import React, { useState } from 'react';
import { useEmployeeContext } from '../../../Contexts/employeeContext';
import { 
    FaLaptop,
    FaBuilding,
    FaBuildingUser,
    FaCircleQuestion,
    FaArrowLeft,
    FaArrowRight,
    FaCheck,
    FaFloppyDisk,
    FaGraduationCap,
    FaUserGear,
    FaUserTie
} from 'react-icons/fa6';

const CHARACTER_LIMITS = {
    careerInterests: 200,
    challengeDetails: 300,
    strengths: 300,
    priorities: 300,
    developmentAreas: 300,
    learningPreferences: 200
};

function EmployeeStep2({ nextStep, prevStep, saveForLater, onSectionChange, onShowConfirmation }) {
    const { employeeContext, setEmployeeContext, saveEmployeeContext } = useEmployeeContext();
    const [currentSection, setCurrentSection] = useState(0);
    const [showConfirmation, setShowConfirmation] = useState(false);
    
    const WORK_STYLES = [
        { value: 'remote', label: 'Fully Remote', icon: FaLaptop },
        { value: 'hybrid', label: 'Hybrid', icon: FaBuildingUser },
        { value: 'office', label: 'Office-based', icon: FaBuilding }
    ];

    const SKILL_LEVELS = [
        { value: 'beginner', label: 'Beginner', icon: FaGraduationCap },
        { value: 'intermediate', label: 'Intermediate', icon: FaUserGear },
        { value: 'advanced', label: 'Advanced', icon: FaUserTie }
    ];

    const COMMON_CHALLENGES = [
        "Time management",
        "Work-life balance",
        "Technical skills gap",
        "Communication",
        "Career progression",
        "Adapting to change",
        "Other"
    ];

    const handleChallengeClick = (challenge) => {
        setEmployeeContext({
            challenges: employeeContext.challenges.includes(challenge)
                ? employeeContext.challenges.filter(c => c !== challenge)
                : [...employeeContext.challenges, challenge]
        });
    };

    const handleSaveAndContinue = async () => {
        onShowConfirmation();
        setShowConfirmation(true);
    };

    const handleConfirmAndProceed = async () => {
        try {
            console.log("Before saving context:", employeeContext);
            await saveEmployeeContext(employeeContext);
            console.log("After saving context - proceeding to next step");
            nextStep();
        } catch (error) {
            console.error('Error saving employee context:', error);
            alert('Failed to save your information. Please try again.');
        }
    };

    const getCharacterCount = (field, value) => {
        const remaining = CHARACTER_LIMITS[field] - (value?.length || 0);
        return `${remaining} characters remaining`;
    };

    console.log("EmployeeStep2 rendering with context:", employeeContext);

    if (showConfirmation) {
        return (
            <div className='employee-goal-set-container'>
                <h2 className='employee-goal-set-title'>Confirm Your Information</h2>
                <div className='employee-team-context-form-section'>
                    <div className='employee-confirmation-content'>
                        <h4>Please review your information before proceeding</h4>
                        
                        <div className='employee-confirmation-section'>
                            <h5>Work Preferences</h5>
                            <div className='employee-confirmation-item'>
                                <strong>Work Style:</strong> {employeeContext.workStyle}
                            </div>
                            <div className='employee-confirmation-item'>
                                <strong>Skill Level:</strong> {employeeContext.skillLevel}
                            </div>
                            <div className='employee-confirmation-item'>
                                <strong>Career Interests:</strong> {employeeContext.careerInterests}
                            </div>
                        </div>

                        <div className='employee-confirmation-section'>
                            <h5>Personal Challenges</h5>
                            <ul>
                                {employeeContext.challenges.map((challenge, index) => (
                                    <li key={index}>{challenge}</li>
                                ))}
                            </ul>
                            {employeeContext.challengeDetails && (
                                <div className='employee-confirmation-item'>
                                    <strong>Additional Details:</strong> {employeeContext.challengeDetails}
                                </div>
                            )}
                        </div>

                        <div className='employee-confirmation-section'>
                            <h5>Personal Strengths</h5>
                            <div className='employee-confirmation-item'>{employeeContext.strengths}</div>
                        </div>

                        <div className='employee-confirmation-section'>
                            <h5>Development Focus</h5>
                            <div className='employee-confirmation-item'>
                                <strong>Priorities:</strong> {employeeContext.priorities}
                            </div>
                            <div className='employee-confirmation-item'>
                                <strong>Development Areas:</strong> {employeeContext.developmentAreas}
                            </div>
                            <div className='employee-confirmation-item'>
                                <strong>Learning Preferences:</strong> {employeeContext.learningPreferences}
                            </div>
                        </div>
                    </div>

                    <div className='employee-goal-set-button-container'>
                        <button 
                            onClick={() => setShowConfirmation(false)} 
                            className='employee-goal-set-link'
                        >
                            <FaArrowLeft /> Edit Information
                        </button>
                        <button onClick={saveForLater} className='employee-goal-set-link'>
                            <FaFloppyDisk /> Save for Later
                        </button>
                        <button 
                            onClick={handleConfirmAndProceed} 
                            className='employee-goal-set-link'
                        >
                            Confirm & Continue <FaArrowRight />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='employee-goal-set-container'>
            <h2 className='employee-goal-set-title'>Tell Us About Yourself</h2>
            
            <div className="employee-step-progress">
                <div className="employee-step-progress-bar">
                    <div 
                        className="employee-step-progress-fill"
                        style={{ width: `${((currentSection + 1) / 3) * 100}%` }}
                    />
                </div>
                <div className="employee-step-progress-text">
                    Step {currentSection + 1} of 3: {
                        currentSection === 0 ? 'Work Preferences' :
                        currentSection === 1 ? 'Personal Assessment' :
                        'Development Focus'
                    }
                </div>
            </div>

            <div className='employee-team-context-form'>
                {/* Work Preferences Section */}
                {currentSection === 0 && (
                    <div className='employee-team-context-form-section'>
                        <h4 className='employee-team-context-form-section-title'>
                            Work Preferences
                        </h4>
                        
                        <label className='employee-team-context-label'>
                            Work Style
                            <FaCircleQuestion className="employee-tooltip-icon" title="How do you prefer to work?" />
                        </label>
                        <div className='employee-work-style-selector'>
                            {WORK_STYLES.map(({value, label, icon: Icon}) => (
                                <div 
                                    key={value}
                                    className={`employee-style-option ${employeeContext.workStyle === value ? 'selected' : ''}`}
                                    onClick={() => {
                                        console.log("Setting work style to:", value);
                                        setEmployeeContext({ workStyle: value });
                                    }}
                                >
                                    <Icon />
                                    <span>{label}</span>
                                </div>
                            ))}
                        </div>

                        <label className='employee-team-context-label'>
                            Skill Level
                            <FaCircleQuestion className="employee-tooltip-icon" title="How would you rate your current skill level in your role?" />
                        </label>
                        <div className='employee-skill-level-selector'>
                            {SKILL_LEVELS.map(({value, label, icon: Icon}) => (
                                <div 
                                    key={value}
                                    className={`employee-style-option ${employeeContext.skillLevel === value ? 'selected' : ''}`}
                                    onClick={() => {
                                        console.log("Setting skill level to:", value);
                                        setEmployeeContext({ skillLevel: value });
                                    }}
                                >
                                    <Icon />
                                    <span>{label}</span>
                                </div>
                            ))}
                        </div>

                        <label className='employee-team-context-label'>
                            Career Interests
                            <FaCircleQuestion className="employee-tooltip-icon" title="What areas of your career are you most interested in developing?" />
                            <span className="employee-character-count">{getCharacterCount('careerInterests', employeeContext.careerInterests)}</span>
                        </label>
                        <textarea
                            className='employee-team-context-input'
                            value={employeeContext.careerInterests}
                            onChange={(e) => setEmployeeContext({ careerInterests: e.target.value })}
                            placeholder="e.g., Leadership, Technical skills, Project management..."
                            maxLength={CHARACTER_LIMITS.careerInterests}
                        />
                    </div>
                )}

                {/* Personal Assessment Section */}
                {currentSection === 1 && (
                    <div className='employee-team-context-form-section'>
                        <h4 className='employee-team-context-form-section-title'>
                            Personal Assessment
                        </h4>
                        
                        <label className='employee-team-context-label'>
                            What challenges do you face in your role?
                            <FaCircleQuestion className="employee-tooltip-icon" title="Select all that apply" />
                        </label>
                        <div className='employee-challenges-grid'>
                            {COMMON_CHALLENGES.map(challenge => (
                                <div 
                                    key={challenge} 
                                    className='employee-challenge-option'
                                    onClick={() => handleChallengeClick(challenge)}
                                >
                                    <input
                                        type="checkbox"
                                        checked={employeeContext.challenges.includes(challenge)}
                                        onChange={() => handleChallengeClick(challenge)}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                    <span>{challenge}</span>
                                </div>
                            ))}
                        </div>
                        
                        {employeeContext.challenges.includes('Other') && (
                            <>
                                <label className='employee-team-context-label'>
                                    Please specify other challenges
                                    <span className="employee-character-count">{getCharacterCount('challengeDetails', employeeContext.challengeDetails)}</span>
                                </label>
                                <textarea
                                    className='employee-team-context-input'
                                    value={employeeContext.challengeDetails}
                                    onChange={(e) => setEmployeeContext({ challengeDetails: e.target.value })}
                                    placeholder="Please describe other challenges you face..."
                                    maxLength={CHARACTER_LIMITS.challengeDetails}
                                />
                            </>
                        )}

                        <label className='employee-team-context-label'>
                            Your Key Strengths
                            <span className="employee-character-count">{getCharacterCount('strengths', employeeContext.strengths)}</span>
                        </label>
                        <textarea
                            className='employee-team-context-input'
                            value={employeeContext.strengths}
                            onChange={(e) => setEmployeeContext({ strengths: e.target.value })}
                            placeholder="What are you particularly good at?"
                            maxLength={CHARACTER_LIMITS.strengths}
                        />
                    </div>
                )}

                {/* Development Focus Section */}
                {currentSection === 2 && (
                    <div className='employee-team-context-form-section'>
                        <h4 className='employee-team-context-form-section-title'>
                            Development Focus
                        </h4>
                        
                        <label className='employee-team-context-label'>
                            Top Priorities
                            <span className="employee-character-count">{getCharacterCount('priorities', employeeContext.priorities)}</span>
                        </label>
                        <textarea
                            className='employee-team-context-input'
                            value={employeeContext.priorities}
                            onChange={(e) => setEmployeeContext({ priorities: e.target.value })}
                            placeholder="What are your main objectives this year?"
                            maxLength={CHARACTER_LIMITS.priorities}
                        />

                        <label className='employee-team-context-label'>
                            Areas for Development
                            <span className="employee-character-count">{getCharacterCount('developmentAreas', employeeContext.developmentAreas)}</span>
                        </label>
                        <textarea
                            className='employee-team-context-input'
                            value={employeeContext.developmentAreas}
                            onChange={(e) => setEmployeeContext({ developmentAreas: e.target.value })}
                            placeholder="What skills or areas would you like to improve?"
                            maxLength={CHARACTER_LIMITS.developmentAreas}
                        />

                        <label className='employee-team-context-label'>
                            Learning Preferences
                            <span className="employee-character-count">{getCharacterCount('learningPreferences', employeeContext.learningPreferences)}</span>
                        </label>
                        <textarea
                            className='employee-team-context-input'
                            value={employeeContext.learningPreferences}
                            onChange={(e) => setEmployeeContext({ learningPreferences: e.target.value })}
                            placeholder="How do you prefer to learn? (e.g., hands-on, reading, mentoring)"
                            maxLength={CHARACTER_LIMITS.learningPreferences}
                        />
                    </div>
                )}

                <div className='employee-goal-set-button-container'>
                    {/* Back button - only visible in first section */}
                    {currentSection === 0 && (
                        <button onClick={prevStep} className='employee-goal-set-link'>
                            <FaArrowLeft /> Back
                        </button>
                    )}

                    {/* Navigation between sections */}
                    {currentSection > 0 && (
                        <button onClick={() => {
                            setCurrentSection(prev => prev - 1);
                            onSectionChange();
                        }} className='employee-goal-set-link'>
                            <FaArrowLeft /> Previous
                        </button>
                    )}
                    <button onClick={saveForLater} className='employee-goal-set-link'>
                        <FaFloppyDisk /> Save for Later
                    </button>
                    {currentSection < 2 ? (
                        <button onClick={() => {
                            setCurrentSection(prev => prev + 1);
                            onSectionChange();
                        }} className='employee-goal-set-link'>
                            Next <FaArrowRight />
                        </button>
                    ) : (
                        <button onClick={handleSaveAndContinue} className='employee-goal-set-link'>
                            <FaCheck /> Complete
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default EmployeeStep2;
