import React from 'react';
import { useTab } from '../../../Contexts/tabContext';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../../Components/BackButton/BackButton';
import StoreManager from '../../../Components/Store/StoreManager';
import '../Styles/StoreManagerPage.css';

const StoreManagerPage = () => {
    const { activeTab } = useTab();
    const navigate = useNavigate();
    
    const handleBackLinkClick = () => {
        navigate(`/AdminPortal?tab=store-manager`);
    };

    return (
        <div className="page-container">
            <div className="back-button-container">
                <BackButton onClick={handleBackLinkClick} />
            </div>
            <div className="page-content">
                <h1 className="page-header">Store Manager</h1>
                <StoreManager hideBackButton={true} />
            </div>
        </div>
    );
};

export default StoreManagerPage; 