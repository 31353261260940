import React, { useState, useEffect, useRef } from 'react';
import '../Styles/EmployeePortal.css'; // Updated CSS import
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../Contexts/authContext';
import { useTab } from '../../../Contexts/tabContext';
import TeamDashboard from '../../../Components/TeamDashboard/TeamDashboard';
import CompanyStore from '../../../Components/Store/CompanyStore';
import fetchWithAuth from '../../../Util/fetchWithAuth';
import { FaCalendarCheck, FaChartLine, FaTrophy, FaBullseye, FaChevronRight, FaUsers, FaCheckCircle, FaAward, FaRegCheckCircle, FaArchive, FaStore } from 'react-icons/fa';

const EmployeePortal = () => {
    const { activeTab, setActiveTab } = useTab('dashboard');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const sidebarRef = useRef(null);
    const { userId } = useAuth();
    const [hasGoals, setHasGoals] = useState(false);
    const [personalGoals, setPersonalGoals] = useState([]);
    const [teamGoals, setTeamGoals] = useState([]);
    const [upcomingCheckIns, setUpcomingCheckIns] = useState([]);
    const [achievements, setAchievements] = useState([]);
    const [managerId, setManagerId] = useState(null);
    const [loading, setLoading] = useState({
        personalGoals: true,
        teamGoals: true,
        checkIns: true,
        achievements: true
    });
    const [isArchivedGoalsLoading, setIsArchivedGoalsLoading] = useState(false);
    const [archivedPersonalGoals, setArchivedPersonalGoals] = useState([]);
    const [archivedTeamGoals, setArchivedTeamGoals] = useState([]);
    const [hasArchivedGoals, setHasArchivedGoals] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const tab = query.get('tab');
        if (tab) {
            setActiveTab(tab);
        } else {
            navigate('?tab=dashboard');
        }
    }, [location, setActiveTab, navigate]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsSidebarOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Get user's manager ID
    useEffect(() => {
        if (userId) {
            fetchWithAuth(`/api/users/get-manager/${userId}`)
                .then(response => response.json())
                .then(data => {
                    if (data && data.managerId) {
                        setManagerId(data.managerId);
                    }
                })
                .catch(error => {
                    console.error('Error fetching user details:', error);
                });
        }
    }, [userId]);

    // Fetch personal goals
    useEffect(() => {
        if (userId && activeTab === 'dashboard') {
            setLoading(prev => ({ ...prev, personalGoals: true }));
            
            fetchWithAuth(`/api/goals/get-goals/${userId}`)
                .then(response => response.json())
                .then(data => {
                    setPersonalGoals(data);
                    setHasGoals(data && data.length > 0);
                    setLoading(prev => ({ ...prev, personalGoals: false }));
                })
                .catch(error => {
                    console.error('Error retrieving goals:', error);
                    setLoading(prev => ({ ...prev, personalGoals: false }));
                });
        }
    }, [userId, activeTab]);

    // Fetch team goals
    useEffect(() => {
        if (userId && managerId && activeTab === 'dashboard') {
            setLoading(prev => ({ ...prev, teamGoals: true }));
            
            // First, fetch manager info to get their name
            fetchWithAuth(`/api/users/get-employee-details/${managerId}`)
                .then(response => response.json())
                .then(managerData => {
                    const managerName = managerData && managerData.user ? 
                        `${managerData.user.first_name} ${managerData.user.last_name}` : 'Your Manager';
                    
                    // Then, fetch team goals
                    return fetchWithAuth(`/api/team-goals/get-goals/${managerId}`)
                        .then(response => response.json())
                        .then(data => {
                            // Add progress property if it doesn't exist and manager name
                            const goalsWithProgress = data.map(goal => ({
                                ...goal,
                                progress: goal.progress || 0,
                                managerName
                            }));
                            setTeamGoals(goalsWithProgress);
                            setLoading(prev => ({ ...prev, teamGoals: false }));
                        });
                })
                .catch(error => {
                    console.error('Error fetching team goals:', error);
                    setLoading(prev => ({ ...prev, teamGoals: false }));
                });
        }
    }, [userId, managerId, activeTab]);

    // Fetch upcoming check-ins
    useEffect(() => {
        if (userId && activeTab === 'dashboard') {
            setLoading(prev => ({ ...prev, checkIns: true }));
            
            fetchWithAuth(`/api/check-ins/employee/${userId}`)
                .then(response => response.json())
                .then(async data => {
                    // Filter for upcoming check-ins
                    const now = new Date();
                    const upcoming = data
                        .filter(checkIn => 
                            new Date(checkIn.scheduled_date) > now && 
                            checkIn.status === 'scheduled'
                        )
                        .sort((a, b) => new Date(a.scheduled_date) - new Date(b.scheduled_date))
                        .slice(0, 3); // Limit to 3 upcoming check-ins
                    
                    // If we have manager ID, fetch manager details for each check-in
                    if (managerId) {
                        try {
                            const managerResponse = await fetchWithAuth(`/api/users/get-employee-details/${managerId}`);
                            const managerData = await managerResponse.json();
                            
                            if (managerData) {
                                const managerName = managerData.user ? 
                                    `${managerData.user.first_name} ${managerData.user.last_name}` : 'Your Manager';
                                // Add manager name to each check-in
                                const checkInsWithManagerName = upcoming.map(checkIn => ({
                                    ...checkIn,
                                    manager_name: managerName
                                }));
                                setUpcomingCheckIns(checkInsWithManagerName);
                            } else {
                                setUpcomingCheckIns(upcoming);
                            }
                        } catch (error) {
                            console.error('Error fetching manager details:', error);
                            setUpcomingCheckIns(upcoming);
                        }
                    } else {
                        setUpcomingCheckIns(upcoming);
                    }
                    
                    setLoading(prev => ({ ...prev, checkIns: false }));
                })
                .catch(error => {
                    console.error('Error fetching check-ins:', error);
                    setLoading(prev => ({ ...prev, checkIns: false }));
                });
        }
    }, [userId, managerId, activeTab]);

    // Replace the placeholder achievements with a real API fetch
    useEffect(() => {
        if (userId && activeTab === 'dashboard') {
            setLoading(prev => ({ ...prev, achievements: true }));
            
            // Try to fetch real achievements
            fetchWithAuth(`/api/achievements/get-achievements/${userId}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`API returned status ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    if (data && Array.isArray(data) && data.length > 0) {
                        // Map the API response to match our achievement structure
                        const formattedAchievements = data.map(achievement => ({
                            id: achievement.id,
                            title: achievement.title,
                            description: achievement.description,
                            date: achievement.date_earned,
                            type: achievement.achievement_type // We'll map this to an icon
                        }));
                        setAchievements(formattedAchievements);
                    } else {
                        // If no real achievements, generate placeholders based on goals
                        generatePlaceholderAchievements();
                    }
                    setLoading(prev => ({ ...prev, achievements: false }));
                })
                .catch(error => {
                    console.error('Error fetching achievements:', error);
                    // On error, fall back to placeholder achievements
                    generatePlaceholderAchievements();
                    setLoading(prev => ({ ...prev, achievements: false }));
                });
        }
    }, [userId, personalGoals, activeTab]);

    // Function to generate placeholder achievements
    const generatePlaceholderAchievements = () => {
        // Generate placeholder achievements based on completed goals
        const completedGoals = personalGoals.filter(goal => goal.progress === 100);
        const placeholderAchievements = completedGoals.map(goal => ({
            id: `achievement-${goal.id}`,
            title: `Completed: ${goal.summary}`,
            description: `Successfully completed the goal: ${goal.summary}`,
            date: new Date(Date.now() - Math.floor(Math.random() * 30) * 24 * 60 * 60 * 1000).toISOString(),
            type: 'goal_completion'
        }));
        
        // Add other placeholder achievements if not many completed goals
        if (completedGoals.length < 2) {
            // Add a check-in streak achievement
            placeholderAchievements.push({
                id: 'achievement-streak',
                title: 'Check-in Streak',
                description: 'Completed 5 consecutive check-ins on time',
                date: new Date(Date.now() - 15 * 24 * 60 * 60 * 1000).toISOString(),
                type: 'check_in_streak'
            });
            
            // Add a progress milestone if applicable 
            if (personalGoals.some(goal => goal.progress >= 50)) {
                placeholderAchievements.push({
                    id: 'achievement-progress',
                    title: 'Halfway Hero',
                    description: 'Reached 50% completion on all active goals',
                    date: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(),
                    type: 'progress_milestone'
                });
            }
        }
        
        setAchievements(placeholderAchievements);
    };

    // Calculate overall progress for personal goals
    const calculateOverallProgress = (goals) => {
        if (!goals || goals.length === 0) return 0;
        
        const totalProgress = goals.reduce((sum, goal) => sum + Number(goal.progress || 0), 0);
        return Math.round(totalProgress / goals.length);
    };

    // Count completed personal goals
    const countCompletedGoals = (goals) => {
        if (!goals || goals.length === 0) return 0;
        return goals.filter(goal => Number(goal.progress) === 100).length;
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setIsSidebarOpen(false);
        navigate(`?tab=${tab}`);
    };

    // Fetch archived goals when viewing the old goals tab
    useEffect(() => {
        if (userId && activeTab === 'review-old-goals' && managerId) {
            setIsArchivedGoalsLoading(true);
            
            const fetchArchivedGoals = async () => {
                try {
                    // Fetch personal archived goals
                    const personalResponse = await fetchWithAuth(`/api/goals/get-goals/${userId}?archived=true`);
                    const personalData = await personalResponse.json();
                    setArchivedPersonalGoals(personalData);
                    
                    // Fetch team archived goals if manager exists
                    const teamResponse = await fetchWithAuth(`/api/team-goals/get-goals/${managerId}?archived=true`);
                    const teamData = await teamResponse.json();
                    
                    // Add manager name to each goal
                    const managerResponse = await fetchWithAuth(`/api/users/get-employee-details/${managerId}`);
                    const managerData = await managerResponse.json();
                    const managerName = managerData && managerData.user ? 
                        `${managerData.user.first_name} ${managerData.user.last_name}` : 'Your Manager';
                    
                    const teamGoalsWithManager = teamData.map(goal => ({
                        ...goal,
                        managerName
                    }));
                    
                    setArchivedTeamGoals(teamGoalsWithManager);
                } catch (error) {
                    console.error('Error fetching archived goals:', error);
                } finally {
                    setIsArchivedGoalsLoading(false);
                }
            };
            
            fetchArchivedGoals();
        }
    }, [userId, managerId, activeTab]);

    // Handle unarchiving a goal
    const handleUnarchiveGoal = async (goalId) => {
        try {
            const response = await fetchWithAuth(`/api/goals/toggle-archive/${goalId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    archive: false
                })
            });
            
            if (!response.ok) {
                throw new Error('Failed to unarchive goal');
            }
            
            // Remove the goal from the archived list
            setArchivedPersonalGoals(prev => prev.filter(goal => goal.id !== goalId));
            
            alert('Goal successfully restored. It can now be found in your active goals.');
            
            // Optionally refresh active goals if on the dashboard tab
            if (activeTab === 'dashboard') {
                fetchWithAuth(`/api/goals/get-goals/${userId}`)
                    .then(response => response.json())
                    .then(data => {
                        setPersonalGoals(data);
                    })
                    .catch(error => {
                        console.error('Error retrieving goals:', error);
                    });
            }
        } catch (error) {
            console.error('Error unarchiving goal:', error);
            alert('Failed to restore goal. Please try again.');
        }
    };

    // Check for archived goals on initial load
    useEffect(() => {
        if (userId && managerId) {
            const checkForArchivedGoals = async () => {
                try {
                    // Check for personal archived goals
                    const personalResponse = await fetchWithAuth(`/api/goals/get-goals/${userId}?archived=true&limit=1`);
                    const personalData = await personalResponse.json();
                    
                    // Check for team archived goals
                    const teamResponse = await fetchWithAuth(`/api/team-goals/get-goals/${managerId}?archived=true&limit=1`);
                    const teamData = await teamResponse.json();
                    
                    // Set flag if there are any archived goals
                    setHasArchivedGoals(
                        (Array.isArray(personalData) && personalData.length > 0) || 
                        (Array.isArray(teamData) && teamData.length > 0)
                    );
                } catch (error) {
                    console.error('Error checking for archived goals:', error);
                    setHasArchivedGoals(false);
                }
            };
            
            checkForArchivedGoals();
        }
    }, [userId, managerId]);

    const renderContent = () => {
        switch (activeTab) {
            case 'dashboard':
                return (
                    <div className="portal-section">
                        <h2>Your Dashboard</h2>
                        <p>View your progress, achievements, and check-in schedule.</p>
                        
                        <div className="ep-dashboard-grid">
                            {/* Personal Goals Section */}
                            <div className="ep-dashboard-card personal-goals">
                                <div className="ep-dashboard-card-header">
                                    <h3><FaBullseye className="card-icon" /> Your Goals</h3>
                                </div>
                                {loading.personalGoals ? (
                                    <div className="ep-dashboard-loading">Loading your goals...</div>
                                ) : personalGoals.length > 0 ? (
                                    <div className="ep-goals-summary">
                                        <div className="ep-goals-stats">
                                            <div className="ep-stat-card">
                                                <span className="ep-stat-value">{personalGoals.length}</span>
                                                <span className="ep-stat-label">Total Goals</span>
                                            </div>
                                            <div className="ep-stat-card">
                                                <span className="ep-stat-value">{calculateOverallProgress(personalGoals)}%</span>
                                                <span className="ep-stat-label">Overall Progress</span>
                                            </div>
                                            <div className="ep-stat-card">
                                                <span className="ep-stat-value">{countCompletedGoals(personalGoals)}</span>
                                                <span className="ep-stat-label">Completed</span>
                                            </div>
                                        </div>
                                        <div className="ep-goals-list">
                                            {personalGoals.slice(0, 3).map(goal => (
                                                <div key={goal.id} className="ep-goal-item">
                                                    <div className="ep-goal-summary">{goal.summary}</div>
                                                    <div className="ep-goal-progress-container">
                                                        <div className="ep-goal-progress-bar">
                                                            <div 
                                                                className="ep-goal-progress-fill" 
                                                                style={{ width: `${goal.progress}%` }}
                                                            ></div>
                                                        </div>
                                                        <span className="ep-goal-progress-text">{goal.progress}%</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="ep-no-data-message">No personal goals set yet. Start by setting new goals.</div>
                                )}
                                <div className="ep-dashboard-buttons">
                                    <Link to="/ProgressPage">
                                        <button className="ep-dashboard-button">
                                            View Progress <FaChevronRight size={12} />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            
                            {/* Team Goals Section */}
                            <div className="ep-dashboard-card team-goals">
                                <div className="ep-dashboard-card-header">
                                    <h3><FaUsers className="card-icon" /> Team Goals</h3>
                                </div>
                                {loading.teamGoals ? (
                                    <div className="ep-dashboard-loading">Loading team goals...</div>
                                ) : teamGoals.length > 0 ? (
                                    <div className="ep-goals-list">
                                        {teamGoals.slice(0, 3).map(goal => (
                                            <div key={goal.id} className="ep-goal-item">
                                                <div className="ep-goal-summary">{goal.summary}</div>
                                                <div className="ep-goal-manager">Assigned by: {goal.managerName || 'Your Manager'}</div>
                                                <div className="ep-goal-progress-container">
                                                    <div className="ep-goal-progress-bar">
                                                        <div 
                                                            className="ep-goal-progress-fill" 
                                                            style={{ width: `${goal.progress}%` }}
                                                        ></div>
                                                    </div>
                                                    <span className="ep-goal-progress-text">{goal.progress}%</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="ep-no-data-message">No team goals assigned yet.</div>
                                )}
                                <div className="ep-dashboard-buttons">
                                    <Link to="/TeamDashboard">
                                        <button className="ep-dashboard-button">
                                            Team Dashboard <FaChevronRight size={12} />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            
                            {/* Upcoming Check-ins Section */}
                            <div className="ep-dashboard-card check-ins">
                                <div className="ep-dashboard-card-header">
                                    <h3><FaCalendarCheck className="card-icon" /> Upcoming Check-ins</h3>
                                </div>
                                {loading.checkIns ? (
                                    <div className="ep-dashboard-loading">Loading check-ins...</div>
                                ) : upcomingCheckIns.length > 0 ? (
                                    <div className="ep-checkins-list">
                                        {upcomingCheckIns.map(checkIn => (
                                            <div key={checkIn.id} className="ep-checkin-item">
                                                <div className="ep-checkin-date">
                                                    {new Date(checkIn.scheduled_date).toLocaleDateString()} at {new Date(checkIn.scheduled_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                                                </div>
                                                <div className="ep-checkin-manager">
                                                    with <strong>{checkIn.manager_name || 'Your Manager'}</strong>
                                                </div>
                                                {checkIn.notes && (
                                                    <div className="ep-checkin-notes">
                                                        {checkIn.notes}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="ep-no-data-message">No upcoming check-ins scheduled.</div>
                                )}
                                <div className="ep-dashboard-buttons">
                                    <Link to="/CheckIns">
                                        <button className="ep-dashboard-button">
                                            View All Check-ins <FaChevronRight size={12} />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            
                            {/* Achievements Section */}
                            <div className="ep-dashboard-card achievements">
                                <div className="ep-dashboard-card-header">
                                    <h3><FaTrophy className="card-icon" /> Your Achievements</h3>
                                </div>
                                {loading.achievements ? (
                                    <div className="ep-dashboard-loading">Loading achievements...</div>
                                ) : achievements.length > 0 ? (
                                    <div className="ep-achievements-list">
                                        {achievements.map(achievement => (
                                            <div key={achievement.id} className="ep-achievement-item">
                                                <div className="ep-achievement-icon">
                                                    {achievement.type === 'goal_completion' && <FaCheckCircle className="achievement-icon-complete" />}
                                                    {achievement.type === 'goal_achiever' && <FaTrophy className="achievement-icon-complete" />}
                                                    {achievement.type === 'check_in_streak' && <FaCalendarCheck className="achievement-icon-streak" />}
                                                    {achievement.type === 'progress_milestone' && <FaChartLine className="achievement-icon-progress" />}
                                                    {achievement.type === 'halfway_hero' && <FaChartLine className="achievement-icon-progress" />}
                                                    {achievement.type === 'progress_starter' && <FaRegCheckCircle className="achievement-icon-progress" />}
                                                    {achievement.type === 'verified_victor' && <FaAward className="achievement-icon-complete" />}
                                                </div>
                                                <div className="ep-achievement-content">
                                                    <div className="ep-achievement-title">{achievement.title}</div>
                                                    <div className="ep-achievement-description">{achievement.description}</div>
                                                    <div className="ep-achievement-date">
                                                        {new Date(achievement.date).toLocaleDateString()}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="ep-no-data-message">
                                        Complete goals to earn achievements.
                                    </div>
                                )}
                                <div className="ep-dashboard-buttons">
                                    <Link to="/Achievements">
                                        <button className="ep-dashboard-button">
                                            View All Achievements <FaChevronRight size={12} />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'set-goals':
                return (
                    <div className="portal-section">
                        <h2>{hasGoals ? 'Manage Your Goals' : 'Set Goals'}</h2>
                        <p>{hasGoals ? 'Review or set new personal goals.' : 'Define your personal goals.'}</p>
                        <div className='ep-goal-setting-button-container'>
                            <Link to={`/EmployeeMaster?step=0&tab=${activeTab}`}>
                                <button className='ep-goal-setting-button'>Set New Goals</button>
                            </Link>
                            {hasGoals && (
                                <Link to={`/EmployeeMaster?step=2&tab=${activeTab}`}>
                                    <button className='ep-goal-setting-button'>Review Goals</button>
                                </Link>
                            )}
                        </div>
                    </div>
                );
            case 'your-progress':
                return (
                    <div className="portal-section">
                        <h2>Your Progress</h2>
                        <p>View your progress on your goals.</p>
                        <Link to="/ProgressPage">
                            <button className='ep-goal-setting-button'>View Progress</button>
                        </Link>
                    </div>
                );
            case 'company-store':
                return (
                    <div className="portal-section">
                        <h2>Company Reward Store</h2>
                        <p>Redeem your earned points for exciting rewards and incentives.</p>
                        <Link to="/CompanyStorePage">
                            <button className='ep-goal-setting-button'>View Store</button>
                        </Link>
                    </div>
                );
            case 'review-old-goals':
                return (
                    <div className="portal-section">
                        <h2>Review Old Goals</h2>
                        <p>View goals that have been archived from previous goal setting periods.</p>
                        
                        {isArchivedGoalsLoading ? (
                            <div className="ep-loading">Loading archived goals...</div>
                        ) : (
                            <div className="ep-archived-goals-container">
                                {/* Personal Goals Section */}
                                <div className="ep-archived-section">
                                    <h3>Personal Goals</h3>
                                    {archivedPersonalGoals.length > 0 ? (
                                        <div className="ep-archived-goals-list">
                                            {archivedPersonalGoals.map((goal) => (
                                                <div key={goal.id} className="ep-archived-goal-card">
                                                    <div className="ep-archived-goal-content">
                                                        <h4>{goal.summary || goal.goal_text}</h4>
                                                        <div className="ep-archived-goal-progress">
                                                            <div className="ep-archived-progress-bar">
                                                                <div 
                                                                    className="ep-archived-progress-fill" 
                                                                    style={{ width: `${goal.progress || 0}%` }}
                                                                ></div>
                                                            </div>
                                                            <span className="ep-archived-progress-text">{goal.progress || 0}%</span>
                                                        </div>
                                                        <p className="ep-archived-date">
                                                            Archived on: {new Date(goal.archived_at).toLocaleDateString()}
                                                        </p>
                                                        <div className="ep-archived-actions">
                                                            <button
                                                                className="ep-unarchive-button"
                                                                onClick={() => handleUnarchiveGoal(goal.id)}
                                                            >
                                                                Restore Goal
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="ep-no-archived">No archived personal goals found.</p>
                                    )}
                                </div>
                                
                                {/* Team Goals Section */}
                                <div className="ep-archived-section">
                                    <h3>Team Goals</h3>
                                    {archivedTeamGoals.length > 0 ? (
                                        <div className="ep-archived-goals-list">
                                            {archivedTeamGoals.map((goal) => (
                                                <div key={goal.id} className="ep-archived-goal-card">
                                                    <div className="ep-archived-goal-content">
                                                        <h4>{goal.summary || goal.goal_text}</h4>
                                                        <div className="ep-goal-manager">Assigned by: {goal.managerName || 'Your Manager'}</div>
                                                        <div className="ep-archived-goal-progress">
                                                            <div className="ep-archived-progress-bar">
                                                                <div 
                                                                    className="ep-archived-progress-fill" 
                                                                    style={{ width: `${goal.progress || 0}%` }}
                                                                ></div>
                                                            </div>
                                                            <span className="ep-archived-progress-text">{goal.progress || 0}%</span>
                                                        </div>
                                                        <p className="ep-archived-date">
                                                            Archived on: {new Date(goal.archived_at).toLocaleDateString()}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="ep-no-archived">No archived team goals found.</p>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                );
            case 'achievements':
                return (
                    <div className="portal-section">
                        <h2>Your Achievements</h2>
                        <p>View all achievements you've earned and track your progress towards unlocking new achievements.</p>
                        <div className="ep-achievements-summary">
                            <div className="ep-achievements-overview">
                                <div className="ep-stat-card">
                                    <span className="ep-stat-value">{achievements.length}</span>
                                    <span className="ep-stat-label">Achievements Earned</span>
                                </div>
                                {/* We can add more stats here in the future */}
                            </div>
                            
                            <div className="ep-achievements-preview">
                                {achievements.length > 0 ? (
                                    <div className="ep-achievements-grid">
                                        {achievements.slice(0, 4).map(achievement => (
                                            <div key={achievement.id} className="ep-achievement-item">
                                                <div className="ep-achievement-icon">
                                                    {achievement.type === 'goal_completion' && <FaCheckCircle className="achievement-icon-complete" />}
                                                    {achievement.type === 'goal_achiever' && <FaTrophy className="achievement-icon-complete" />}
                                                    {achievement.type === 'check_in_streak' && <FaCalendarCheck className="achievement-icon-streak" />}
                                                    {achievement.type === 'progress_milestone' && <FaChartLine className="achievement-icon-progress" />}
                                                    {achievement.type === 'halfway_hero' && <FaChartLine className="achievement-icon-progress" />}
                                                    {achievement.type === 'progress_starter' && <FaRegCheckCircle className="achievement-icon-progress" />}
                                                    {achievement.type === 'verified_victor' && <FaAward className="achievement-icon-complete" />}
                                                </div>
                                                <div className="ep-achievement-content">
                                                    <div className="ep-achievement-title">{achievement.title}</div>
                                                    <div className="ep-achievement-description">{achievement.description}</div>
                                                    <div className="ep-achievement-date">
                                                        {new Date(achievement.date).toLocaleDateString()}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="ep-no-data-message">
                                        You haven't earned any achievements yet. Complete goals and engage regularly to earn achievements!
                                    </div>
                                )}
                            </div>
                        </div>
                        
                        <div className="ep-action-buttons">
                            <Link to="/AchievementsPage">
                                <button className="ep-goal-setting-button">
                                    View All Achievements
                                </button>
                            </Link>
                        </div>
                    </div>
                );
            default:
                return <div className="portal-section">Please select a tab.</div>;
        }
    };

    return (
        <div className="ep-portal">
            <button 
                className="ep-portal-menu-button" 
                onClick={toggleSidebar}
            >
                Menu
            </button>
            <div className="ep-portal-sidebar">
                <button className={`ep-portal-sidebar-button ${activeTab === 'dashboard' ? 'active' : ''}`} onClick={() => handleTabClick('dashboard')}>Dashboard</button>
                <button className={`ep-portal-sidebar-button ${activeTab === 'set-goals' ? 'active' : ''}`} onClick={() => handleTabClick('set-goals')}>Set Goals</button>
                <button className={`ep-portal-sidebar-button ${activeTab === 'your-progress' ? 'active' : ''}`} onClick={() => handleTabClick('your-progress')}>Your Progress</button>
                <button className={`ep-portal-sidebar-button ${activeTab === 'achievements' ? 'active' : ''}`} onClick={() => handleTabClick('achievements')}>Achievements</button>
                <button className={`ep-portal-sidebar-button ${activeTab === 'company-store' ? 'active' : ''}`} onClick={() => handleTabClick('company-store')}>
                    Company Store
                </button>
                <button 
                    className={`ep-portal-sidebar-button ${activeTab === 'review-old-goals' ? 'active' : ''} ${!hasArchivedGoals ? 'disabled' : ''}`} 
                    onClick={() => hasArchivedGoals && handleTabClick('review-old-goals')}
                    title={!hasArchivedGoals ? "No archived goals to display" : "Review your archived goals"}
                >
                    Review Old Goals
                </button>
            </div>
            <div ref={sidebarRef} className={`ep-portal-sidebar mobile ${isSidebarOpen ? 'open' : ''}`}>
                <button className={`ep-portal-sidebar-button ${activeTab === 'dashboard' ? 'active' : ''}`} onClick={() => handleTabClick('dashboard')}>Dashboard</button>
                <button className={`ep-portal-sidebar-button ${activeTab === 'set-goals' ? 'active' : ''}`} onClick={() => handleTabClick('set-goals')}>Set Goals</button>
                <button className={`ep-portal-sidebar-button ${activeTab === 'your-progress' ? 'active' : ''}`} onClick={() => handleTabClick('your-progress')}>Your Progress</button>
                <button className={`ep-portal-sidebar-button ${activeTab === 'achievements' ? 'active' : ''}`} onClick={() => handleTabClick('achievements')}>Achievements</button>
                <button className={`ep-portal-sidebar-button ${activeTab === 'company-store' ? 'active' : ''}`} onClick={() => handleTabClick('company-store')}>
                    Company Store
                </button>
                <button 
                    className={`ep-portal-sidebar-button ${activeTab === 'review-old-goals' ? 'active' : ''} ${!hasArchivedGoals ? 'disabled' : ''}`} 
                    onClick={() => hasArchivedGoals && handleTabClick('review-old-goals')}
                    title={!hasArchivedGoals ? "No archived goals to display" : "Review your archived goals"}
                >
                    Review Old Goals
                </button>
            </div>
            <div className="ep-portal-content">
                {renderContent()}
            </div>
        </div>
    );
};

export default EmployeePortal;