import React, { useState, useEffect } from 'react';
import './SessionTimeoutMessage.css';

const SessionTimeoutMessage = () => {
  const [visible, setVisible] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [isLoginPage, setIsLoginPage] = useState(false);

  useEffect(() => {
    console.log('===== SESSION TIMEOUT MESSAGE MOUNTED =====');
    
    // Check if we're on the login page
    const isOnLoginPage = window.location.pathname === '/login';
    setIsLoginPage(isOnLoginPage);
    
    // Check for URL timeout parameter
    const urlParams = new URLSearchParams(window.location.search);
    const hasTimeoutParam = urlParams.get('timeout') === 'true';
    
    // Check localStorage for timeout flags
    const sessionExpired = localStorage.getItem('sessionExpired') === 'true';
    const inactivityTimeout = localStorage.getItem('inactivityTimeout') === 'true';
    const logoutTimestamp = parseInt(localStorage.getItem('logoutTimestamp') || '0');
    
    // Only show message if timeout was recent (within last 5 minutes)
    const isRecentTimeout = Date.now() - logoutTimestamp < 300000; // 5 minutes
    
    console.log('SessionTimeoutMessage state:', {
      isOnLoginPage,
      hasTimeoutParam,
      sessionExpired,
      inactivityTimeout,
      logoutTimestamp,
      isRecentTimeout,
      timeSinceLogout: logoutTimestamp ? (Date.now() - logoutTimestamp) + 'ms' : 'N/A'
    });
    
    if ((hasTimeoutParam || (sessionExpired && isRecentTimeout))) {
      // Set appropriate message based on timeout type
      const messageToShow = inactivityTimeout
        ? 'You have been logged out due to inactivity. Please log in again.'
        : 'Your session has expired. Please log in again.';
      
      console.log('Showing timeout message:', messageToShow);
      setMessageText(messageToShow);
      setVisible(true);
      
      // If we have the URL parameter, update the URL without reloading
      if (hasTimeoutParam && isOnLoginPage) {
        const newUrl = window.location.pathname;
        window.history.replaceState({}, document.title, newUrl);
      }
    }
  }, []);

  const handleClose = () => {
    console.log('Closing timeout message');
    setVisible(false);
    
    // Clear localStorage flags when user dismisses the message
    localStorage.removeItem('sessionExpired');
    localStorage.removeItem('inactivityTimeout');
    localStorage.removeItem('logoutTimestamp');
  };

  if (!visible) return null;

  // Use different classes depending on whether we're on the login page
  const containerClass = isLoginPage ? 'session-timeout-banner' : 'message-container';
  const closeButtonClass = isLoginPage ? 'session-timeout-banner-close' : 'close-button';

  return (
    <div className={containerClass}>
      <span>{messageText}</span>
      <button className={closeButtonClass} onClick={handleClose}>✕</button>
    </div>
  );
};

export default SessionTimeoutMessage; 