import React, { useState, useEffect } from 'react';
import '../Styles/EmployeeDirectory.css'; // Ensure this CSS file contains the necessary styles
import { useTab } from '../../../Contexts/tabContext';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../../Contexts/authContext';
import BackButton from '../../../Components/BackButton/BackButton';
import fetchWithAuth from '../../../Util/fetchWithAuth';

const EmployeeDirectory = () => {
    const [users, setUsers] = useState([]);
    const { activeTab } = useTab();
    const navigate = useNavigate();
    const { companyId } = useAuth(); // Get company_id from context
    
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetchWithAuth(`/api/users/get-users-by-company/${companyId}`);
                const data = await response.json();
                setUsers(data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        if (companyId) {
            fetchUsers();
        }
    }, [companyId]);

    const handleBackLinkClick = () => {
        navigate(`/AdminPortal?tab=employee-directory`);
    };

    return (
        <div className="page-container">
            <div className="back-button-container">
                <BackButton onClick={handleBackLinkClick} />
            </div>
            <div className="page-content">
                <h1 className="page-header">Employee Directory</h1>
                <div className="employee-directory">
                    <ul>
                        {users.map(user => (
                            <li key={user.id}>
                                <Link to={`/EmployeeProfile/${user.id}`}>
                                    {user.first_name} {user.last_name} - {user.job_title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default EmployeeDirectory;
