import React, { useState, useRef, useEffect } from 'react';
import { useNotifications } from '../../Contexts/notificationContext';
import { useAuth } from '../../Contexts/authContext';
import { FaBell, FaCheckCircle, FaAward, FaTimes, FaComment, FaCommentDots, FaCalendarCheck, FaClipboardCheck, FaCalendarTimes, FaComments, FaBullseye } from 'react-icons/fa';
import './NotificationIcon.css';
import { useNavigate } from 'react-router-dom';

const NotificationIcon = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { 
        notifications = [], 
        unreadCount = 0, 
        markAsRead = () => {}, 
        markAllAsRead = () => {}, 
        fetchNotifications = () => {} 
    } = useNotifications() || {};
    const { userRole } = useAuth();
    const notificationPanelRef = useRef(null);
    const navigate = useNavigate();
    const [isMarkingAllAsRead, setIsMarkingAllAsRead] = useState(false);
    
    // Format timestamp to relative time (e.g., "2 hours ago")
    const formatTimeAgo = (timestamp) => {
        const date = new Date(timestamp);
        const now = new Date();
        const seconds = Math.floor((now - date) / 1000);
        
        let interval = Math.floor(seconds / 31536000);
        if (interval >= 1) {
            return `${interval} year${interval === 1 ? '' : 's'} ago`;
        }
        
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            return `${interval} month${interval === 1 ? '' : 's'} ago`;
        }
        
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
            return `${interval} day${interval === 1 ? '' : 's'} ago`;
        }
        
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
            return `${interval} hour${interval === 1 ? '' : 's'} ago`;
        }
        
        interval = Math.floor(seconds / 60);
        if (interval >= 1) {
            return `${interval} minute${interval === 1 ? '' : 's'} ago`;
        }
        
        return `${Math.floor(seconds)} second${seconds === 1 ? '' : 's'} ago`;
    };
    
    // Handle clicking outside the notification panel to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (notificationPanelRef.current && !notificationPanelRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    
    // Refresh notifications when panel is opened
    useEffect(() => {
        if (isOpen && fetchNotifications) {
            try {
                fetchNotifications();
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        }
    }, [isOpen, fetchNotifications]);
    
    const togglePanel = () => {
        setIsOpen(!isOpen);
    };
    
    const handleNotificationClick = (notification) => {
        // Mark notification as read without navigation
        markAsRead(notification.id);
        setIsOpen(false);
        
        // We're removing the navigation logic for now, just marking as read
    };
    
    const handleMarkAllAsRead = async (e) => {
        e.stopPropagation();
        
        if (isMarkingAllAsRead) return;
        
        setIsMarkingAllAsRead(true);
        
        try {
            const result = await markAllAsRead();
            
            if (result.success) {
                console.log('Successfully marked all notifications as read');
            } else {
                console.error('Failed to mark all notifications as read', result.error);
            }
        } catch (error) {
            console.error('Error in mark all as read handler:', error);
        } finally {
            setIsMarkingAllAsRead(false);
        }
    };
    
    // Get notification icon color based on type
    const getNotificationTypeColor = (type) => {
        switch (type) {
            case 'goal_completed':
                return 'notification-success';
            case 'goal_verified':
                return 'notification-success';
            case 'goal_rejected':
                return 'notification-danger';
            case 'comment_added':
                return 'notification-info';
            case 'feedback_added':
                return 'notification-info';
            case 'check_in_scheduled':
                return 'notification-primary';
            case 'check_in_completed':
                return 'notification-success';
            case 'check_in_cancelled':
                return 'notification-warning';
            case 'check_in_notes_added':
                return 'notification-info';
            case 'goal_period':
                return 'notification-primary';
            default:
                return '';
        }
    };

    const getNotificationIcon = (type) => {
        switch (type) {
            case 'goal_completed':
                return <FaCheckCircle />;
            case 'goal_verified':
                return <FaAward />;
            case 'goal_rejected':
                return <FaTimes />;
            case 'comment_added':
                return <FaComment />;
            case 'feedback_added':
                return <FaCommentDots />;
            case 'check_in_scheduled':
                return <FaCalendarCheck />;
            case 'check_in_completed':
                return <FaClipboardCheck />;
            case 'check_in_cancelled':
                return <FaCalendarTimes />;
            case 'check_in_notes_added':
                return <FaComments />;
            case 'goal_period':
                return <FaBullseye />;
            default:
                return <FaBell />;
        }
    };
    
    return (
        <div className="notification-container" ref={notificationPanelRef}>
            <button 
                className="notification-icon-button" 
                onClick={togglePanel}
                aria-label="Notifications"
            >
                <FaBell className="notification-bell-icon" />
                {unreadCount > 0 && (
                    <span className="notification-badge">{unreadCount}</span>
                )}
            </button>
            
            {isOpen && (
                <div className="notification-panel">
                    <div className="notification-header">
                        <h3>Notifications</h3>
                        {unreadCount > 0 && (
                            <button 
                                className="mark-all-read-button"
                                onClick={handleMarkAllAsRead}
                                disabled={isMarkingAllAsRead}
                            >
                                {isMarkingAllAsRead ? (
                                    <span>Processing...</span>
                                ) : (
                                    <span>Mark all as read</span>
                                )}
                            </button>
                        )}
                    </div>
                    
                    <div className="notification-list">
                        {(!notifications || !Array.isArray(notifications) || notifications.length === 0) ? (
                            <p className="no-notifications">No notifications</p>
                        ) : (
                            notifications.map(notification => (
                                <div 
                                    key={notification.id} 
                                    className={`notification-item ${notification.read ? 'read' : 'unread'}`}
                                    onClick={() => handleNotificationClick(notification)}
                                >
                                    <div 
                                        className={`notification-type-indicator ${getNotificationTypeColor(notification.type)}`}
                                    />
                                    <div className="notification-content">
                                        <p>{notification.content}</p>
                                        <span className="notification-time">
                                            {formatTimeAgo(notification.created_at)}
                                        </span>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default NotificationIcon; 