// Filename - pages/about.js

import React from 'react';
import './Styles/About.css';
import { useNavigate } from 'react-router-dom';
import { scrollToTop } from '../../Util/scrollHelper';

const About = () => {
    const navigate = useNavigate();
    return (
        <div className="about-body">
            <header className="about-header">
                <h1>Our Story</h1>
            </header>
            <section className="about-section mission">
                <h2 className="about-section-header">Goal setting is hard</h2>
                <p className="about-section-text">We all know the anxious feeling around performance reviews. We spend a serious amount of time setting goals for ourselves and our teams, 
                    but we struggle to come up with the right goals when there are no guidelines or expectations.
                <br/>
                <br/>
                Frustration kicks in when those goals are forgotten about by the next performance review. The process becomes tedious, and employees lose motivation.
                Managers are left looking for a better people development solution to get the best out of their teams.
                </p>
            </section>
            <section className="about-section story">
                <h2 className="about-section-header">Inspire your workforce</h2>
                <p className="about-section-text">Astellyst was born out of a desire to change the way businesses approach goal setting and performance management. 
                    By aligning the process with personal objectives and company aspirations, we bring goals to life.
                    <br/>
                    <br/>
                    With our AI-enabled platform, we:
                </p>
                <ul className="about-section-list">
                    <li className="about-section-list-item">Suggest goals tailored by your company's mission and employee profiles</li>
                    <li className="about-section-list-item">Track employee achievements with AI-powered analytics</li>
                    <li className="about-section-list-item">Provide feedback and recognition when goals are achieved</li>
                </ul>
            </section>
            <section className="about-section call-to-action">
                <h2 className="about-section-header">There are talents hidden in all of us</h2>
                <p className="about-section-text">Sign up now to unlock your team's potential</p>
                <button className="about-sign-up-button" onClick={() => { navigate('/SalesQuotePage'); scrollToTop(); }}>Sign up</button>
            </section>
        </div>
    );
};

export default About;
