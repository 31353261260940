import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie'; // Importing js-cookie for cookie management
import './Styles/LogInPage.css';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation
import { useAuth } from '../../Contexts/authContext'; // Import the Auth Context
import SessionTimeoutMessage from '../../Components/SessionTimeout/SessionTimeoutMessage';

function LogInPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate
    const location = useLocation();
    const { userRole, login } = useAuth(); // Get everything from auth context
    const hasNavigated = useRef(false); // Ref to track if navigation has occurred

    useEffect(() => {
        console.log('===== LOGIN PAGE RENDERED =====');
        
        // Check URL parameters for timeout flag
        const searchParams = new URLSearchParams(location.search);
        const hasTimeoutParam = searchParams.get('timeout') === 'true';
        
        // Check localStorage for timeout information
        const sessionExpired = localStorage.getItem('sessionExpired') === 'true';
        const inactivityTimeout = localStorage.getItem('inactivityTimeout') === 'true';
        const logoutTimestamp = parseInt(localStorage.getItem('logoutTimestamp') || '0');
        
        // Only consider timeout info from the last 60 seconds
        const isRecentTimeout = Date.now() - logoutTimestamp < 60000;
        
        console.log('Login page timeout information:', { 
            hasTimeoutParam, 
            sessionExpired, 
            inactivityTimeout, 
            logoutTimestamp,
            isRecentTimeout,
            timeSinceLogout: logoutTimestamp ? (Date.now() - logoutTimestamp) + 'ms' : 'N/A'
        });
        
        // We'll rely on the SessionTimeoutMessage component to display timeout messages
        // Only set non-timeout error messages here
        if ((hasTimeoutParam || isRecentTimeout) && sessionExpired) {
            // Ensure the URL parameter is cleared without reloading the page
            if (hasTimeoutParam) {
                const newUrl = window.location.pathname;
                window.history.replaceState({}, document.title, newUrl);
            }
            
            // We don't need to set loginError for timeouts anymore
            // The SessionTimeoutMessage will handle that
        }
    }, [location.search]);

    // Add another useEffect with a cleanup function
    useEffect(() => {
        // Cleanup function runs when component unmounts or before next effect
        return () => {
            console.log('Login page cleanup');
            // Only clear timeout flags when navigating away from login page
            // (not on initial mount)
            if (loginError) {
                localStorage.removeItem('sessionExpired');
                localStorage.removeItem('inactivityTimeout');
                localStorage.removeItem('logoutTimestamp');
            }
        };
    }, [loginError]);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoginError('');
        try {
            const response = await fetch(process.env.REACT_APP_LOGIN_API_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password }),
                credentials: 'include'
            });
            if (!response.ok) {
                throw new Error('Login failed');
            }
            const data = await response.json();
            if (data.message === 'Login successful') {
                const userId = Cookies.get('userId');
                const userRole = Cookies.get('userRole');
                const companyId = Cookies.get('company_id');
                const firstName = data.first_name;
                const lastName = data.last_name;

                console.log('Cookies:', { userId, userRole, companyId, firstName, lastName });

                // Use the login function from auth context
                login({
                    token: Cookies.get('authToken'),
                    userId,
                    userRole,
                    companyId,
                    firstName,
                    lastName
                });

                // Direct users to the dashboard tab of their respective portals
                const rolePathMap = {
                    'Admin': '/adminportal?tab=your-dashboard',
                    'Manager': '/managerportal?tab=your-dashboard',
                    'Employee': '/employeeportal?tab=dashboard' // Employee portal uses 'dashboard' instead of 'your-dashboard'
                };
                navigate(rolePathMap[userRole] || '/');
            } else {
                setLoginError('Invalid credentials');
            }
        } catch (error) {
            console.error('Error logging in:', error);
            setLoginError('Login failed');
        }
    };

    useEffect(() => {
        if (userRole && !hasNavigated.current) {
            console.log('Role from context:', userRole);
            const currentPath = location.pathname;
            let targetPath = '';

            if (userRole === 'Admin') {
                targetPath = '/adminportal?tab=your-dashboard';
            } else if (userRole === 'Manager') {
                targetPath = '/managerportal?tab=your-dashboard';
            } else {
                targetPath = '/employeeportal?tab=dashboard'; // Employee portal uses 'dashboard' instead of 'your-dashboard'
            }

            if (currentPath !== targetPath.split('?')[0]) {
                console.log(`Redirecting to ${targetPath}`);
                hasNavigated.current = true; // Set ref to true to prevent further navigation
                navigate(targetPath);
            }
        }
    }, [userRole, location.pathname, navigate]);

    const handleSSO = () => {
        // Handle SSO login logic here
        alert('Redirecting to SSO login...');
    };

    const handleForgotPassword = () => {
        // Redirect to ForgotPassword component
        navigate('/ForgotPassword'); // Update to use navigate
    };

    return (
        <div className="login-body">
            <SessionTimeoutMessage />
            <form onSubmit={handleLogin}>
                <div className="login-box">
                    <h1>Log In</h1>
                    <p>Sign in to your Astellyst account.</p>
                    
                    {/* Only show error messages that are not about timeouts/inactivity */}
                    {loginError && !loginError.includes('inactivity') && !loginError.includes('session has expired') && (
                        <div className="login-error-message">
                            {loginError}
                        </div>
                    )}
                    
                    <div className="login-container">
                        <input 
                            placeholder="Email"
                            className="login-input"
                            type="email" 
                            name="email"
                            id="email"
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            autoComplete="email"
                            required 
                        />
                    </div>
                    <div className="login-container">
                        <input 
                            placeholder="Password"
                            className="login-input"
                            type="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="forgot-password-container">
                        <p className="forgot-password-link" onClick={() => navigate('/ForgotPassword')}>
                            Forgot Password?
                        </p>
                    </div>
                    <div className="login-button-container">    
                        <button className="login-button" type="submit">Log In</button>
                    </div>
                    <div className="or-container">
                        <hr className="or-line" />
                        <span>or</span>
                        <hr className="or-line" />
                    </div>
                    <div className="login-sso-button-container">
                        <button className="login-sso-button" onClick={handleSSO}>Log In with SSO</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default LogInPage;
