import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../Contexts/authContext';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaEdit, FaTrash, FaSpinner, FaStore, FaArrowLeft, FaCoins, FaCheck, FaTimes, FaList, FaShoppingCart } from 'react-icons/fa';
import fetchWithAuth from '../../Util/fetchWithAuth';
import './Store.css';

const getImageUrl = (imageUrl) => {
    if (!imageUrl) return null;
    // If it's a full URL, use it directly
    if (imageUrl.startsWith('http://') || imageUrl.startsWith('https://')) {
        return imageUrl;
    }
    // If it's a relative path, prepend with the base URL based on environment
    const apiUrl = process.env.NODE_ENV === 'production' 
        ? process.env.REACT_APP_API_URL 
        : process.env.REACT_APP_LOCAL_API_URL;
    return apiUrl ? `${apiUrl}${imageUrl}` : imageUrl;
};

const StoreManager = ({ hideBackButton = false }) => {
    const [storeItems, setStoreItems] = useState([]);
    const [redemptions, setRedemptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [viewMode, setViewMode] = useState('items'); // 'items' or 'redemptions'
    const [showForm, setShowForm] = useState(false);
    const [formMode, setFormMode] = useState('add'); // 'add' or 'edit'
    const [currentItem, setCurrentItem] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        pointsCost: '',
        quantityAvailable: '',
        isActive: true
    });
    const [formErrors, setFormErrors] = useState({});
    const [image, setImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [notification, setNotification] = useState(null);
    const fileInputRef = useRef(null);

    const { companyId } = useAuth();
    const navigate = useNavigate();

    // Fetch store items
    useEffect(() => {
        const fetchStoreData = async () => {
            setLoading(true);
            try {
                const response = await fetchWithAuth(`/api/store/admin/items/${companyId}`);
                const data = await response.json();
                setStoreItems(data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching store items:', err);
                setNotification({
                    type: 'error',
                    message: 'Failed to load store items. Please try again.'
                });
                setLoading(false);
            }
        };

        if (viewMode === 'items') {
            fetchStoreData();
        }
    }, [companyId, viewMode]);

    // Fetch redemptions
    useEffect(() => {
        const fetchRedemptions = async () => {
            setLoading(true);
            try {
                const response = await fetchWithAuth(`/api/store/admin/redemptions/${companyId}`);
                const data = await response.json();
                setRedemptions(data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching redemptions:', err);
                setNotification({
                    type: 'error',
                    message: 'Failed to load redemptions. Please try again.'
                });
                setLoading(false);
            }
        };

        if (viewMode === 'redemptions') {
            fetchRedemptions();
        }
    }, [companyId, viewMode]);

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    // Handle image file selection
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };

    // Reset form data
    const resetForm = () => {
        setFormData({
            name: '',
            description: '',
            pointsCost: '',
            quantityAvailable: '',
            isActive: true
        });
        setImage(null);
        setPreviewUrl('');
        setFormErrors({});
    };

    // Open form for adding a new item
    const handleAddItem = () => {
        resetForm();
        setFormMode('add');
        setShowForm(true);
    };

    // Open form for editing an existing item
    const handleEditItem = (item) => {
        setCurrentItem(item);
        setFormData({
            name: item.name,
            description: item.description || '',
            pointsCost: item.points_cost,
            quantityAvailable: item.quantity_available,
            isActive: item.is_active
        });
        setPreviewUrl(item.image_url || '');
        setFormMode('edit');
        setShowForm(true);
    };

    // Validate form data
    const validateForm = () => {
        const errors = {};
        if (!formData.name.trim()) errors.name = 'Name is required';
        if (!formData.pointsCost) errors.pointsCost = 'Points cost is required';
        if (isNaN(Number(formData.pointsCost)) || Number(formData.pointsCost) <= 0) 
            errors.pointsCost = 'Points cost must be a positive number';
        if (!formData.quantityAvailable) errors.quantityAvailable = 'Quantity is required';
        if (isNaN(Number(formData.quantityAvailable)) || Number(formData.quantityAvailable) < 0) 
            errors.quantityAvailable = 'Quantity must be a non-negative number';

        return errors;
    };

    // Submit the form
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Validate form
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        setLoading(true);
        try {
            const formDataObj = new FormData();
            formDataObj.append('name', formData.name);
            formDataObj.append('description', formData.description);
            formDataObj.append('pointsCost', formData.pointsCost);
            formDataObj.append('quantityAvailable', formData.quantityAvailable);
            formDataObj.append('companyId', companyId);
            
            if (formMode === 'edit') {
                formDataObj.append('isActive', formData.isActive);
            }
            
            if (image) {
                formDataObj.append('image', image);
            }

            let response;
            if (formMode === 'add') {
                response = await fetchWithAuth('/api/store/items', {
                    method: 'POST',
                    body: formDataObj
                });
            } else {
                response = await fetchWithAuth(`/api/store/items/${currentItem.id}`, {
                    method: 'PUT',
                    body: formDataObj
                });
            }

            if (!response.ok) {
                throw new Error('Failed to save item');
            }

            // Refresh store items
            const itemsResponse = await fetchWithAuth(`/api/store/admin/items/${companyId}`);
            const data = await itemsResponse.json();
            setStoreItems(data);
            
            setNotification({
                type: 'success',
                message: formMode === 'add' ? 'Item added successfully!' : 'Item updated successfully!'
            });
            
            setShowForm(false);
            resetForm();
        } catch (err) {
            console.error('Error saving store item:', err);
            setNotification({
                type: 'error',
                message: 'Failed to save item. Please try again.'
            });
        } finally {
            setLoading(false);
        }
    };

    // Handle item deletion
    const handleDeleteItem = async (itemId) => {
        if (!window.confirm('Are you sure you want to delete this item?')) {
            return;
        }

        setLoading(true);
        try {
            const response = await fetchWithAuth(`/api/store/items/${itemId}`, {
                method: 'DELETE'
            });

            if (!response.ok) {
                throw new Error('Failed to delete item');
            }

            // Remove item from state
            setStoreItems(storeItems.filter(item => item.id !== itemId));
            
            setNotification({
                type: 'success',
                message: 'Item deleted successfully!'
            });
        } catch (err) {
            console.error('Error deleting store item:', err);
            setNotification({
                type: 'error',
                message: 'Failed to delete item. Please try again.'
            });
        } finally {
            setLoading(false);
        }
    };

    // Handle redemption status update
    const handleUpdateRedemptionStatus = async (redemptionId, newStatus) => {
        const statusText = newStatus === 'fulfilled' ? 'fulfill' : 'reject';
        if (!window.confirm(`Are you sure you want to ${statusText} this redemption?`)) {
            return;
        }

        setLoading(true);
        try {
            const response = await fetchWithAuth(`/api/store/admin/redemptions/${redemptionId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus })
            });

            if (!response.ok) {
                throw new Error(`Failed to ${statusText} redemption`);
            }

            // Update redemption status in state
            setRedemptions(redemptions.map(r => 
                r.id === redemptionId ? { ...r, status: newStatus } : r
            ));
            
            setNotification({
                type: 'success',
                message: `Redemption ${newStatus} successfully!`
            });
        } catch (err) {
            console.error(`Error ${statusText}ing redemption:`, err);
            setNotification({
                type: 'error',
                message: `Failed to ${statusText} redemption. Please try again.`
            });
        } finally {
            setLoading(false);
        }
    };

    // Format date to readable format
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    // Format points with comma for thousands
    const formatPoints = (points) => {
        return points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    if (loading && !storeItems.length && !redemptions.length) {
        return (
            <div className="store-loading">
                <FaSpinner className="loading-spinner" />
                <p>Loading...</p>
            </div>
        );
    }

    return (
        <div className="store-manager-container">
            <div className="store-manager-header">
                <div className="store-navigation">
                    {!hideBackButton && (
                        <button className="back-button" onClick={() => navigate(-1)}>
                            <FaArrowLeft /> Back
                        </button>
                    )}
                    <h1>Store Manager</h1>
                </div>
                <div className="store-manager-tabs">
                    <button 
                        className={`store-tab ${viewMode === 'items' ? 'active' : ''}`}
                        onClick={() => setViewMode('items')}
                    >
                        <FaStore /> Store Items
                    </button>
                    <button 
                        className={`store-tab ${viewMode === 'redemptions' ? 'active' : ''}`}
                        onClick={() => setViewMode('redemptions')}
                    >
                        <FaShoppingCart /> Redemptions
                    </button>
                </div>
            </div>

            {notification && (
                <div className={`store-notification ${notification.type}`}>
                    {notification.message}
                    <button 
                        className="close-notification" 
                        onClick={() => setNotification(null)}
                    >
                        ×
                    </button>
                </div>
            )}

            {viewMode === 'items' && (
                <div className="store-items-section">
                    <div className="store-section-header">
                        <h2>Manage Store Items</h2>
                        <button className="add-item-button" onClick={handleAddItem}>
                            <FaPlus /> Add New Item
                        </button>
                    </div>

                    {storeItems.length === 0 ? (
                        <div className="no-items-message">
                            <p>No items in the store yet. Add some items for employees to redeem!</p>
                        </div>
                    ) : (
                        <div className="store-items-table-container">
                            <table className="store-items-table">
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Points</th>
                                        <th>Quantity</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {storeItems.map(item => (
                                        <tr key={item.id} className={!item.is_active ? 'inactive-item' : ''}>
                                            <td className="item-image-cell">
                                                {item.image_url ? (
                                                    <img 
                                                        src={getImageUrl(item.image_url)} 
                                                        alt={item.name} 
                                                        className="table-item-image" 
                                                    />
                                                ) : (
                                                    <div className="no-image-small">
                                                        <FaStore />
                                                    </div>
                                                )}
                                            </td>
                                            <td>{item.name}</td>
                                            <td className="description-cell">{item.description}</td>
                                            <td>
                                                <div className="points-cell">
                                                    <FaCoins className="points-icon" />
                                                    <span>{formatPoints(item.points_cost)}</span>
                                                </div>
                                            </td>
                                            <td>{item.quantity_available}</td>
                                            <td>
                                                <span className={`item-status ${item.is_active ? 'active' : 'inactive'}`}>
                                                    {item.is_active ? 'Active' : 'Inactive'}
                                                </span>
                                            </td>
                                            <td>
                                                <div className="item-actions">
                                                    <button 
                                                        className="edit-button" 
                                                        onClick={() => handleEditItem(item)}
                                                        title="Edit Item"
                                                    >
                                                        <FaEdit />
                                                    </button>
                                                    <button 
                                                        className="delete-button" 
                                                        onClick={() => handleDeleteItem(item.id)}
                                                        title="Delete Item"
                                                    >
                                                        <FaTrash />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            )}

            {viewMode === 'redemptions' && (
                <div className="redemptions-section">
                    <div className="store-section-header">
                        <h2>Manage Redemptions</h2>
                    </div>

                    {redemptions.length === 0 ? (
                        <div className="no-items-message">
                            <p>No redemptions to manage yet.</p>
                        </div>
                    ) : (
                        <div className="redemptions-table-container">
                            <table className="redemptions-table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Employee</th>
                                        <th>Item</th>
                                        <th>Points</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {redemptions.map(redemption => (
                                        <tr key={redemption.id}>
                                            <td>{formatDate(redemption.created_at)}</td>
                                            <td>
                                                {redemption.first_name} {redemption.last_name}
                                                <div className="employee-email">{redemption.email}</div>
                                            </td>
                                            <td>
                                                <div className="redemption-item">
                                                    {redemption.image_url && (
                                                        <img 
                                                            src={getImageUrl(redemption.image_url)} 
                                                            alt={redemption.name} 
                                                            className="redemption-item-image" 
                                                        />
                                                    )}
                                                    <span>{redemption.name}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="points-cell">
                                                    <FaCoins className="points-icon" />
                                                    <span>{formatPoints(redemption.points_cost)}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <span className={`redemption-status ${redemption.status}`}>
                                                    {redemption.status.charAt(0).toUpperCase() + redemption.status.slice(1)}
                                                </span>
                                            </td>
                                            <td>
                                                {redemption.status === 'pending' && (
                                                    <div className="redemption-actions">
                                                        <button 
                                                            className="fulfill-button" 
                                                            onClick={() => handleUpdateRedemptionStatus(redemption.id, 'fulfilled')}
                                                            title="Fulfill Redemption"
                                                        >
                                                            <FaCheck /> Fulfill
                                                        </button>
                                                        <button 
                                                            className="reject-button" 
                                                            onClick={() => handleUpdateRedemptionStatus(redemption.id, 'rejected')}
                                                            title="Reject Redemption"
                                                        >
                                                            <FaTimes /> Reject
                                                        </button>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            )}

            {showForm && (
                <div className="store-form-modal">
                    <div className="store-form-container">
                        <h2>{formMode === 'add' ? 'Add New Item' : 'Edit Item'}</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-grid">
                                <div className="form-left">
                                    <div className="form-group">
                                        <label htmlFor="name">Item Name*</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            className={formErrors.name ? 'error' : ''}
                                        />
                                        {formErrors.name && <div className="error-message">{formErrors.name}</div>}
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="description">Description</label>
                                        <textarea
                                            id="description"
                                            name="description"
                                            value={formData.description}
                                            onChange={handleInputChange}
                                            rows="4"
                                        />
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group">
                                            <label htmlFor="pointsCost">Points Cost*</label>
                                            <input
                                                type="number"
                                                id="pointsCost"
                                                name="pointsCost"
                                                value={formData.pointsCost}
                                                onChange={handleInputChange}
                                                min="1"
                                                className={formErrors.pointsCost ? 'error' : ''}
                                            />
                                            {formErrors.pointsCost && <div className="error-message">{formErrors.pointsCost}</div>}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="quantityAvailable">Quantity Available*</label>
                                            <input
                                                type="number"
                                                id="quantityAvailable"
                                                name="quantityAvailable"
                                                value={formData.quantityAvailable}
                                                onChange={handleInputChange}
                                                min="0"
                                                className={formErrors.quantityAvailable ? 'error' : ''}
                                            />
                                            {formErrors.quantityAvailable && <div className="error-message">{formErrors.quantityAvailable}</div>}
                                        </div>
                                    </div>

                                    {formMode === 'edit' && (
                                        <div className="form-group checkbox-group">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="isActive"
                                                    checked={formData.isActive}
                                                    onChange={handleInputChange}
                                                />
                                                Item is active (visible in store)
                                            </label>
                                        </div>
                                    )}
                                </div>

                                <div className="form-right">
                                    <div className="form-group">
                                        <label>Item Image</label>
                                        <div className="image-upload-container">
                                            <div 
                                                className="image-preview"
                                                onClick={() => fileInputRef.current.click()}
                                            >
                                                {previewUrl ? (
                                                    <img src={getImageUrl(previewUrl)} alt="Preview" />
                                                ) : (
                                                    <div className="no-image">
                                                        <FaPlus />
                                                        <span>Add Image</span>
                                                    </div>
                                                )}
                                            </div>
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                onChange={handleImageChange}
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                            />
                                            <div className="image-upload-instructions">
                                                <p>Click to upload an image</p>
                                                <p className="image-hint">Recommended size: 500x500px, Max 5MB</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-actions">
                                <button type="submit" className="save-button">
                                    {loading ? <FaSpinner className="loading-spinner-small" /> : 'Save Item'}
                                </button>
                                <button 
                                    type="button" 
                                    className="cancel-button"
                                    onClick={() => setShowForm(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StoreManager; 