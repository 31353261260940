import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

// Import UserProvider and TabProvider
import { AuthProvider } from './Contexts/authContext';
import { TabProvider } from './Contexts/tabContext';
import { GoalSettingProvider } from './Contexts/goalSettingContext';
import { TeamContextProvider } from './Contexts/teamContext';
import { EmployeeContextProvider } from './Contexts/employeeContext';
import { NotificationProvider } from './Contexts/notificationContext';

// Import ScrollToTop component
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';

// Pages
import Home from "./Pages/HomePage/home";
import About from "./Pages/About/about";
import Contact from "./Pages/Contact/Contact";
import Resources from "./Pages/Resources/Resources";
import SupportPage from "./Pages/Support/Support";
import CareersPage from "./Pages/Careers/Careers";
import TermsOfUse from "./Pages/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import SalesQuotePage from "./Pages/SalesQuote/SalesQuotePage";
import LogInPage from "./Pages/LogInSelection/LogInPage";
import ForgotPassword from "./Pages/LogInSelection/ForgotPassword";
import AdminPortal from "./Pages/AdminPortal/AdminPortalPage/AdminPortal";
import AdminMaster from "./Pages/AdminPortal/AdminGoalSet/AdminMaster";
import EmployeeDirectory from "./Pages/AdminPortal/AdminPortalPage/EmployeeDirectory";
import Billing from "./Pages/AdminPortal/AdminPortalPage/Billing";
import ManagerPortal from "./Pages/ManagerPortal/ManagerPortalPage/ManagerPortal";
import ManagerMaster from "./Pages/ManagerPortal/ManagerGoalSet/ManagerMaster";
import TeamMembers from "./Pages/ManagerPortal/ManagerPortalPage/TeamMembers";
import EmployeePortal from "./Pages/EmployeePortal/EmployeePortalPage/EmployeePortal";
import EmployeeMaster from "./Pages/EmployeePortal/EmployeeGoalSet/EmployeeMaster";
import ProgressPage from './Pages/EmployeePortal/EmployeeProgress/ProgressPage';
import Dashboard from './Pages/EmployeePortal/Dashboard/Dashboard';
import EmployeeProfile from "./Pages/AdminPortal/AdminPortalPage/EmployeeProfile";
import EditEmployee from "./Pages/AdminPortal/AdminPortalPage/EditEmployee";
import ManagerProgress from "./Pages/ManagerPortal/ManagerPortalPage/ManagerProgress";
import StoreManagerPage from "./Pages/AdminPortal/AdminPortalPage/StoreManagerPage";
import CompanyStorePage from "./Pages/EmployeePortal/EmployeePortalPage/CompanyStorePage";
import AchievementsPage from "./Pages/EmployeePortal/Achievements/AchievementsPage";

// Components
import WithNav from "./Components/NavFunctionality/WithNav";
import WithoutNav from "./Components/NavFunctionality/WithoutNav";
import Footer from "./Components/Footer/Footer";
import ProtectedRoute from './Components/ProtectedRoute';
import SessionTimeoutMessage from './Components/SessionTimeout/SessionTimeoutMessage';
import SessionInactivityTracker from './Components/SessionTimeout/SessionInactivityTracker';

function Layout({ children }) {
  return (
    <>
      <SessionTimeoutMessage />
      <SessionInactivityTracker />
      {children}
      <Footer />
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <GoalSettingProvider>
        <TeamContextProvider>
          <EmployeeContextProvider>
            <NotificationProvider>
              <Router>
                <ScrollToTop />
                <Layout>
                  <div className="content">
                    <Routes>
                      <Route element={<WithoutNav />}>
                        <Route exact path="/" element={<Home />} />
                      </Route>
                      <Route element={<WithNav />}>
                        <Route exact path="/About" element={<About />} />
                        <Route exact path="/Contact" element={<Contact />} />
                        <Route exact path="/Resources" element={<Resources />} />
                        <Route exact path="/Support" element={<SupportPage />} />
                        <Route exact path="/Careers" element={<CareersPage />} />
                        <Route exact path="/TermsOfUse" element={<TermsOfUse />} />
                        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                        <Route exact path="/SalesQuotePage" element={<SalesQuotePage />} />
                        <Route exact path="/Login" element={<LogInPage />} />
                        <Route exact path="/ForgotPassword" element={<ForgotPassword />} />

                        {/* Admin Portal */}
                        <Route exact path="/AdminPortal" element={
                          <ProtectedRoute requiredRoles={['Admin']}>
                            <TabProvider>
                              <AdminPortal />
                            </TabProvider>
                          </ProtectedRoute>
                        } />
                        <Route exact path="/AdminMaster" element={
                          <ProtectedRoute requiredRoles={['Admin']}>
                            <TabProvider>
                              <AdminMaster />
                            </TabProvider>
                          </ProtectedRoute>
                        } />
                        <Route exact path="/EmployeeDirectory" element={
                          <ProtectedRoute requiredRoles={['Admin']}>
                            <TabProvider>
                              <EmployeeDirectory />
                            </TabProvider>
                          </ProtectedRoute>
                        } />
                        <Route exact path="/Billing" element={
                          <ProtectedRoute requiredRoles={['Admin']}>
                            <TabProvider>
                              <Billing />
                            </TabProvider>
                          </ProtectedRoute>
                        } />
                        <Route exact path="/ManagerPortal" element={
                          <ProtectedRoute requiredRoles={['Manager', 'Admin']}>
                            <TabProvider>
                              <ManagerPortal />
                            </TabProvider>
                          </ProtectedRoute>
                        } />
                        <Route exact path="/ManagerMaster" element={
                          <ProtectedRoute requiredRoles={['Manager', 'Admin']}>
                            <TabProvider>
                              <ManagerMaster />
                            </TabProvider>
                          </ProtectedRoute>
                        } />
                        <Route exact path="/TeamMembers" element={
                          <ProtectedRoute requiredRoles={['Manager', 'Admin']}>
                            <TabProvider>
                              <TeamMembers />
                            </TabProvider>
                          </ProtectedRoute>
                        } />
                        <Route exact path="/EmployeePortal" element={
                          <ProtectedRoute requiredRoles={['Employee', 'Admin', 'Manager']}>
                            <TabProvider>
                              <EmployeePortal />
                            </TabProvider>
                          </ProtectedRoute>
                        } />
                        <Route exact path="/EmployeeMaster" element={
                          <ProtectedRoute requiredRoles={['Employee', 'Admin', 'Manager']}>
                            <TabProvider>
                              <EmployeeMaster />
                            </TabProvider>
                          </ProtectedRoute>
                        } />
                        <Route exact path="/ProgressPage" element={
                          <ProtectedRoute requiredRoles={['Employee', 'Admin', 'Manager']}>
                            <TabProvider>
                              <ProgressPage />
                            </TabProvider>
                          </ProtectedRoute>
                        } />
                        <Route exact path="/Dashboard" element={
                          <ProtectedRoute requiredRoles={['Employee', 'Admin', 'Manager']}>
                            <TabProvider>
                              <Dashboard />
                            </TabProvider>
                          </ProtectedRoute>
                        } />
                        <Route exact path="/EmployeeProfile/:userId" element={
                          <ProtectedRoute requiredRoles={['Admin']}>
                            <EmployeeProfile />
                          </ProtectedRoute>
                        } />
                        <Route exact path="/EditEmployee/:userId" element={
                          <ProtectedRoute requiredRoles={['Admin']}>
                            <EditEmployee />
                          </ProtectedRoute>
                        } />
                        <Route exact path="/ManagerProgress/:userId" element={
                          <ProtectedRoute requiredRoles={['Manager', 'Admin']}>
                            <ManagerProgress />
                          </ProtectedRoute>
                        } />
                        <Route exact path="/StoreManager" element={
                          <ProtectedRoute requiredRoles={['Admin']}>
                            <TabProvider>
                              <StoreManagerPage />
                            </TabProvider>
                          </ProtectedRoute>
                        } />
                        <Route exact path="/CompanyStorePage" element={
                          <ProtectedRoute requiredRoles={['Employee', 'Admin', 'Manager']}>
                            <TabProvider>
                              <CompanyStorePage />
                            </TabProvider>
                          </ProtectedRoute>
                        } />
                        <Route exact path="/AchievementsPage" element={
                          <ProtectedRoute requiredRoles={['Employee', 'Admin', 'Manager']}>
                            <TabProvider>
                              <AchievementsPage />
                            </TabProvider>
                          </ProtectedRoute>
                        } />
                      </Route>
                    </Routes>
                  </div>
                </Layout>
              </Router>
            </NotificationProvider>
          </EmployeeContextProvider>
        </TeamContextProvider>
      </GoalSettingProvider>
    </AuthProvider>
  );
}

export default App;
