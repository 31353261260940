import React from 'react';
import { Link } from 'react-router-dom';
import { FaHeadset, FaChartLine } from 'react-icons/fa6';
import './Styles/Contact.css';

const Contact = () => {
    return (
        <div className='contact-body'>
            <h1>Want to get in touch?</h1>
            <div className="contact-container">
                <div className="contact-box">
                    <FaChartLine size={32} color="#6908C9" />
                    <h2>Sales</h2>
                    <p>Get a quote or learn about our products and how we can help transform your workplace</p>
                    <Link to="/SalesQuotePage" className="contact-button">Contact Sales</Link>
                </div>
                <div className="contact-box">
                    <FaHeadset size={32} color="#6908C9" />
                    <h2>Support</h2>
                    <p>Need help with your account? Our support team is here to assist you</p>
                    <Link to="/Support" className="contact-button">Get Support</Link>
                </div>
            </div>
            <div className="contact-h3-container">
                <h3 className="contact-h3">
                    For media inquiries and more, please contact us at{' '}
                    <a href="mailto:info@astellyst.com">info@astellyst.com</a>
                </h3>
            </div>
        </div>
    );
};

export default Contact;
