import React, { useState, useEffect } from "react";
import '../Styles/ProgressPage.css';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../Contexts/authContext';
import BackButton from '../../../Components/BackButton/BackButton';
import { FaArrowLeft, FaPaperPlane, FaCheck, FaTimes, FaSpinner, FaClipboardCheck, FaCalendarCheck, FaComments, FaComment, FaCommentDots, FaRegPaperPlane, FaArchive } from 'react-icons/fa';
import fetchWithAuth from '../../../Util/fetchWithAuth';

function ProgressPage() {
    const [goals, setGoals] = useState([]);
    const [commentsVisible, setCommentsVisible] = useState({});
    const [currentComment, setCurrentComment] = useState({});
    const [pendingComments, setPendingComments] = useState({});
    const [goalComments, setGoalComments] = useState({});
    const [goalTextVisible, setGoalTextVisible] = useState([]);
    
    // Verification state variables
    const [showVerificationModal, setShowVerificationModal] = useState(false);
    const [currentGoalId, setCurrentGoalId] = useState(null);
    const [verificationNotes, setVerificationNotes] = useState('');
    const [isSubmittingVerification, setIsSubmittingVerification] = useState(false);
    
    // New check-in state variables
    const [checkIns, setCheckIns] = useState([]);
    const [showCheckInSection, setShowCheckInSection] = useState(false);
    const [employeeNotes, setEmployeeNotes] = useState({});
    const [isSubmittingNotes, setIsSubmittingNotes] = useState(false);

    // Add this to your state variables at the top
    const [progressChanged, setProgressChanged] = useState({});

    // Archive confirmation state
    const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
    const [goalToArchive, setGoalToArchive] = useState(null);

    const navigate = useNavigate();
    const { userId, firstName, lastName } = useAuth();

    useEffect(() => {
        const fetchGoalsAndComments = async () => {
            try {
                const response = await fetchWithAuth(`/api/goals/get-goals/${userId}`);
                const data = await response.json();
                console.log("Fetched Goals:", data);
                setGoals(data);
                setCommentsVisible(data.reduce((acc, goal) => ({ ...acc, [goal.id]: false }), {}));
                setCurrentComment(data.reduce((acc, goal) => ({ ...acc, [goal.id]: "" }), {}));
            } catch (error) {
                console.error("Error fetching goals or comments:", error);
            }
        };

        if (userId) {
            fetchGoalsAndComments();
            fetchCheckIns(); // Fetch check-ins when component loads
        }
    }, [userId]);
    
    // Function to fetch check-ins for this employee
    const fetchCheckIns = async () => {
        try {
            const response = await fetchWithAuth(`/api/check-ins/employee/${userId}`);
            if (response.ok) {
                const data = await response.json();
                setCheckIns(data);
                
                // Initialize employee notes state
                const notesState = {};
                data.forEach(checkIn => {
                    notesState[checkIn.id] = checkIn.employeeNotes || '';
                });
                setEmployeeNotes(notesState);
            } else {
                console.error("Failed to fetch check-ins");
            }
        } catch (error) {
            console.error("Error fetching check-ins:", error);
        }
    };
    
    // Function to save employee notes for a check-in
    const handleSaveNotes = async (checkInId) => {
        setIsSubmittingNotes(true);
        
        try {
            const response = await fetchWithAuth(`/api/check-ins/${checkInId}/employee-notes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    employeeNotes: employeeNotes[checkInId]
                })
            });
            
            if (!response.ok) {
                throw new Error('Failed to save notes');
            }
            
            alert('Your notes have been saved');
        } catch (error) {
            console.error('Error saving notes:', error);
            alert('Failed to save notes. Please try again.');
        } finally {
            setIsSubmittingNotes(false);
        }
    };
    
    // Function to handle employee notes change
    const handleNotesChange = (checkInId, notes) => {
        setEmployeeNotes(prev => ({
            ...prev,
            [checkInId]: notes
        }));
    };

    const handleProgressChange = (index, newProgress) => {
        const updatedGoals = [...goals];
        updatedGoals[index].progress = newProgress;
        setGoals(updatedGoals);
        
        // Track that this goal's progress has changed
        setProgressChanged(prev => ({
            ...prev,
            [goals[index].id]: true
        }));
    };

    const handleAddComment = (index) => {
        const comment = currentComment[index];
        if (!comment?.trim()) return;

        setPendingComments(prev => ({
            ...prev,
            [index]: comment
        }));
        setCurrentComment(prev => ({...prev, [index]: ''}));
    };

    const handleClearComment = (index) => {
        setPendingComments(prev => {
            const newPendingComments = { ...prev };
            delete newPendingComments[index];
            return newPendingComments;
        });
    };
    
    // Function to show verification request modal
    const handleShowVerificationModal = (goalId) => {
        setCurrentGoalId(goalId);
        setVerificationNotes('');
        setShowVerificationModal(true);
    };
    
    // Function to handle verification request
    const handleVerificationRequest = async () => {
        if (!verificationNotes.trim()) {
            alert('Please provide details about how you completed this goal.');
            return;
        }
        
        setIsSubmittingVerification(true);
        
        try {
            const response = await fetchWithAuth(`/api/goal-verification/request/${currentGoalId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    notes: verificationNotes 
                })
            });
            
            if (!response.ok) {
                throw new Error('Failed to submit verification request');
            }
            
            // Update goal status locally
            setGoals(prev => prev.map(goal => 
                goal.id === currentGoalId 
                    ? { ...goal, verification_status: 'pending', verification_notes: verificationNotes } 
                    : goal
            ));
            
            setShowVerificationModal(false);
            alert('Verification request submitted successfully.');
        } catch (error) {
            console.error('Error submitting verification request:', error);
            alert('Failed to submit verification request. Please try again.');
        } finally {
            setIsSubmittingVerification(false);
        }
    };

    const handleSubmit = async () => {
        const commentsToSubmit = Object.entries(pendingComments)
            .filter(([_, comment]) => comment && comment.trim() !== "")
            .map(([index, comment]) => ({
                goalId: goals[index].id,
                comment: `${comment} - ${firstName} ${lastName}`,
                userId,
                progress: goals[index].progress
            }));
        
        // Get goals with changed progress but no comments
        const progressOnlyUpdates = goals
            .filter((goal, index) => 
                progressChanged[goal.id] && 
                !Object.keys(pendingComments).includes(index.toString())
            )
            .map(goal => ({
                goalId: goal.id,
                progress: goal.progress,
                userId
            }));
        
        if (commentsToSubmit.length === 0 && progressOnlyUpdates.length === 0) {
            alert('No changes to submit');
            return;
        }
        
        try {
            // Submit comments with progress
            if (commentsToSubmit.length > 0) {
                const submitPromises = commentsToSubmit.map(comment => {
                    return fetchWithAuth('/api/comments/add-comment', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(comment)
                    });
                });
                
                await Promise.all(submitPromises);
            }
            
            // Submit progress-only updates
            if (progressOnlyUpdates.length > 0) {
                const progressPromises = progressOnlyUpdates.map(update => {
                    return fetchWithAuth('/api/goals/update-progress', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(update)
                    });
                });
                
                await Promise.all(progressPromises);
            }
            
            // Reset states after successful submission
            setPendingComments({});
            setProgressChanged({});
            
            // Refresh comments for all goals that had comments submitted
            const goalIdsWithComments = commentsToSubmit.map(comment => comment.goalId);
            await Promise.all(goalIdsWithComments.map(goalId => handleShowComments(goalId)));
            
            alert('Changes submitted successfully');
        } catch (error) {
            console.error('Error submitting changes:', error);
            alert('Error submitting changes. Please try again.');
        }
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleGoalTextToggle = (index) => {
        setGoalTextVisible((prev) => {
            const newVisibility = [...prev];
            newVisibility[index] = !newVisibility[index];
            return newVisibility;
        });
    };

    const handleShowComments = async (goalId) => {
        try {
            // Always fetch the latest comments
            const response = await fetchWithAuth(`/api/comments/get-comments/${goalId}`);
            const data = await response.json();
            setGoalComments((prev) => ({
                ...prev,
                [goalId]: data,
            }));
            
            // Set comments to visible if they're not already
            if (!commentsVisible[goalId]) {
                setCommentsVisible((prev) => ({
                    ...prev,
                    [goalId]: true,
                }));
            }
        } catch (error) {
            console.error('Error fetching all comments:', error);
        }
    };
    
    // Helper function to render verification status
    const renderVerificationStatus = (goal) => {
        const status = goal.verification_status;
        
        if (goal.progress < 100) {
            return null; // Don't show any verification status if progress is less than 100%
        }
        
        if (!status || status === 'not_submitted') {
            return (
                <div className="progress-page-verification-status">
                    <span>Not verified</span>
                </div>
            );
        } else if (status === 'pending') {
            return (
                <div className="progress-page-verification-status progress-page-status-pending">
                    <FaSpinner className="progress-page-fa-spin" />
                    <span>Verification pending</span>
                </div>
            );
        } else if (status === 'verified') {
            return (
                <div className="progress-page-verification-status progress-page-status-verified">
                    <FaCheck />
                    <span>Verified</span>
                </div>
            );
        } else if (status === 'rejected') {
            return (
                <div className="progress-page-verification-status progress-page-status-rejected">
                    <FaTimes />
                    <span>Verification rejected</span>
                </div>
            );
        }
    };

    // Function to handle showing archive confirmation
    const handleShowArchiveConfirmation = (goal) => {
        setGoalToArchive(goal);
        setShowArchiveConfirmation(true);
    };
    
    // Function to archive a goal
    const handleArchiveGoal = async () => {
        if (!goalToArchive) return;
        
        try {
            const response = await fetchWithAuth(`/api/goals/toggle-archive/${goalToArchive.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    archive: true
                })
            });
            
            if (!response.ok) {
                throw new Error('Failed to archive goal');
            }
            
            // Remove the goal from the current list
            setGoals(prev => prev.filter(goal => goal.id !== goalToArchive.id));
            
            setShowArchiveConfirmation(false);
            setGoalToArchive(null);
            
            alert('Goal archived successfully. You can find it in the "Review Old Goals" section.');
        } catch (error) {
            console.error('Error archiving goal:', error);
            alert('Failed to archive goal. Please try again.');
        }
    };

    return (
        <div className="page-container">
            <div className="back-button-container">
                <BackButton onClick={handleGoBack} />
            </div>
            <div className="page-content">
                <header className="progress-page-header">
                    <h2 className="page-header progress-page-header-text">Goal Progress</h2>
                    
                    {/* Check-in Toggle Button */}
                    <button 
                        className="progress-page-checkin-button"
                        onClick={() => setShowCheckInSection(!showCheckInSection)}
                    >
                        <FaCalendarCheck /> {showCheckInSection ? "Hide Check-ins" : "Show Check-ins"}
                    </button>
                </header>
                
                {/* Check-in Section */}
                {showCheckInSection && (
                    <div className="progress-page-checkin-section">
                        <h3>Your Check-ins</h3>
                        
                        {checkIns.length > 0 ? (
                            <div className="checkin-list">
                                {checkIns.map(checkIn => {
                                    const isUpcoming = new Date(checkIn.scheduledDate) > new Date() && checkIn.status === 'scheduled';
                                    const isPast = checkIn.status === 'completed';
                                    const isCancelled = checkIn.status === 'cancelled';
                                    
                                    return (
                                        <div 
                                            key={checkIn.id} 
                                            className={`checkin-item ${isUpcoming ? 'upcoming-checkin' : ''} ${isPast ? 'past-checkin' : ''} ${isCancelled ? 'cancelled-checkin' : ''}`}
                                        >
                                            <div className="checkin-header">
                                                <div className="checkin-date">
                                                    <strong>{isUpcoming ? 'Upcoming: ' : isPast ? 'Completed: ' : 'Scheduled: '}</strong> 
                                                    {new Date(checkIn.scheduledDate).toLocaleString()}
                                                </div>
                                                <div className="checkin-status">
                                                    <span className="status-badge">{checkIn.status}</span>
                                                </div>
                                            </div>
                                            
                                            {checkIn.completedDate && (
                                                <div className="checkin-completed-date">
                                                    <strong>Completed on:</strong> {new Date(checkIn.completedDate).toLocaleString()}
                                                </div>
                                            )}
                                            
                                            {checkIn.managerNotes && (
                                                <div className="checkin-manager-notes">
                                                    <strong>Manager's Notes:</strong>
                                                    <p>{checkIn.managerNotes}</p>
                                                </div>
                                            )}
                                            
                                            {checkIn.status !== 'cancelled' && (
                                                <div className="checkin-employee-notes">
                                                    <strong>Your Notes/Topics to Discuss:</strong>
                                                    <textarea
                                                        value={employeeNotes[checkIn.id] || ''}
                                                        onChange={(e) => handleNotesChange(checkIn.id, e.target.value)}
                                                        placeholder="Add topics you'd like to discuss during this check-in..."
                                                        rows={3}
                                                        disabled={isPast}
                                                    ></textarea>
                                                    
                                                    {!isPast && (
                                                        <button 
                                                            className="save-notes-button"
                                                            onClick={() => handleSaveNotes(checkIn.id)}
                                                            disabled={isSubmittingNotes}
                                                        >
                                                            <FaComments /> Save Notes
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                            
                                            {checkIn.relatedGoalIds && checkIn.relatedGoalIds.length > 0 && (
                                                <div className="checkin-related-goals">
                                                    <strong>Goals to Discuss:</strong>
                                                    <ul>
                                                        {checkIn.relatedGoalIds.map(goalId => {
                                                            const goal = goals.find(g => g.id === goalId);
                                                            return goal ? (
                                                                <li key={goalId}>{goal.summary}</li>
                                                            ) : null;
                                                        })}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <p>No check-ins scheduled yet.</p>
                        )}
                    </div>
                )}

                <div className="progress-page-goals">
                    {goals.map((goal, index) => (
                        <div key={goal.id} className="goal-card">
                            <h3 className="progress-page-goal-name">{goal.summary}</h3>
                            
                            <button 
                                className="action-button"
                                onClick={() => handleGoalTextToggle(index)}
                            >
                                {goalTextVisible[index] ? "Show Less" : "Show More"}
                            </button>
                            
                            {goalTextVisible[index] && (
                                <p className="progress-page-goal-text">{goal.goal_text}</p>
                            )}
                            
                            <div className="progress-bar-container">
                                <input
                                    type="range"
                                    className="progress-page-goal-progress"
                                    value={goal.progress}
                                    onChange={(e) => handleProgressChange(index, e.target.value)}
                                />
                                <span className="progress-percentage">{goal.progress}%</span>
                            </div>
                            
                            {/* Verification controls */}
                            <div className="progress-page-verification-controls">
                                {goal.progress == 100 && (!goal.verification_status || goal.verification_status === 'not_submitted') && (
                                    <button 
                                        className="progress-page-add-verification-button"
                                        onClick={() => handleShowVerificationModal(goal.id)}
                                    >
                                        <FaClipboardCheck /> Request Verification
                                    </button>
                                )}
                                
                                {renderVerificationStatus(goal)}
                            </div>
                            
                            {/* Verification notes (if submitted) */}
                            {goal.verification_status === 'pending' && goal.verification_notes && (
                                <div className="progress-page-verification-notes-section">
                                    <h4>Your Verification Notes</h4>
                                    <p>{goal.verification_notes}</p>
                                </div>
                            )}
                            
                            <div className="comments-section">
                                <div className="feedback-composer">
                                    <div className="feedback-composer-header">
                                        <FaCommentDots /> Add Your Comment
                                    </div>
                                    <textarea
                                        className="progress-page-goal-comment"
                                        placeholder="Share your thoughts or update on this goal's progress..."
                                        value={currentComment[index] || ''}
                                        onChange={(e) => setCurrentComment((prev) => ({
                                            ...prev,
                                            [index]: e.target.value,
                                        }))}
                                    />
                                    <div className="feedback-composer-actions">
                                        <button 
                                            className="action-button"
                                            onClick={() => handleShowComments(goal.id)}
                                        >
                                            {commentsVisible[goal.id] ? "Hide Discussion" : "View Discussion"}
                                        </button>
                                        
                                        <button 
                                            className="action-button primary"
                                            onClick={() => handleAddComment(index)}
                                            disabled={!currentComment[index]?.trim()}
                                        >
                                            <FaRegPaperPlane /> Add Comment
                                        </button>
                                    </div>
                                </div>

                                {pendingComments[index] && (
                                    <div className="pending-comment">
                                        <div className="pending-comment-header">
                                            <p><FaComment /> Pending comment</p>
                                            <button 
                                                className="clear-feedback-button"
                                                onClick={() => handleClearComment(index)}
                                                title="Clear pending comment"
                                            >
                                                ×
                                            </button>
                                        </div>
                                        <p>{pendingComments[index]}</p>
                                    </div>
                                )}
                                
                                {commentsVisible[goal.id] && (
                                    <div className="comment-history">
                                        {goalComments[goal.id]?.length > 0 ? (
                                            goalComments[goal.id].map((comment, idx) => (
                                                <div 
                                                    key={idx} 
                                                    className={`comment-item ${comment.type === 'manager' ? 'manager' : 'employee'}`}
                                                >
                                                    <div className="comment-metadata">
                                                        <span className="comment-author">
                                                            {comment.first_name} {comment.last_name}
                                                        </span>
                                                        <span className="comment-time">
                                                            {new Date(comment.date).toLocaleDateString()} {new Date(comment.date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                                                        </span>
                                                    </div>
                                                    <div className="comment-content">
                                                        {comment.comment.split(' - ')[0]}
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="no-comments-message">
                                                No discussion yet. Be the first to add a comment!
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="goal-buttons">
                                {goal.progress === 100 && !goal.verification_status && (
                                    <button 
                                        className="request-verification-button"
                                        onClick={() => handleShowVerificationModal(goal.id)}
                                    >
                                        <FaClipboardCheck /> Request Verification
                                    </button>
                                )}
                                {/* Add Archive button */}
                                <button 
                                    className="archive-goal-button"
                                    onClick={() => handleShowArchiveConfirmation(goal)}
                                >
                                    <FaArchive /> Archive
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="progress-page-submit-container">
                    <button className="progress-page-submit-link" onClick={handleSubmit}>
                        <FaPaperPlane /> Submit
                    </button>
                </div>
            </div>
            
            {/* Verification Request Modal */}
            {showVerificationModal && (
                <div className="progress-page-verification-modal">
                    <div className="progress-page-verification-modal-content">
                        <h3>Request Goal Verification</h3>
                        <p>Please provide details about how you completed this goal.</p>
                        
                        <div className="progress-page-verification-form">
                            <div className="progress-page-form-group">
                                <label>Completion Details</label>
                                <textarea
                                    value={verificationNotes}
                                    onChange={(e) => setVerificationNotes(e.target.value)}
                                    placeholder="Describe how you completed this goal and where your manager can find evidence (e.g., 'Shared certificate via email', 'Results posted in Slack channel')"
                                    rows={5}
                                ></textarea>
                            </div>
                        </div>
                        
                        <div className="progress-page-verification-modal-actions">
                            <button 
                                className="action-button"
                                onClick={() => setShowVerificationModal(false)}
                            >
                                Cancel
                            </button>
                            <button 
                                className="action-button primary"
                                onClick={handleVerificationRequest}
                                disabled={isSubmittingVerification}
                            >
                                {isSubmittingVerification ? 'Submitting...' : 'Submit for Verification'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            
            {/* Archive Confirmation Modal */}
            {showArchiveConfirmation && (
                <div className="archive-modal-overlay">
                    <div className="archive-modal">
                        <h3>Archive Goal</h3>
                        <p>Are you sure you want to archive this goal?</p>
                        <p><strong>{goalToArchive?.summary || goalToArchive?.goal_text}</strong></p>
                        <p className="archive-note">The goal will be moved to "Review Old Goals" section.</p>
                        
                        <div className="archive-modal-buttons">
                            <button className="archive-confirm-button" onClick={handleArchiveGoal}>
                                <FaArchive /> Archive Goal
                            </button>
                            <button className="archive-cancel-button" onClick={() => setShowArchiveConfirmation(false)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProgressPage;
