// src/Pages/ManagerPortal/TeamMembers.js
import React, { useState, useEffect } from 'react';
import '../Styles/TeamMembers.css';
import { useAuth } from '../../../Contexts/authContext'; // Import the useAuth hook
import { Link, useNavigate } from 'react-router-dom'; // Import Link and useNavigate
import BackButton from '../../../Components/BackButton/BackButton';
import fetchWithAuth from '../../../Util/fetchWithAuth';

const TeamMembers = () => {
    const { userId, userRole } = useAuth(); // Get the userId and userRole from the context
    const navigate = useNavigate();
    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        const fetchTeamMembers = async () => {
            try {
                const response = await fetchWithAuth(`/api/users/get-team-members/${userId}`);
                const data = await response.json();
                setTeamMembers(data);
            } catch (error) {
                console.error('Error fetching team members:', error);
            }
        };

        if (userId) {
            fetchTeamMembers();
        }
    }, [userId]);

    const handleBackLinkClick = () => {
        let portalPath = '/managerportal?tab=your-team'; // Default path for managers

        if (userRole === 'Admin') {
            portalPath = '/adminportal?tab=your-team'; // Path for admin
        } else if (userRole === 'Manager') {
            portalPath = '/managerportal?tab=your-team'; // Path for manager
        }
        navigate(portalPath); // Using navigate instead of window.location.href
    };

    return (
        <div className="page-container">
            <div className="back-button-container">
                <BackButton onClick={handleBackLinkClick} />
            </div>
            <div className="page-content">
                <h1 className="page-header">Your Team Members</h1>
                <div className="employee-directory">
                    <ul className="team-members-list">
                        {teamMembers.map(member => (
                            <li key={member.id}>
                                <Link to={`/ManagerProgress/${member.id}`}>
                                    {member.first_name} {member.last_name} - {member.job_title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default TeamMembers;