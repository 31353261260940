import React, { useEffect, useCallback } from 'react';
import { useAuth } from '../../Contexts/authContext';
import Cookies from 'js-cookie';

const SessionInactivityTracker = () => {
  const { logout } = useAuth();
  
  // Change from 1 minute (60000 ms) to 2 hours (7200000 ms)
  const INACTIVITY_TIMEOUT = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
  
  console.log('SessionInactivityTracker mounted'); // Verify component mounts
  
  // Check if user is authenticated by looking for any auth cookie
  const isAuthenticated = () => {
    return !!(Cookies.get('userId') || Cookies.get('userRole'));
  };
  
  // Use useCallback to prevent unnecessary function recreations
  const handleSessionTimeout = useCallback(() => {
    // Only proceed if user is still logged in
    if (isAuthenticated()) {
      console.log('User inactive for 2 hours, logging out automatically');
      
      // Set session expired flag for message display
      localStorage.setItem('sessionExpired', 'true');
      localStorage.setItem('inactivityTimeout', 'true');
      localStorage.setItem('logoutTimestamp', Date.now().toString());
      
      // Log the user out via context - this will handle cookie removal and redirect
      logout(true);
    }
  }, [logout]);
  
  useEffect(() => {
    console.log('Setting up inactivity tracker with timeout:', INACTIVITY_TIMEOUT);
    let inactivityTimer;
    
    // Function to reset the timer
    const resetTimer = () => {
      // Only set timer if user is logged in
      if (isAuthenticated()) {
        console.log('Activity detected, resetting timer');
        // Clear existing timer
        clearTimeout(inactivityTimer);
        
        // Set a new timer
        inactivityTimer = setTimeout(handleSessionTimeout, INACTIVITY_TIMEOUT);
      }
    };
    
    // Events to track user activity
    const activityEvents = [
      'mousedown', 'mousemove', 'keypress', 
      'scroll', 'touchstart', 'click', 'keydown'
    ];
    
    // Start the initial timer if user is logged in
    if (isAuthenticated()) {
      console.log('User is logged in, starting initial timer');
      resetTimer();
    } else {
      console.log('No auth cookie found, inactivity tracker inactive');
    }
    
    // Add event listeners for all activity events
    activityEvents.forEach(event => {
      document.addEventListener(event, resetTimer);
    });
    
    // Clean up on unmount
    return () => {
      console.log('Cleaning up inactivity tracker');
      clearTimeout(inactivityTimer);
      activityEvents.forEach(event => {
        document.removeEventListener(event, resetTimer);
      });
    };
  }, [handleSessionTimeout, INACTIVITY_TIMEOUT]);
  
  // This component doesn't render anything
  return null;
};

export default SessionInactivityTracker; 