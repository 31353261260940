import React, { useState } from 'react';
import '../AIButton/AIButton.css'; // Reuse the same styling
import { FaWandMagicSparkles } from 'react-icons/fa6';
import fetchWithAuth from '../../Util/fetchWithAuth';

function TeamAIButton({ userId, onNewGoal }) {
    const [isGenerating, setIsGenerating] = useState(false);

    const handleGenerateTeamGoal = async () => {
        if (isGenerating) return;
        
        setIsGenerating(true);
        try {
            const response = await fetchWithAuth('/api/ai-team-goals/generate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ managerId: userId })
            });
            
            if (!response.ok) {
                throw new Error('Failed to generate team goal');
            }
            
            const data = await response.json();
            onNewGoal(data.goal);
        } catch (error) {
            console.error('Error generating team goal:', error);
            alert('Failed to generate team goal. Please try again.');
        } finally {
            setIsGenerating(false);
        }
    };

    return (
        <button 
            onClick={handleGenerateTeamGoal} 
            disabled={isGenerating} 
            className={`astellyst-ai-button ${isGenerating ? 'generating' : ''}`}
        >
            <FaWandMagicSparkles /> {isGenerating ? 'Generating...' : 'Generate Suggestion'}
        </button>
    );
}

export default TeamAIButton;
