import React, { useState } from 'react';
import './AIButton.css';
import fetchWithAuth from '../../Util/fetchWithAuth';

function AIButton({ userId, onNewGoal, contextData, icon }) {
    const [isGenerating, setIsGenerating] = useState(false);

    const handleGenerateGoal = async () => {
        if (isGenerating) return;
        
        setIsGenerating(true);
        try {
            const requestData = {
                userId,
                contextType: contextData ? 'employee' : 'default'
            };

            // Add context data if available
            if (contextData) {
                requestData.context = {
                    workStyle: contextData.workStyle,
                    skillLevel: contextData.skillLevel,
                    careerInterests: contextData.careerInterests,
                    challenges: contextData.challenges,
                    strengths: contextData.strengths,
                    developmentAreas: contextData.developmentAreas,
                    priorities: contextData.priorities,
                    learningPreferences: contextData.learningPreferences
                };
            }

            const response = await fetchWithAuth('/api/ai-goals/generate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                throw new Error('Failed to generate goal');
            }

            const data = await response.json();
            onNewGoal(data.goal);
        } catch (error) {
            console.error('Error generating goal:', error);
            alert('Failed to generate a goal. Please try again.');
        } finally {
            setIsGenerating(false);
        }
    };

    return (
        <button 
            onClick={handleGenerateGoal} 
            className={`astellyst-ai-button ${isGenerating ? 'generating' : ''}`}
            disabled={isGenerating}
        >
            {icon} {isGenerating ? 'Generating...' : 'Generate Suggestion'}
        </button>
    );
}

export default AIButton;
