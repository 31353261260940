import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../Contexts/authContext';

const ProtectedRoute = ({ children, requiredRoles }) => {
    const { userRole, isAuthenticated } = useAuth();

    // If not authenticated, redirect to login
    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    // If authenticated but doesn't have required role, redirect to login
    if (requiredRoles && !requiredRoles.includes(userRole)) {
        return <Navigate to="/login" />;
    }

    // User is authenticated and has required role
    return children;
};

export default ProtectedRoute;
