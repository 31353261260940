import React, { useState, useRef, useEffect, useMemo } from 'react';
import AdminStep1 from './AdminStep1';
import AdminStep2 from './AdminStep2';
import AdminStep3 from './AdminStep3';
import '../Styles/AdminMaster.css';
import { attachAutoExpand, detachAutoExpand } from '../../../Util/autoExpand';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../Contexts/authContext';
import { scrollToTop } from '../../../Util/scrollHelper';

function AdminMaster() {
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const initialStep = parseInt(queryParams.get('step'), 10);

    const [currentStep, setCurrentStep] = useState(initialStep || 0);

    const [companyMission, setCompanyMission] = useState({ text: '', confirmed: false });
    const [companyGoals, setCompanyGoals] = useState([]);
    const textareaRef = useRef(null);
    const { companyId } = useAuth();
    console.log('Company ID from context:', companyId);

    useEffect(() => {
        if (companyId) {
            console.log('Fetching company mission for companyId:', companyId);
            fetch(`/api/company-missions/mission/${companyId}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => setCompanyMission({ text: data.mission || '', confirmed: true }))
                .catch(error => {
                    console.error('Error fetching company mission:', error);
                });
        }
    }, [companyId]);

    useEffect(() => {
        if (companyId) {
            console.log('Fetching company goals for companyId:', companyId);
            fetch(`/api/company-goals/get-goals/${companyId}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => setCompanyGoals(data))
                .catch(error => {
                    console.error('Error fetching company goals:', error);
                });
        }
    }, [companyId]);

    useEffect(() => {
        if (textareaRef.current) {
            attachAutoExpand(textareaRef.current);
        }
        return () => {
            if (textareaRef.current) {
                detachAutoExpand(textareaRef.current);
            }
        };
    }, [textareaRef]);

    useEffect(() => {
        const handlePopState = (event) => {
            const step = event.state?.step || 0;
            setCurrentStep(step);
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    const updateStep = (newStep) => {
        setCurrentStep(newStep);
        window.history.pushState({ step: newStep }, '', `?step=${newStep}`);
        scrollToTop();
    };

    const nextStep = () => updateStep(currentStep + 1);
    const prevStep = () => updateStep(currentStep - 1);

    const saveForLater = () => {
        submitCompanyMission(true);
        submitCompanyGoals(true);
        scrollToTop();
        alert('Progress saved for later!');
    };

    const submitCompanyMission = (isDraft = false) => {
        fetch('/api/company-missions/mission', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                company_id: companyId, 
                mission: companyMission.text,
                is_draft: isDraft 
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log('Company mission saved successfully!');
        })
        .catch(error => {
            console.error('Error saving company mission:', error);
        });
    };

    const submitCompanyGoals = (isDraft = false) => {
        const newGoals = companyGoals.filter(goal => !goal.id);
        const existingGoals = companyGoals.filter(goal => goal.id);

        if (newGoals.length > 0) {
            fetch('/api/company-goals/create-goal', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    company_id: companyId, 
                    goals: newGoals,
                    is_draft: isDraft 
                }),
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('New goals saved successfully:', data);
            })
            .catch(error => {
                console.error('Error saving new goals:', error);
            });
        }

        if (existingGoals.length > 0) {
            fetch('/api/company-goals/update-goals', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    company_id: companyId, 
                    goals: existingGoals,
                    is_draft: isDraft 
                }),
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Existing goals updated successfully:', data);
            })
            .catch(error => {
                console.error('Error updating existing goals:', error);
            });
        }
    };

    const submitData = async () => {
        try {
            // Submit mission
            await fetch('/api/company-missions/mission', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    company_id: companyId, 
                    mission: companyMission.text,
                    is_draft: false 
                }),
            });

            // Submit goals
            await fetch('/api/company-goals/create-goal', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    company_id: companyId, 
                    goals: companyGoals,
                    is_draft: false 
                }),
            });

            scrollToTop();
            return Promise.resolve();
        } catch (error) {
            console.error('Error submitting data:', error);
            return Promise.reject(error);
        }
    };

    return (
        <div className='admin-goal-set-body'>
            {currentStep === 0 && (
                <AdminStep1
                    companyMission={companyMission}
                    setCompanyMission={setCompanyMission}
                    nextStep={nextStep}
                    saveForLater={saveForLater}
                    textareaRef={textareaRef}
                    company_id={companyId}
                />
            )}
            {currentStep === 1 && (
                <AdminStep2
                    companyGoals={companyGoals}
                    setCompanyGoals={setCompanyGoals}
                    prevStep={prevStep}
                    nextStep={nextStep}
                    saveForLater={saveForLater}
                    textareaRef={textareaRef}
                    company_id={companyId}
                />
            )}
            {currentStep === 2 && (
                <AdminStep3
                    companyMission={companyMission}
                    companyGoals={companyGoals}
                    prevStep={prevStep}
                    saveForLater={saveForLater}
                    submitData={submitData}
                    company_id={companyId}
                />
            )}
        </div>
    );
}

export default AdminMaster;