import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import api from '../Util/apiService';
import { useAuth } from './authContext';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const { userId } = useAuth();
    
    // Memoize the fetch functions to prevent unnecessary re-renders
    const fetchNotifications = useCallback(async () => {
        // Only try to fetch if user is logged in
        if (!userId) {
            setNotifications([]);
            return;
        }
        
        try {
            const response = await api.get('/notifications', { withCredentials: true });
            
            // Add debug log to check response format
            console.log('Notifications API response:', response);
            
            // Check if the response looks like HTML (which indicates a routing issue)
            if (response && typeof response.data === 'string' && response.data.includes('<!doctype html>')) {
                console.error('Received HTML response instead of JSON. API endpoint is misconfigured or redirecting to frontend.');
                setNotifications([]);
                return;
            }
            
            if (response && response.data) {
                setNotifications(response.data || []);
            } else {
                console.error('Invalid response format from notifications API');
                setNotifications([]);
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
            setNotifications([]);
        }
    }, [userId]);
    
    const fetchUnreadCount = useCallback(async () => {
        // Only try to fetch if user is logged in
        if (!userId) {
            setUnreadCount(0);
            return;
        }
        
        try {
            const response = await api.get('/notifications/unread-count', { withCredentials: true });
            
            // Add debug log to see the actual response
            console.log('Unread count API response:', response);
            
            // Check if response has the expected JSON structure
            if (response && response.data) {
                // Check if the response looks like HTML (which indicates a routing issue)
                if (typeof response.data === 'string' && response.data.includes('<!doctype html>')) {
                    console.error('Received HTML response instead of JSON. API endpoint is misconfigured or redirecting to frontend.');
                    setUnreadCount(0);
                    return;
                }
                
                // More flexible parsing of count value
                if (response.data.count !== undefined) {
                    // Convert to number explicitly to handle string counts
                    const countValue = parseInt(response.data.count, 10);
                    if (!isNaN(countValue)) {
                        setUnreadCount(countValue);
                    } else {
                        console.log('Count property is not a valid number:', response.data.count);
                        setUnreadCount(0);
                    }
                } else {
                    console.log('Invalid response format from unread count API - missing count property', response.data);
                    setUnreadCount(0);
                }
            } else {
                console.log('Invalid response format from unread count API - empty response');
                setUnreadCount(0);
            }
        } catch (error) {
            console.error('Error fetching unread count:', error);
            setUnreadCount(0);
        }
    }, [userId]);
    
    // Fetch notifications when user is logged in
    useEffect(() => {
        if (userId) {
            fetchNotifications();
            fetchUnreadCount();
            
            // Set up polling for new notifications (every 15 seconds instead of 30)
            const intervalId = setInterval(() => {
                fetchUnreadCount();
                fetchNotifications(); // Also fetch full notifications data regularly
            }, 15000);
            
            return () => clearInterval(intervalId);
        } else {
            // Reset state when user logs out
            setNotifications([]);
            setUnreadCount(0);
        }
    }, [userId, fetchNotifications, fetchUnreadCount]);
    
    const markAsRead = async (notificationId) => {
        try {
            await api.patch(`/notifications/${notificationId}`, {}, { withCredentials: true });
            
            // Update local state
            setNotifications(prevNotifications => 
                prevNotifications.map(notification => 
                    notification.id === notificationId 
                        ? { ...notification, read: true } 
                        : notification
                )
            );
            
            // Update unread count
            setUnreadCount(prevCount => Math.max(0, prevCount - 1));
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };
    
    const markAllAsRead = async () => {
        try {
            const response = await api.patch('/notifications/mark-all-read', {}, { withCredentials: true });
            
            // Log the response for debugging
            console.log('Mark all as read response:', response);
            
            // Update local state
            setNotifications(prevNotifications => 
                prevNotifications.map(notification => ({ ...notification, read: true }))
            );
            
            // Reset unread count
            setUnreadCount(0);
            
            // Fetch updated notifications
            await fetchNotifications();
            
            return { success: true };
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
            
            // Log more detailed error information
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
            }
            
            return { success: false, error };
        }
    };
    
    return (
        <NotificationContext.Provider 
            value={{ 
                notifications, 
                unreadCount, 
                fetchNotifications, 
                fetchUnreadCount,
                markAsRead, 
                markAllAsRead 
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotifications = () => useContext(NotificationContext); 