import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { FaArrowLeft, FaFloppyDisk, FaPaperPlane, FaPenToSquare, FaCheck } from 'react-icons/fa6';
import { scrollToTop } from '../../../Util/scrollHelper';

function AdminStep3({ companyMission, companyGoals, prevStep, saveForLater, submitData }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isEditingGoals, setIsEditingGoals] = useState(false);
    const [editedGoals, setEditedGoals] = useState(companyGoals.map(goal => goal.text || ''));
    const location = useLocation();
    const navigate = useNavigate();

    // Check if the user came from AdminPortal
    const cameFromAdminPortal = location.search.includes('tab=');

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            // Start timing when submission begins
            const startTime = Date.now();
            
            // Minimum loading time in milliseconds (e.g., 2 seconds = 2000ms)
            const MIN_LOADING_TIME = 2000;

            // Submit the data
            await submitData();

            // Calculate how long the submission took
            const elapsedTime = Date.now() - startTime;
            
            // If submission was faster than MIN_LOADING_TIME, wait for the remaining time
            if (elapsedTime < MIN_LOADING_TIME) {
                await new Promise(resolve => setTimeout(resolve, MIN_LOADING_TIME - elapsedTime));
            }

            scrollToTop();
            navigate('/adminportal');
        } catch (error) {
            console.error('Error submitting data:', error);
            alert('There was an error submitting your goals. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const toggleEditGoals = () => {
        setIsEditingGoals(!isEditingGoals);
    };

    if (isSubmitting) {
        return (
            <div className='admin-goal-set-container'>
                <div className='loading-overlay'>
                    <div className='loading-content'>
                        <PulseLoader
                            color="#6908C9"
                            size={15}
                            margin={5}
                            speedMultiplier={0.8}
                        />
                        <h2 className='loading-text'>Submitting goals...</h2>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="page-container">
            <div className="page-content">
                <div className='admin-goal-set-container'>
                    <h2 className='admin-goal-set-title'>Review Your Information</h2>
                    <div className='admin-goal-set-review-container'>
                        <h3 className='admin-goal-set-title'>Company Mission</h3>
                        <div className='admin-goals-review-list'>
                            <div className='admin-goal-review-item'>
                                <div className='admin-goal-review-content'>
                                    {companyMission.text || "No company mission provided."}
                                </div>
                            </div>
                        </div>

                        <h3 className='admin-goal-set-title'>Company Goals</h3>
                        <div className='admin-goals-review-list'>
                            {companyGoals.length > 0 ? (
                                companyGoals.map((goal, index) => (
                                    <div key={index} className='admin-goal-review-item'>
                                        <div className='admin-goal-review-content'>
                                            {goal.text}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='admin-goal-review-item'>
                                    <p className='admin-goal-review-content'>
                                        No company goals provided.
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='admin-goal-set-button-container'>
                        <button onClick={prevStep} className='admin-goal-set-link'>
                            <FaArrowLeft /> Back
                        </button>
                        <button onClick={saveForLater} className='admin-goal-set-link'>
                            <FaFloppyDisk /> Save for Later
                        </button>
                        <button 
                            onClick={handleSubmit} 
                            className='admin-goal-set-submit-link'
                            disabled={!companyMission.text || companyGoals.length === 0}
                        >
                            <FaPaperPlane /> Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminStep3;