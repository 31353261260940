import React from 'react';
import './Styles/Careers.css';

const CareersPage = () => {
    const jobPostings = []; // Empty array since there are no openings

    return (
        <div className="careers-body">
            <h1 className="careers-title">Join Our Team</h1>
            
            <div className="careers-container">
                <div className="careers-section">
                    <h2 className="careers-subtitle">Current Opportunities</h2>
                    
                    <div className="no-openings-card">
                        <span className="no-openings-icon">ℹ️</span>
                        <p className="careers-text" style={{margin: 0}}>
                            We don't have any open positions at the moment, but we're always interested in connecting with talented individuals.
                        </p>
                    </div>
                    
                    <p className="careers-text">
                        At Astellyst, we're building the future of goal setting and performance management. Our team is passionate about creating intuitive tools that help organizations achieve their objectives while empowering employees.
                    </p>
                    
                    <h2 className="careers-subtitle">Our Values</h2>
                    <p className="careers-text">
                        • <strong>Innovation:</strong> We challenge the status quo of traditional performance management<br/>
                        • <strong>Simplicity:</strong> We believe powerful tools should be easy to use<br/>
                        • <strong>Growth:</strong> We're committed to continuous learning and improvement<br/>
                        • <strong>Collaboration:</strong> We work together to create exceptional experiences
                    </p>
                    
                    <h2 className="careers-subtitle">Get in Touch</h2>
                    <p className="careers-text">
                        Think you can help us transform how organizations set and achieve goals? We'd love to hear from you! Send your resume and a brief introduction to <a href="mailto:careers@astellyst.com" className="careers-link">careers@astellyst.com</a>.
                    </p>
                    
                    <a href="mailto:careers@astellyst.com" className="careers-cta">
                        Contact Our Team
                    </a>
                </div>
            </div>
        </div>
    );
};

export default CareersPage;