import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../Styles/EmployeeProfile.css';
import BackButton from '../../../Components/BackButton/BackButton';
import { FaPenToSquare, FaUsers, FaBullseye, FaChartLine } from 'react-icons/fa6';
import fetchWithAuth from '../../../Util/fetchWithAuth';

const EmployeeProfile = () => {
    const { userId } = useParams();
    const [employee, setEmployee] = useState(null);
    const navigate = useNavigate();
    const [goalTextVisible, setGoalTextVisible] = useState([]);

    const handleBackLinkClick = () => {
        navigate('/EmployeeDirectory');
    };

    const handleEditClick = () => {
        navigate(`/EditEmployee/${userId}`);
    };

    useEffect(() => {
        const fetchEmployeeDetails = async () => {
            try {
                const response = await fetchWithAuth(`/api/users/get-employee-details/${userId}`);
                const data = await response.json();
                setEmployee(data);
            } catch (error) {
                console.error('Error fetching employee details:', error);
            }
        };

        fetchEmployeeDetails();
    }, [userId]);

    const handleGoalTextToggle = (index) => {
        setGoalTextVisible((prev) => {
            const newVisibility = [...prev];
            newVisibility[index] = !newVisibility[index];
            return newVisibility;
        });
    };

    if (!employee) return <div>Loading...</div>;

    return (
        <div className="page-container">
            <div className="back-button-container">
                <BackButton onClick={handleBackLinkClick} />
            </div>
            <div className="page-content">
                <div className="employee-profile-header">
                    <div className="employee-profile-main-info">
                        <h1 className="page-header">{employee.user.first_name} {employee.user.last_name}</h1>
                        <p className="employee-profile-job-title">{employee.user.job_title}</p>
                        <p className="employee-profile-email">{employee.user.email}</p>
                    </div>
                    <button className="employee-profile-edit-button" onClick={handleEditClick}>
                        <FaPenToSquare /> Edit Profile
                    </button>
                </div>

                <div className="employee-profile-sections">
                    <div className="employee-profile-section">
                        <div className="employee-profile-section-header">
                            <FaUsers className="employee-profile-section-icon" />
                            <h2>Role Information</h2>
                        </div>
                        <div className="employee-profile-section-content">
                            <p className="employee-profile-job-description">{employee.user.job_description}</p>
                        </div>
                    </div>

                    <div className="employee-profile-section">
                        <div className="employee-profile-section-header">
                            <FaBullseye className="employee-profile-section-icon" />
                            <h2>Direct Reports</h2>
                        </div>
                        <div className="employee-profile-section-content employee-profile-reports-grid">
                            {employee.directReports.length > 0 ? (
                                employee.directReports.map(report => (
                                    <div
                                        key={report.id}
                                        className="employee-profile-report-card"
                                        onClick={() => {
                                            navigate(`/EmployeeProfile/${report.id}`);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <h3>{report.first_name} {report.last_name}</h3>
                                        <p>{report.job_title}</p>
                                    </div>
                                ))
                            ) : (
                                <p>No direct reports found.</p>
                            )}
                        </div>
                    </div>

                    <div className="employee-profile-section">
                        <div className="employee-profile-section-header">
                            <FaChartLine className="employee-profile-section-icon" />
                            <h2>Goals & Progress</h2>
                        </div>
                        <div className="employee-profile-section-content employee-profile-goals-grid">
                            {employee.goals.length > 0 ? (
                                employee.goals.map((goal, index) => (
                                    <div key={goal.id} className="employee-profile-goal-card">
                                        <h3 className="employee-profile-goal-title">
                                            {goal.summary?.length > 75 ? goal.summary.substring(0, 75) + '...' : goal.summary}
                                        </h3>
                                        <div className="employee-profile-progress-container">
                                            <div className="employee-profile-progress-indicator">
                                                <div 
                                                    className="employee-profile-progress-bar" 
                                                    style={{width: `${goal.progress}%`}}
                                                />
                                            </div>
                                            <span className="employee-profile-progress-label">{goal.progress}%</span>
                                        </div>
                                        
                                        {goal.goal_text && goal.goal_text.trim() !== '' && (
                                            <>
                                                <div className={`employee-profile-goal-details ${goalTextVisible[index] ? 'expanded' : ''}`}>
                                                    {goalTextVisible[index] && (
                                                        <p className="employee-profile-goal-text">{goal.goal_text}</p>
                                                    )}
                                                </div>
                                                
                                                <button 
                                                    className="employee-profile-toggle-button"
                                                    onClick={() => handleGoalTextToggle(index)}
                                                >
                                                    {goalTextVisible[index] ? "Show Less" : "Show More"}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p className="employee-profile-no-goals">No goals have been set yet.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeProfile;
