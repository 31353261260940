import React, { useState, useEffect, useRef } from 'react';
import '../Styles/ManagerPortal.css'; // Updated CSS import
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../Contexts/authContext';
import { useTab } from '../../../Contexts/tabContext';
import fetchWithAuth from '../../../Util/fetchWithAuth';
import { FaCalendarCheck, FaChartLine, FaUsers, FaBullseye, FaCheckCircle, FaTasks, FaChevronRight, FaArchive } from 'react-icons/fa';

const ManagerPortal = () => {
    const { activeTab, setActiveTab } = useTab('your-dashboard');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const sidebarRef = useRef(null);
    const { userId } = useAuth();
    const [hasGoals, setHasGoals] = useState(false);
    const [teamStats, setTeamStats] = useState({
        teamSize: 0,
        teamGoals: 0,
        teamCompletedGoals: 0
    });
    const [personalGoals, setPersonalGoals] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [upcomingCheckIns, setUpcomingCheckIns] = useState([]);
    const [teamGoals, setTeamGoals] = useState([]);
    const [employeeProgress, setEmployeeProgress] = useState([]);
    const [loading, setLoading] = useState({
        teamStats: true,
        personalGoals: true,
        checkIns: true,
        teamGoals: true,
        employeeProgress: true
    });
    const [isArchivedGoalsLoading, setIsArchivedGoalsLoading] = useState(false);
    const [archivedPersonalGoals, setArchivedPersonalGoals] = useState([]);
    const [archivedTeamGoals, setArchivedTeamGoals] = useState([]);
    const [hasArchivedGoals, setHasArchivedGoals] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const tab = query.get('tab');
        if (tab) {
            setActiveTab(tab);
        } else {
            navigate('?tab=your-dashboard');
        }
    }, [location, setActiveTab, navigate]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsSidebarOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Fetch personal goals
    useEffect(() => {
        if (userId && activeTab === 'your-dashboard') {
            setLoading(prev => ({ ...prev, personalGoals: true }));
            
            fetchWithAuth(`/api/goals/get-goals/${userId}`)
                .then(response => response.json())
                .then(data => {
                    setPersonalGoals(data);
                    setHasGoals(data && data.length > 0);
                    setLoading(prev => ({ ...prev, personalGoals: false }));
                })
                .catch(error => {
                    console.error('Error retrieving goals:', error);
                    setLoading(prev => ({ ...prev, personalGoals: false }));
                });
        }
    }, [userId, activeTab]);

    // Fetch team members and their stats
    useEffect(() => {
        if (userId && activeTab === 'your-dashboard') {
            setLoading(prev => ({ ...prev, teamStats: true }));
            
            // Fetch team members
            fetchWithAuth(`/api/users/get-team-members/${userId}`)
                .then(response => response.json())
                .then(async (members) => {
                    setTeamMembers(members);
                    
                    // Initialize counters
                    let totalGoals = 0;
                    let completedGoals = 0;
                    
                    // Get goals for each team member
                    const memberGoalsPromises = members.map(member => 
                        fetchWithAuth(`/api/goals/get-goals/${member.id}`)
                            .then(response => response.json())
                            .catch(error => {
                                console.error(`Error fetching goals for team member ${member.id}:`, error);
                                return [];
                            })
                    );
                    
                    const allMemberGoals = await Promise.all(memberGoalsPromises);
                    
                    // Process all goals
                    allMemberGoals.forEach((memberGoals, index) => {
                        // Add team member name to each goal for display
                        const goalsWithNames = memberGoals.map(goal => ({
                            ...goal,
                            employeeName: `${members[index].first_name} ${members[index].last_name}`
                        }));
                        
                        // Add to employee progress for highlighting
                        setEmployeeProgress(prev => [
                            ...prev,
                            ...goalsWithNames.filter(goal => goal.progress > 0)
                        ]);
                        
                        // Count goals for stats
                        totalGoals += memberGoals.length;
                        completedGoals += memberGoals.filter(goal => goal.progress === 100).length;
                    });
                    
                    // Update team stats
                    setTeamStats({
                        teamSize: members.length,
                        teamGoals: totalGoals,
                        teamCompletedGoals: completedGoals
                    });
                    
                    setLoading(prev => ({ ...prev, teamStats: false, employeeProgress: false }));
                })
                .catch(error => {
                    console.error('Error fetching team members:', error);
                    setLoading(prev => ({ ...prev, teamStats: false, employeeProgress: false }));
                });
        }
    }, [userId, activeTab]);

    // Fetch team goals
    useEffect(() => {
        if (userId && activeTab === 'your-dashboard') {
            setLoading(prev => ({ ...prev, teamGoals: true }));
            
            fetchWithAuth(`/api/team-goals/get-goals/${userId}`)
                .then(response => response.json())
                .then(data => {
                    // Add progress property if it doesn't exist
                    const goalsWithProgress = data.map(goal => ({
                        ...goal,
                        progress: goal.progress || 0
                    }));
                    
                    setTeamGoals(goalsWithProgress);
                    setLoading(prev => ({ ...prev, teamGoals: false }));
                })
                .catch(error => {
                    console.error('Error fetching team goals:', error);
                    setLoading(prev => ({ ...prev, teamGoals: false }));
                });
        }
    }, [userId, activeTab]);

    // Fetch upcoming check-ins
    useEffect(() => {
        if (userId && activeTab === 'your-dashboard') {
            setLoading(prev => ({ ...prev, checkIns: true }));
            
            fetchWithAuth(`/api/check-ins/manager/${userId}`)
                .then(response => response.json())
                .then(data => {
                    // Filter for upcoming check-ins
                    const now = new Date();
                    const upcoming = data
                        .filter(checkIn => 
                            new Date(checkIn.scheduled_date) > now && 
                            checkIn.status === 'scheduled'
                        )
                        .sort((a, b) => new Date(a.scheduled_date) - new Date(b.scheduled_date))
                        .slice(0, 5); // Limit to 5 upcoming check-ins
                    
                    setUpcomingCheckIns(upcoming);
                    setLoading(prev => ({ ...prev, checkIns: false }));
                })
                .catch(error => {
                    console.error('Error fetching check-ins:', error);
                    setLoading(prev => ({ ...prev, checkIns: false }));
                });
        }
    }, [userId, activeTab]);

    // Fetch archived goals when viewing the old goals tab
    useEffect(() => {
        if (userId && activeTab === 'review-old-goals') {
            setIsArchivedGoalsLoading(true);
            
            const fetchArchivedGoals = async () => {
                try {
                    // Fetch personal archived goals
                    const personalResponse = await fetchWithAuth(`/api/goals/get-goals/${userId}?archived=true`);
                    const personalData = await personalResponse.json();
                    setArchivedPersonalGoals(personalData);
                    
                    // Fetch team archived goals
                    const teamResponse = await fetchWithAuth(`/api/team-goals/get-goals/${userId}?archived=true`);
                    const teamData = await teamResponse.json();
                    setArchivedTeamGoals(teamData);
                } catch (error) {
                    console.error('Error fetching archived goals:', error);
                } finally {
                    setIsArchivedGoalsLoading(false);
                }
            };
            
            fetchArchivedGoals();
        }
    }, [userId, activeTab]);

    // Check for archived goals on initial load
    useEffect(() => {
        if (userId) {
            const checkForArchivedGoals = async () => {
                try {
                    // Check for personal archived goals
                    const personalResponse = await fetchWithAuth(`/api/goals/get-goals/${userId}?archived=true&limit=1`);
                    const personalData = await personalResponse.json();
                    
                    // Check for team archived goals
                    const teamResponse = await fetchWithAuth(`/api/team-goals/get-goals/${userId}?archived=true&limit=1`);
                    const teamData = await teamResponse.json();
                    
                    // Set flag if there are any archived goals
                    setHasArchivedGoals(
                        (Array.isArray(personalData) && personalData.length > 0) || 
                        (Array.isArray(teamData) && teamData.length > 0)
                    );
                } catch (error) {
                    console.error('Error checking for archived goals:', error);
                    setHasArchivedGoals(false);
                }
            };
            
            checkForArchivedGoals();
        }
    }, [userId]);

    // Calculate overall progress for personal goals
    const calculateOverallProgress = (goals) => {
        if (!goals || goals.length === 0) return 0;
        
        const totalProgress = goals.reduce((sum, goal) => sum + Number(goal.progress || 0), 0);
        return Math.round(totalProgress / goals.length);
    };

    // Count completed personal goals
    const countCompletedGoals = (goals) => {
        if (!goals || goals.length === 0) return 0;
        return goals.filter(goal => Number(goal.progress) === 100).length;
    };

    // Handle unarchiving a personal goal
    const handleUnarchivePersonalGoal = async (goalId) => {
        try {
            const response = await fetchWithAuth(`/api/goals/toggle-archive/${goalId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    archive: false
                })
            });
            
            if (!response.ok) {
                throw new Error('Failed to unarchive goal');
            }
            
            // Remove the goal from the archived list
            setArchivedPersonalGoals(prev => prev.filter(goal => goal.id !== goalId));
            
            alert('Personal goal successfully restored. It can now be found in your active goals.');
            
            // Optionally refresh active goals if on the dashboard tab
            if (activeTab === 'your-dashboard') {
                fetchWithAuth(`/api/goals/get-goals/${userId}`)
                    .then(response => response.json())
                    .then(data => {
                        setPersonalGoals(data);
                    })
                    .catch(error => {
                        console.error('Error retrieving goals:', error);
                    });
            }
        } catch (error) {
            console.error('Error unarchiving goal:', error);
            alert('Failed to restore goal. Please try again.');
        }
    };

    // Handle unarchiving a team goal
    const handleUnarchiveTeamGoal = async (goalId) => {
        try {
            const response = await fetchWithAuth(`/api/team-goals/toggle-archive/${goalId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    archive: false
                })
            });
            
            if (!response.ok) {
                throw new Error('Failed to unarchive team goal');
            }
            
            // Remove the goal from the archived list
            setArchivedTeamGoals(prev => prev.filter(goal => goal.id !== goalId));
            
            alert('Team goal successfully restored. It can now be found in your active team goals.');
            
            // Optionally refresh active team goals if on the dashboard tab
            if (activeTab === 'your-dashboard') {
                fetchWithAuth(`/api/team-goals/get-goals/${userId}`)
                    .then(response => response.json())
                    .then(data => {
                        setTeamGoals(data);
                    })
                    .catch(error => {
                        console.error('Error fetching team goals:', error);
                    });
            }
        } catch (error) {
            console.error('Error unarchiving team goal:', error);
            alert('Failed to restore team goal. Please try again.');
        }
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setIsSidebarOpen(false);
        navigate(`?tab=${tab}`);
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'your-dashboard':
                return (
                    <div className="portal-section">
                        <h2>Your Dashboard</h2>
                        <p>View your personal progress and team statistics.</p>
                        
                        <div className="mp-dashboard-grid">
                            {/* Team Overview Section */}
                            <div className="mp-dashboard-card team-overview">
                                <div className="mp-dashboard-card-header">
                                    <h3><FaUsers className="card-icon" /> Team Overview</h3>
                                </div>
                                {loading.teamStats ? (
                                    <div className="mp-dashboard-loading">Loading team statistics...</div>
                                ) : (
                                    <>
                                        <div className="mp-stats-grid">
                                            <div className="mp-stat-card">
                                                <span className="mp-stat-value">{teamStats.teamSize}</span>
                                                <span className="mp-stat-label">Team Members</span>
                                            </div>
                                            <div className="mp-stat-card">
                                                <span className="mp-stat-value">{teamStats.teamGoals}</span>
                                                <span className="mp-stat-label">Total Goals</span>
                                            </div>
                                            <div className="mp-stat-card">
                                                <span className="mp-stat-value">{teamStats.teamCompletedGoals}</span>
                                                <span className="mp-stat-label">Completed Goals</span>
                                            </div>
                                        </div>
                                        <div className="mp-dashboard-buttons">
                                            <Link to="/TeamMembers">
                                                <button className="mp-dashboard-button">
                                                    View Team <FaChevronRight size={12} />
                                                </button>
                                            </Link>
                                        </div>
                                    </>
                                )}
                            </div>
                            
                            {/* Upcoming Check-ins Section */}
                            <div className="mp-dashboard-card check-ins">
                                <div className="mp-dashboard-card-header">
                                    <h3><FaCalendarCheck className="card-icon" /> Upcoming Check-ins</h3>
                                </div>
                                {loading.checkIns ? (
                                    <div className="mp-dashboard-loading">Loading check-ins...</div>
                                ) : upcomingCheckIns.length > 0 ? (
                                    <div className="mp-checkins-list">
                                        {upcomingCheckIns.map(checkIn => (
                                            <div key={checkIn.id} className="mp-checkin-item">
                                                <div className="mp-checkin-date">
                                                    {new Date(checkIn.scheduled_date).toLocaleDateString()} at {new Date(checkIn.scheduled_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                                                </div>
                                                <div className="mp-checkin-employee">
                                                    with <strong>{checkIn.first_name} {checkIn.last_name}</strong>
                                                </div>
                                                {checkIn.manager_notes && (
                                                    <div className="mp-checkin-notes">
                                                        {checkIn.manager_notes}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="mp-no-data-message">No upcoming check-ins scheduled.</div>
                                )}
                                <div className="mp-dashboard-buttons">
                                    <Link to="/TeamMembers">
                                        <button className="mp-dashboard-button">
                                            Schedule Check-in <FaChevronRight size={12} />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            
                            {/* Personal Goals Section */}
                            <div className="mp-dashboard-card personal-goals">
                                <div className="mp-dashboard-card-header">
                                    <h3><FaBullseye className="card-icon" /> Your Goals</h3>
                                </div>
                                {loading.personalGoals ? (
                                    <div className="mp-dashboard-loading">Loading goals...</div>
                                ) : personalGoals.length > 0 ? (
                                    <div className="mp-goals-summary">
                                        <div className="mp-goals-stats">
                                            <div className="mp-stat-card">
                                                <span className="mp-stat-value">{personalGoals.length}</span>
                                                <span className="mp-stat-label">Total Goals</span>
                                            </div>
                                            <div className="mp-stat-card">
                                                <span className="mp-stat-value">{calculateOverallProgress(personalGoals)}%</span>
                                                <span className="mp-stat-label">Overall Progress</span>
                                            </div>
                                            <div className="mp-stat-card">
                                                <span className="mp-stat-value">{countCompletedGoals(personalGoals)}</span>
                                                <span className="mp-stat-label">Completed</span>
                                            </div>
                                        </div>
                                        <div className="mp-goals-list">
                                            {personalGoals.slice(0, 3).map(goal => (
                                                <div key={goal.id} className="mp-goal-item">
                                                    <div className="mp-goal-summary">{goal.summary}</div>
                                                    <div className="mp-goal-progress-container">
                                                        <div className="mp-goal-progress-bar">
                                                            <div 
                                                                className="mp-goal-progress-fill" 
                                                                style={{ width: `${goal.progress}%` }}
                                                            ></div>
                                                        </div>
                                                        <span className="mp-goal-progress-text">{goal.progress}%</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="mp-no-data-message">No personal goals set yet.</div>
                                )}
                                <div className="mp-dashboard-buttons">
                                    <Link to="/ProgressPage">
                                        <button className="mp-dashboard-button">
                                            View Progress <FaChevronRight size={12} />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            
                            {/* Team Goals Section */}
                            <div className="mp-dashboard-card team-goals">
                                <div className="mp-dashboard-card-header">
                                    <h3><FaTasks className="card-icon" /> Team Goals</h3>
                                </div>
                                {loading.teamGoals ? (
                                    <div className="mp-dashboard-loading">Loading team goals...</div>
                                ) : teamGoals.length > 0 ? (
                                    <div className="mp-goals-list">
                                        {teamGoals.slice(0, 5).map(goal => (
                                            <div key={goal.id} className="mp-goal-item">
                                                <div className="mp-goal-summary">{goal.summary}</div>
                                                <div className="mp-goal-progress-container">
                                                    <div className="mp-goal-progress-bar">
                                                        <div 
                                                            className="mp-goal-progress-fill" 
                                                            style={{ width: `${goal.progress}%` }}
                                                        ></div>
                                                    </div>
                                                    <span className="mp-goal-progress-text">{goal.progress}%</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="mp-no-data-message">No team goals set yet.</div>
                                )}
                                <div className="mp-dashboard-buttons">
                                    <Link to="/ManagerMaster?step=3">
                                        <button className="mp-dashboard-button">
                                            Manage Team Goals <FaChevronRight size={12} />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            
                            {/* Employee Progress Highlights */}
                            <div className="mp-dashboard-card employee-progress">
                                <div className="mp-dashboard-card-header">
                                    <h3><FaChartLine className="card-icon" /> Employee Highlights</h3>
                                </div>
                                {loading.employeeProgress ? (
                                    <div className="mp-dashboard-loading">Loading employee progress...</div>
                                ) : employeeProgress.length > 0 ? (
                                    <div className="mp-progress-list">
                                        {employeeProgress
                                            .sort((a, b) => b.progress - a.progress)
                                            .slice(0, 5)
                                            .map(goal => (
                                                <div key={goal.id} className="mp-progress-item">
                                                    <div className="mp-progress-header">
                                                        <span className="mp-progress-employee">{goal.employeeName}</span>
                                                        <span className="mp-progress-percentage">{goal.progress}%</span>
                                                    </div>
                                                    <div className="mp-progress-summary">{goal.summary}</div>
                                                    <div className="mp-goal-progress-container">
                                                        <div className="mp-goal-progress-bar">
                                                            <div 
                                                                className="mp-goal-progress-fill" 
                                                                style={{ width: `${goal.progress}%` }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ) : (
                                    <div className="mp-no-data-message">No employee progress to display.</div>
                                )}
                                <div className="mp-dashboard-buttons">
                                    <Link to="/TeamMembers">
                                        <button className="mp-dashboard-button">
                                            View All Progress <FaChevronRight size={12} />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'set-goals':
                return (
                    <div className="portal-section">
                        <h2>Set Team Goals</h2>
                        <p>Define goals for your team to achieve.</p>
                        <div className='mp-goal-setting-button-container'>
                            <Link to={`/ManagerMaster?step=0&tab=${activeTab}`}>
                                <button className='mp-goal-setting-button'>Set Team Goals</button>
                            </Link>
                            <Link to={`/ManagerMaster?step=3&tab=${activeTab}`}>
                                <button className='mp-goal-setting-button'>Review Goals</button>
                            </Link>
                        </div>
                    </div>
                );
            case 'your-team':
                return (
                    <div className="portal-section">
                        <h2>Your Team</h2>
                        <p>View and manage your team members.</p>
                        <Link to={`/TeamMembers?tab=${activeTab}`}>
                            <button className='mp-goal-setting-button'>View Team</button>
                        </Link>
                    </div>
                );
            case 'set-your-goals':
                return (
                    <div className="portal-section">
                        <h2>{hasGoals ? 'Manage Your Goals' : 'Set Your Goals'}</h2>
                        <p>{hasGoals ? 'Review or set new personal goals.' : 'Establish your personal goals.'}</p>
                        <div className='mp-goal-setting-button-container'>
                            <Link to={`/EmployeeMaster?step=0&tab=${activeTab}`}>
                                <button className='mp-goal-setting-button'>Set New Goals</button>
                            </Link>
                            {hasGoals && (
                                <Link to={`/EmployeeMaster?step=2&tab=${activeTab}`}>
                                    <button className='mp-goal-setting-button'>Review Goals</button>
                                </Link>
                            )}
                        </div>
                    </div>
                );
            case 'your-progress':
                return (
                    <div className="portal-section">
                        <h2>Your Progress</h2>
                        <p>View your progress.</p>
                        <Link to={`/ProgressPage?tab=${activeTab}`}>
                            <button className='mp-goal-setting-button'>View Progress</button>
                        </Link>
                    </div>
                );
            case 'company-store':
                return (
                    <div className="portal-section">
                        <h2>Company Reward Store</h2>
                        <p>Redeem your earned points for exciting rewards and incentives.</p>
                        <Link to="/CompanyStorePage">
                            <button className='mp-goal-setting-button'>View Store</button>
                        </Link>
                    </div>
                );
            case 'review-old-goals':
                return (
                    <div className="portal-section">
                        <h2>Review Old Goals</h2>
                        <p>View goals that have been archived from previous goal setting periods.</p>
                        
                        {isArchivedGoalsLoading ? (
                            <div className="mp-loading">Loading archived goals...</div>
                        ) : (
                            <div className="mp-archived-goals-container">
                                {/* Team Goals Section */}
                                <div className="mp-archived-section">
                                    <h3>Team Goals</h3>
                                    {archivedTeamGoals.length > 0 ? (
                                        <div className="mp-archived-goals-list">
                                            {archivedTeamGoals.map((goal) => (
                                                <div key={goal.id} className="mp-archived-goal-card">
                                                    <div className="mp-archived-goal-content">
                                                        <h4>{goal.summary || goal.goal_text}</h4>
                                                        <div className="mp-archived-goal-progress">
                                                            <div className="mp-archived-progress-bar">
                                                                <div 
                                                                    className="mp-archived-progress-fill" 
                                                                    style={{ width: `${goal.progress || 0}%` }}
                                                                ></div>
                                                            </div>
                                                            <span className="mp-archived-progress-text">{goal.progress || 0}%</span>
                                                        </div>
                                                        <p className="mp-archived-date">
                                                            Archived on: {new Date(goal.archived_at).toLocaleDateString()}
                                                        </p>
                                                        <div className="mp-archived-actions">
                                                            <button
                                                                className="mp-unarchive-button"
                                                                onClick={() => handleUnarchiveTeamGoal(goal.id)}
                                                            >
                                                                Restore Team Goal
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="mp-no-archived">No archived team goals found.</p>
                                    )}
                                </div>
                                
                                {/* Personal Goals Section */}
                                <div className="mp-archived-section">
                                    <h3>Personal Goals</h3>
                                    {archivedPersonalGoals.length > 0 ? (
                                        <div className="mp-archived-goals-list">
                                            {archivedPersonalGoals.map((goal) => (
                                                <div key={goal.id} className="mp-archived-goal-card">
                                                    <div className="mp-archived-goal-content">
                                                        <h4>{goal.summary || goal.goal_text}</h4>
                                                        <div className="mp-archived-goal-progress">
                                                            <div className="mp-archived-progress-bar">
                                                                <div 
                                                                    className="mp-archived-progress-fill" 
                                                                    style={{ width: `${goal.progress || 0}%` }}
                                                                ></div>
                                                            </div>
                                                            <span className="mp-archived-progress-text">{goal.progress || 0}%</span>
                                                        </div>
                                                        <p className="mp-archived-date">
                                                            Archived on: {new Date(goal.archived_at).toLocaleDateString()}
                                                        </p>
                                                        <div className="mp-archived-actions">
                                                            <button
                                                                className="mp-unarchive-button"
                                                                onClick={() => handleUnarchivePersonalGoal(goal.id)}
                                                            >
                                                                Restore Personal Goal
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="mp-no-archived">No archived personal goals found.</p>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                );
            default:
                return <div className="portal-section">Please select a tab.</div>;
        }
    };

    return (
        <div className="manager-portal">
            <button 
                className="mp-portal-menu-button" 
                onClick={toggleSidebar}
            >
                Menu
            </button>
            <div className="mp-portal-sidebar">
                <button 
                    className={`mp-portal-sidebar-button ${activeTab === 'your-dashboard' ? 'active' : ''}`} 
                    onClick={() => handleTabClick('your-dashboard')}
                >
                    Your Dashboard
                </button>
                <button 
                    className={`mp-portal-sidebar-button ${activeTab === 'set-goals' ? 'active' : ''}`} 
                    onClick={() => handleTabClick('set-goals')}
                >
                    Set Team Goals
                </button>
                <button 
                    className={`mp-portal-sidebar-button ${activeTab === 'your-team' ? 'active' : ''}`} 
                    onClick={() => handleTabClick('your-team')}
                >
                    Your Team
                </button>
                <button 
                    className={`mp-portal-sidebar-button ${activeTab === 'set-your-goals' ? 'active' : ''}`} 
                    onClick={() => handleTabClick('set-your-goals')}
                >
                    Set Your Goals
                </button>
                <button 
                    className={`mp-portal-sidebar-button ${activeTab === 'your-progress' ? 'active' : ''}`} 
                    onClick={() => handleTabClick('your-progress')}
                >
                    Your Progress
                </button>
                <button 
                    className={`mp-portal-sidebar-button ${activeTab === 'company-store' ? 'active' : ''}`} 
                    onClick={() => handleTabClick('company-store')}
                >
                    Company Store
                </button>
                <button 
                    className={`mp-portal-sidebar-button ${activeTab === 'review-old-goals' ? 'active' : ''} ${!hasArchivedGoals ? 'disabled' : ''}`} 
                    onClick={() => hasArchivedGoals && handleTabClick('review-old-goals')}
                    title={!hasArchivedGoals ? "No archived goals to display" : "Review your archived goals"}
                >
                    Review Old Goals
                </button>
            </div>
            <div ref={sidebarRef} className={`mp-portal-sidebar mobile ${isSidebarOpen ? 'open' : ''}`}>
                <button 
                    className={`mp-portal-sidebar-button ${activeTab === 'your-dashboard' ? 'active' : ''}`} 
                    onClick={() => handleTabClick('your-dashboard')}
                >
                    Your Dashboard
                </button>
                <button 
                    className={`mp-portal-sidebar-button ${activeTab === 'set-goals' ? 'active' : ''}`} 
                    onClick={() => handleTabClick('set-goals')}
                >
                    Set Team Goals
                </button>
                <button 
                    className={`mp-portal-sidebar-button ${activeTab === 'your-team' ? 'active' : ''}`} 
                    onClick={() => handleTabClick('your-team')}
                >
                    Your Team
                </button>
                <button 
                    className={`mp-portal-sidebar-button ${activeTab === 'set-your-goals' ? 'active' : ''}`} 
                    onClick={() => handleTabClick('set-your-goals')}
                >
                    Set Your Goals
                </button>
                <button 
                    className={`mp-portal-sidebar-button ${activeTab === 'your-progress' ? 'active' : ''}`} 
                    onClick={() => handleTabClick('your-progress')}
                >
                    Your Progress
                </button>
                <button 
                    className={`mp-portal-sidebar-button ${activeTab === 'company-store' ? 'active' : ''}`} 
                    onClick={() => handleTabClick('company-store')}
                >
                    Company Store
                </button>
                <button 
                    className={`mp-portal-sidebar-button ${activeTab === 'review-old-goals' ? 'active' : ''} ${!hasArchivedGoals ? 'disabled' : ''}`} 
                    onClick={() => hasArchivedGoals && handleTabClick('review-old-goals')}
                    title={!hasArchivedGoals ? "No archived goals to display" : "Review your archived goals"}
                >
                    Review Old Goals
                </button>
            </div>
            <div className="mp-portal-content">
                {renderContent()}
            </div>
        </div>
    );
};

export default ManagerPortal;