import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../Styles/EditEmployee.css';
import BackButton from '../../../Components/BackButton/BackButton';
import fetchWithAuth from '../../../Util/fetchWithAuth';

const EditEmployee = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        job_title: '',
        job_description: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const fetchEmployeeDetails = async () => {
            try {
                const response = await fetchWithAuth(`/api/users/get-employee-details/${userId}`);
                const data = await response.json();
                setFormData(data.user);
            } catch (error) {
                console.error('Error fetching employee details:', error);
            }
        };

        fetchEmployeeDetails();
    }, [userId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        
        try {
            const response = await fetchWithAuth(`/api/users/update-employee/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                navigate(`/EmployeeProfile/${userId}`);
            } else {
                console.error('Failed to update employee details');
                alert('Failed to update employee details. Please try again.');
            }
        } catch (error) {
            console.error('Error updating employee details:', error);
            alert('An error occurred while updating employee details.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleArchive = async () => {
        const confirmArchive = window.confirm("Are you sure you want to archive this employee?");
        if (!confirmArchive) {
            return;
        }

        setIsSubmitting(true);
        try {
            const response = await fetchWithAuth(`/api/users/archive-employee/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ is_archived: true })
            });
            if (response.ok) {
                navigate(`/EmployeeDirectory`);
            } else {
                console.error('Failed to archive employee');
                alert('Failed to archive employee. Please try again.');
            }
        } catch (error) {
            console.error('Error archiving employee:', error);
            alert('An error occurred while archiving employee.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleBackClick = () => {
        navigate(`/EmployeeProfile/${userId}`);
    };

    return (
        <div className="page-container">
            <div className="back-button-container">
                <BackButton onClick={handleBackClick} />
            </div>
            <div className="page-content">
                <h1 className="page-header edit-employee-header">Edit Employee</h1>
                <p className="edit-employee-description">
                    Update employee information using the form below.
                </p>

                <form className="form-container edit-employee-form" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="first_name">First Name</label>
                        <input 
                            type="text" 
                            id="first_name" 
                            name="first_name" 
                            value={formData.first_name} 
                            onChange={handleChange} 
                            required 
                            autoComplete="given-name"
                        />
                    </div>
                    <div>
                        <label htmlFor="last_name">Last Name</label>
                        <input 
                            type="text" 
                            id="last_name" 
                            name="last_name" 
                            value={formData.last_name} 
                            onChange={handleChange} 
                            required 
                            autoComplete="family-name"
                        />
                    </div>
                    <div>
                        <label htmlFor="email">Email Address</label>
                        <input 
                            type="email" 
                            id="email" 
                            name="email" 
                            value={formData.email} 
                            onChange={handleChange} 
                            required 
                            autoComplete="email"
                        />
                    </div>
                    <div>
                        <label htmlFor="job_title">Job Title</label>
                        <input 
                            type="text" 
                            id="job_title" 
                            name="job_title" 
                            value={formData.job_title} 
                            onChange={handleChange} 
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="job_description">Job Description</label>
                        <textarea 
                            id="job_description" 
                            name="job_description" 
                            value={formData.job_description} 
                            onChange={handleChange} 
                            rows="4"
                        />
                    </div>
                    <button type="submit" disabled={isSubmitting} className="edit-employee-save-button">
                        {isSubmitting ? 'Saving...' : 'Save Changes'}
                    </button>
                </form>
                
                <button 
                    className="edit-employee-archive-button" 
                    onClick={handleArchive} 
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Processing...' : 'Archive Employee'}
                </button>
            </div>
        </div>
    );
};

export default EditEmployee;
