import React, { useState, useEffect, useRef } from 'react';
import '../Styles/AdminPortal.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../Contexts/authContext';
import { TabProvider, useTab } from '../../../Contexts/tabContext';
import { useGoalSetting } from '../../../Contexts/goalSettingContext';
import TeamDashboard from '../../../Components/TeamDashboard/TeamDashboard';
import StoreManager from '../../../Components/Store/StoreManager';
import fetchWithAuth from '../../../Util/fetchWithAuth';
import { FaCalendarCheck, FaChartLine, FaUsers, FaBullseye, FaCheckCircle, FaTasks, FaChevronRight, FaArchive, FaStore } from 'react-icons/fa';
import { useNotifications } from '../../../Contexts/notificationContext';

const AdminPortal = () => {
    const { activeTab, setActiveTab } = useTab('your-dashboard');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const sidebarRef = useRef(null);
    const { userId, companyId } = useAuth();
    const [hasGoals, setHasGoals] = useState(false);
    const { isGoalSettingActive, updateGoalSettingStatus } = useGoalSetting();
    const { fetchNotifications, fetchUnreadCount } = useNotifications();
    const [companyStats, setCompanyStats] = useState({
        totalEmployees: 0,
        totalGoals: 0,
        completedGoals: 0
    });
    const [personalGoals, setPersonalGoals] = useState([]);
    const [upcomingCheckIns, setUpcomingCheckIns] = useState([]);
    const [teamGoals, setTeamGoals] = useState([]);
    const [loading, setLoading] = useState({
        goals: true,
        checkIns: true,
        teamGoals: true,
        companyStats: true
    });
    const [isArchivedGoalsLoading, setIsArchivedGoalsLoading] = useState(false);
    const [archivedPersonalGoals, setArchivedPersonalGoals] = useState([]);
    const [archivedTeamGoals, setArchivedTeamGoals] = useState([]);
    const [archivedCompanyGoals, setArchivedCompanyGoals] = useState([]);
    const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);
    const [hasArchivedGoals, setHasArchivedGoals] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const tab = query.get('tab');
        if (tab) {
            setActiveTab(tab);
        } else {
            navigate('?tab=your-dashboard');
        }
    }, [location, setActiveTab, navigate]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsSidebarOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Fetch personal goals
    useEffect(() => {
        if (userId && activeTab === 'your-dashboard') {
            setLoading(prev => ({ ...prev, goals: true }));
            
            fetchWithAuth(`/api/goals/get-goals/${userId}`)
                .then(response => response.json())
                .then(data => {
                    setPersonalGoals(data);
                    setHasGoals(data && data.length > 0);
                    setLoading(prev => ({ ...prev, goals: false }));
                })
                .catch(error => {
                    console.error('Error retrieving goals:', error);
                    setLoading(prev => ({ ...prev, goals: false }));
                });
        }
    }, [userId, activeTab]);

    // Fetch upcoming check-ins
    useEffect(() => {
        if (userId && activeTab === 'your-dashboard') {
            setLoading(prev => ({ ...prev, checkIns: true }));
            
            fetchWithAuth(`/api/check-ins/manager/${userId}`)
                .then(response => response.json())
                .then(data => {
                    // Filter for upcoming check-ins
                    const now = new Date();
                    const upcoming = data
                        .filter(checkIn => 
                            new Date(checkIn.scheduled_date) > now && 
                            checkIn.status === 'scheduled'
                        )
                        .sort((a, b) => new Date(a.scheduled_date) - new Date(b.scheduled_date))
                        .slice(0, 5); // Limit to 5 upcoming check-ins
                    
                    setUpcomingCheckIns(upcoming);
                    setLoading(prev => ({ ...prev, checkIns: false }));
                })
                .catch(error => {
                    console.error('Error fetching check-ins:', error);
                    setLoading(prev => ({ ...prev, checkIns: false }));
                });
        }
    }, [userId, activeTab]);

    // Fetch team goals
    useEffect(() => {
        if (userId && activeTab === 'your-dashboard') {
            setLoading(prev => ({ ...prev, teamGoals: true }));
            
            fetchWithAuth(`/api/team-goals/get-goals/${userId}`)
                .then(response => response.json())
                .then(data => {
                    // Add progress property if it doesn't exist
                    const goalsWithProgress = data.map(goal => ({
                        ...goal,
                        progress: goal.progress || 0
                    }));
                    
                    setTeamGoals(goalsWithProgress);
                    setLoading(prev => ({ ...prev, teamGoals: false }));
                })
                .catch(error => {
                    console.error('Error fetching team goals:', error);
                    setLoading(prev => ({ ...prev, teamGoals: false }));
                });
        }
    }, [userId, activeTab]);

    // Fetch company-wide stats for admin dashboard
    useEffect(() => {
        if (companyId && activeTab === 'your-dashboard') {
            const fetchCompanyStats = async () => {
                setLoading(prev => ({ ...prev, companyStats: true }));
                
                try {
                    // Fetch all company users
                    const usersResponse = await fetchWithAuth(`/api/users/get-users-by-company/${companyId}`);
                    const usersData = await usersResponse.json();
                    const totalEmployees = usersData.length;

                    // Use Promise.all to fetch all data in parallel for better performance
                    const [companyGoalsResponse, allUserGoalsData] = await Promise.all([
                        // Fetch company goals
                        fetchWithAuth(`/api/company-goals/get-goals/${companyId}`)
                            .then(response => response.json())
                            .catch(error => {
                                console.error('Error fetching company goals:', error);
                                return [];
                            }),
                        
                        // Fetch all user goals in a batched manner
                        Promise.all(
                            // Batch users into groups of 5 to avoid too many parallel requests
                            Array.from({ length: Math.ceil(usersData.length / 5) }, (_, i) => 
                                usersData.slice(i * 5, (i + 1) * 5)
                            ).map(async userBatch => {
                                // For each batch, fetch goals in parallel
                                const batchResults = await Promise.all(
                                    userBatch.map(user => 
                                        fetchWithAuth(`/api/goals/get-goals/${user.id}`)
                                            .then(response => response.json())
                                            .catch(error => {
                                                console.error(`Error fetching goals for user ${user.id}:`, error);
                                                return [];
                                            })
                                    )
                                );
                                
                                // Flatten the batch results into a single array
                                return batchResults.flat();
                            })
                        ).then(batchedResults => batchedResults.flat())
                    ]);

                    // Calculate total goals and completed goals
                    const totalGoals = companyGoalsResponse.length + allUserGoalsData.length;
                    const completedGoals = allUserGoalsData.filter(goal => Number(goal.progress) === 100).length;

                    // Update company stats with real data
                    setCompanyStats({
                        totalEmployees,
                        totalGoals,
                        completedGoals
                    });
                } catch (error) {
                    console.error('Error fetching company statistics:', error);
                    // Fallback to default values if fetch fails
                    setCompanyStats({
                        totalEmployees: 0,
                        totalGoals: 0,
                        completedGoals: 0
                    });
                } finally {
                    setLoading(prev => ({ ...prev, companyStats: false }));
                }
            };

            fetchCompanyStats();
        }
    }, [companyId, activeTab]);

    // Fetch archived goals when viewing the old goals tab
    useEffect(() => {
        if (userId && activeTab === 'review-old-goals') {
            setIsArchivedGoalsLoading(true);
            
            const fetchArchivedGoals = async () => {
                try {
                    // Fetch personal archived goals
                    const personalResponse = await fetchWithAuth(`/api/goals/get-goals/${userId}?archived=true`);
                    const personalData = await personalResponse.json();
                    setArchivedPersonalGoals(personalData);
                    
                    // Fetch team archived goals
                    const teamResponse = await fetchWithAuth(`/api/team-goals/get-goals/${userId}?archived=true`);
                    const teamData = await teamResponse.json();
                    setArchivedTeamGoals(teamData);
                    
                    // Fetch company archived goals (if companyId exists)
                    if (companyId) {
                        const companyResponse = await fetchWithAuth(`/api/company-goals/get-goals/${companyId}?archived=true`);
                        const companyData = await companyResponse.json();
                        setArchivedCompanyGoals(companyData);
                    }
                } catch (error) {
                    console.error('Error fetching archived goals:', error);
                } finally {
                    setIsArchivedGoalsLoading(false);
                }
            };
            
            fetchArchivedGoals();
        }
    }, [userId, companyId, activeTab]);

    // Check for archived goals on initial load
    useEffect(() => {
        if (userId && companyId) {
            const checkForArchivedGoals = async () => {
                try {
                    // Check for personal archived goals
                    const personalResponse = await fetchWithAuth(`/api/goals/get-goals/${userId}?archived=true&limit=1`);
                    const personalData = await personalResponse.json();
                    
                    // Check for team archived goals
                    const teamResponse = await fetchWithAuth(`/api/team-goals/get-goals/${userId}?archived=true&limit=1`);
                    const teamData = await teamResponse.json();
                    
                    // Check for company archived goals
                    const companyResponse = await fetchWithAuth(`/api/company-goals/get-goals/${companyId}?archived=true&limit=1`);
                    const companyData = await companyResponse.json();
                    
                    // Set flag if there are any archived goals
                    setHasArchivedGoals(
                        (Array.isArray(personalData) && personalData.length > 0) || 
                        (Array.isArray(teamData) && teamData.length > 0) ||
                        (Array.isArray(companyData) && companyData.length > 0)
                    );
                } catch (error) {
                    console.error('Error checking for archived goals:', error);
                    setHasArchivedGoals(false);
                }
            };
            
            checkForArchivedGoals();
        }
    }, [userId, companyId]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setIsSidebarOpen(false);
        navigate(`?tab=${tab}`);
    };

    const toggleGoalSettingPeriod = async () => {
        try {
            const response = await fetchWithAuth('/api/goal-setting-period/toggle', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    company_id: companyId,
                    is_active: !isGoalSettingActive 
                }),
            });
            
            if (!response.ok) throw new Error('Failed to toggle goal setting period');
            
            const data = await response.json();
            updateGoalSettingStatus(data.is_active);
            
            // Refresh notifications after toggling goal period
            if (fetchNotifications) {
                fetchNotifications();
            }
            if (fetchUnreadCount) {
                fetchUnreadCount();
            }
            
            alert(`Goal setting period has been ${data.is_active ? 'opened' : 'closed'}`);
        } catch (error) {
            console.error('Error toggling goal setting period:', error);
            alert('Failed to toggle goal setting period');
        }
    };

    // Calculate overall progress for personal goals
    const calculateOverallProgress = (goals) => {
        if (!goals || goals.length === 0) return 0;
        
        const totalProgress = goals.reduce((sum, goal) => sum + Number(goal.progress || 0), 0);
        return Math.round(totalProgress / goals.length);
    };

    // Count completed personal goals
    const countCompletedGoals = (goals) => {
        if (!goals || goals.length === 0) return 0;
        return goals.filter(goal => Number(goal.progress) === 100).length;
    };

    const handleArchiveAllGoals = async () => {
        try {
            const response = await fetchWithAuth('/api/goal-setting-period/archive-goals', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ company_id: companyId }),
            });
            
            if (!response.ok) {
                throw new Error('Failed to archive goals');
            }
            
            const data = await response.json();
            alert(data.message);
            setShowArchiveConfirmation(false);
            
            // Refresh notifications
            if (fetchNotifications) {
                fetchNotifications();
            }
            if (fetchUnreadCount) {
                fetchUnreadCount();
            }
        } catch (error) {
            console.error('Error archiving goals:', error);
            alert('Failed to archive goals. Please try again later.');
        }
    };

    // Handle unarchiving a personal goal
    const handleUnarchivePersonalGoal = async (goalId) => {
        try {
            const response = await fetchWithAuth(`/api/goals/toggle-archive/${goalId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    archive: false
                })
            });
            
            if (!response.ok) {
                throw new Error('Failed to unarchive goal');
            }
            
            // Remove the goal from the archived list
            setArchivedPersonalGoals(prev => prev.filter(goal => goal.id !== goalId));
            
            alert('Personal goal successfully restored. It can now be found in your active goals.');
            
            // Optionally refresh active goals if on the dashboard tab
            if (activeTab === 'your-dashboard') {
                fetchWithAuth(`/api/goals/get-goals/${userId}`)
                    .then(response => response.json())
                    .then(data => {
                        setPersonalGoals(data);
                    })
                    .catch(error => {
                        console.error('Error retrieving goals:', error);
                    });
            }
        } catch (error) {
            console.error('Error unarchiving goal:', error);
            alert('Failed to restore goal. Please try again.');
        }
    };

    // Handle unarchiving a team goal
    const handleUnarchiveTeamGoal = async (goalId) => {
        try {
            const response = await fetchWithAuth(`/api/team-goals/toggle-archive/${goalId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    archive: false
                })
            });
            
            if (!response.ok) {
                throw new Error('Failed to unarchive team goal');
            }
            
            // Remove the goal from the archived list
            setArchivedTeamGoals(prev => prev.filter(goal => goal.id !== goalId));
            
            alert('Team goal successfully restored. It can now be found in your active team goals.');
            
            // Optionally refresh active team goals if on the dashboard tab
            if (activeTab === 'your-dashboard') {
                fetchWithAuth(`/api/team-goals/get-goals/${userId}`)
                    .then(response => response.json())
                    .then(data => {
                        setTeamGoals(data);
                    })
                    .catch(error => {
                        console.error('Error fetching team goals:', error);
                    });
            }
        } catch (error) {
            console.error('Error unarchiving team goal:', error);
            alert('Failed to restore team goal. Please try again.');
        }
    };

    // Handle unarchiving a company goal
    const handleUnarchiveCompanyGoal = async (goalId) => {
        try {
            const response = await fetchWithAuth(`/api/company-goals/toggle-archive/${goalId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    archive: false
                })
            });
            
            if (!response.ok) {
                throw new Error('Failed to unarchive company goal');
            }
            
            // Remove the goal from the archived list
            setArchivedCompanyGoals(prev => prev.filter(goal => goal.id !== goalId));
            
            alert('Company goal successfully restored. It can now be found in your active company goals.');
            
        } catch (error) {
            console.error('Error unarchiving company goal:', error);
            alert('Failed to restore company goal. Please try again.');
        }
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'your-dashboard':
                return (
                    <div className="portal-section">
                        <h2>Your Dashboard</h2>
                        <p>View your company overview, upcoming check-ins, and goal progress.</p>
                        
                        <div className="admin-dashboard-grid">
                            {/* Company Overview Section */}
                            <div className="admin-dashboard-card company-overview">
                                <div className="admin-dashboard-card-header">
                                    <h3><FaUsers className="card-icon" /> Company Overview</h3>
                                </div>
                                {loading.companyStats ? (
                                    <div className="admin-dashboard-loading">Loading company statistics...</div>
                                ) : (
                                    <>
                                        <div className="admin-stats-grid">
                                            <div className="admin-stat-card">
                                                <span className="admin-stat-value">{companyStats.totalEmployees}</span>
                                                <span className="admin-stat-label">Total Employees</span>
                                            </div>
                                            <div className="admin-stat-card">
                                                <span className="admin-stat-value">{companyStats.totalGoals}</span>
                                                <span className="admin-stat-label">Total Goals</span>
                                            </div>
                                            <div className="admin-stat-card">
                                                <span className="admin-stat-value">{companyStats.completedGoals}</span>
                                                <span className="admin-stat-label">Completed Goals</span>
                                            </div>
                                        </div>
                                        <div className="admin-dashboard-buttons">
                                            <Link to="/EmployeeDirectory">
                                                <button className="admin-dashboard-button">
                                                    View Directory <FaChevronRight size={12} />
                                                </button>
                                            </Link>
                                        </div>
                                    </>
                                )}
                            </div>
                            
                            {/* Upcoming Check-ins Section */}
                            <div className="admin-dashboard-card check-ins">
                                <div className="admin-dashboard-card-header">
                                    <h3><FaCalendarCheck className="card-icon" /> Upcoming Check-ins</h3>
                                </div>
                                {loading.checkIns ? (
                                    <div className="admin-dashboard-loading">Loading check-ins...</div>
                                ) : upcomingCheckIns.length > 0 ? (
                                    <div className="admin-checkins-list">
                                        {upcomingCheckIns.map(checkIn => (
                                            <div key={checkIn.id} className="admin-checkin-item">
                                                <div className="admin-checkin-date">
                                                    {new Date(checkIn.scheduled_date).toLocaleDateString()} at {new Date(checkIn.scheduled_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                                                </div>
                                                <div className="admin-checkin-employee">
                                                    with <strong>{checkIn.first_name} {checkIn.last_name}</strong>
                                                </div>
                                                {checkIn.manager_notes && (
                                                    <div className="admin-checkin-notes">
                                                        {checkIn.manager_notes}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="admin-no-data-message">No upcoming check-ins scheduled.</div>
                                )}
                                <div className="admin-dashboard-buttons">
                                    <Link to="/TeamMembers">
                                        <button className="admin-dashboard-button">
                                            Manage Team <FaChevronRight size={12} />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            
                            {/* Personal Goals Section */}
                            <div className="admin-dashboard-card personal-goals">
                                <div className="admin-dashboard-card-header">
                                    <h3><FaBullseye className="card-icon" /> Your Goals</h3>
                                </div>
                                {loading.goals ? (
                                    <div className="admin-dashboard-loading">Loading goals...</div>
                                ) : personalGoals.length > 0 ? (
                                    <div className="admin-goals-summary">
                                        <div className="admin-goals-stats">
                                            <div className="admin-stat-card">
                                                <span className="admin-stat-value">{personalGoals.length}</span>
                                                <span className="admin-stat-label">Total Goals</span>
                                            </div>
                                            <div className="admin-stat-card">
                                                <span className="admin-stat-value">{calculateOverallProgress(personalGoals)}%</span>
                                                <span className="admin-stat-label">Overall Progress</span>
                                            </div>
                                            <div className="admin-stat-card">
                                                <span className="admin-stat-value">{countCompletedGoals(personalGoals)}</span>
                                                <span className="admin-stat-label">Completed</span>
                                            </div>
                                        </div>
                                        <div className="admin-goals-list">
                                            {personalGoals.slice(0, 3).map(goal => (
                                                <div key={goal.id} className="admin-goal-item">
                                                    <div className="admin-goal-summary">{goal.summary}</div>
                                                    <div className="admin-goal-progress-container">
                                                        <div className="admin-goal-progress-bar">
                                                            <div 
                                                                className="admin-goal-progress-fill" 
                                                                style={{ width: `${goal.progress}%` }}
                                                            ></div>
                                                        </div>
                                                        <span className="admin-goal-progress-text">{goal.progress}%</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="admin-no-data-message">No personal goals set yet.</div>
                                )}
                                <div className="admin-dashboard-buttons">
                                    <Link to="/ProgressPage">
                                        <button className="admin-dashboard-button">
                                            View Progress <FaChevronRight size={12} />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            
                            {/* Team Goals Section */}
                            <div className="admin-dashboard-card team-goals">
                                <div className="admin-dashboard-card-header">
                                    <h3><FaTasks className="card-icon" /> Team Goals</h3>
                                </div>
                                {loading.teamGoals ? (
                                    <div className="admin-dashboard-loading">Loading team goals...</div>
                                ) : teamGoals.length > 0 ? (
                                    <div className="admin-goals-list">
                                        {teamGoals.slice(0, 5).map(goal => (
                                            <div key={goal.id} className="admin-goal-item">
                                                <div className="admin-goal-summary">{goal.summary}</div>
                                                <div className="admin-goal-progress-container">
                                                    <div className="admin-goal-progress-bar">
                                                        <div 
                                                            className="admin-goal-progress-fill" 
                                                            style={{ width: `${goal.progress}%` }}
                                                        ></div>
                                                    </div>
                                                    <span className="admin-goal-progress-text">{goal.progress}%</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="admin-no-data-message">No team goals set yet.</div>
                                )}
                                <div className="admin-dashboard-buttons">
                                    <Link to="/ManagerMaster?step=3">
                                        <button className="admin-dashboard-button">
                                            Manage Team Goals <FaChevronRight size={12} />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'set-company-goals':
                return (
                    <div className="portal-section">
                        <h2>Set Company Goals</h2>
                        <p>Set universal goals for all employees and managers to see.</p>
                        <div className='admin-goal-setting-button-container'>
                            <Link to={`/AdminMaster?step=0&tab=${activeTab}`}>
                                <button className='admin-goal-setting-button'>
                                    <i className="fas fa-plus"></i>
                                    Set Goals
                                </button>
                            </Link>
                            <Link to={`/AdminMaster?step=2&tab=${activeTab}`}>
                                <button className='admin-goal-setting-button'>
                                    <i className="fas fa-eye"></i>
                                    Review Goals
                                </button>
                            </Link>
                        </div>
                    </div>
                );
            case 'employee-directory':
                return (
                    <div className="portal-section">
                        <h2>Employee Directory</h2>
                        <p>View and manage the employee directory.</p>
                        <Link to={`/EmployeeDirectory?tab=${activeTab}`}>
                            <button className='admin-goal-setting-button'>View Directory</button>
                        </Link>
                    </div>
                );
            case 'billing-usage':
                return (
                    <div className="portal-section">
                        <h2>Billing & Usage</h2>
                        <p>View billing and usage statistics.</p>
                        <Link to={`/Billing?tab=${activeTab}`}>
                            <button className='admin-goal-setting-button'>View Billing</button>
                        </Link>
                    </div>
                );
            case 'store-manager':
                return (
                    <div className="portal-section">
                        <h2>Store Manager</h2>
                        <p>Manage your rewards store and employee redemptions.</p>
                        <Link to={`/StoreManager?tab=${activeTab}`}>
                            <button className='admin-goal-setting-button'>View Store</button>
                        </Link>
                    </div>
                );
            case 'set-team-goals':
                return (
                    <div className="portal-section">
                        <h2>Set Team Goals</h2>
                        <p>Define goals for your team to achieve.</p>
                        <div className='admin-goal-setting-button-container'>
                            <Link to={`/ManagerMaster?step=0&tab=${activeTab}`}>
                                <button className='admin-goal-setting-button'>Set Team Goals</button>
                            </Link>
                            <Link to={`/ManagerMaster?step=3&tab=${activeTab}`}>
                                <button className='admin-goal-setting-button'>Review Goals</button>
                            </Link>
                        </div>
                    </div>
                );
            case 'your-team':
                return (
                    <div className="portal-section">
                        <h2>Your Team</h2>
                        <p>View and manage your team members.</p>
                        <Link to={`/TeamMembers?tab=${activeTab}`}>
                            <button className='admin-goal-setting-button'>View Team</button>
                        </Link>
                    </div>
                );
            case 'set-your-goals':
                return (
                    <div className="portal-section">
                        <h2>{hasGoals ? 'Manage Your Goals' : 'Set Your Goals'}</h2>
                        <p>{hasGoals ? 'Review or set new personal goals.' : 'Establish your personal goals.'}</p>
                        <div className='admin-goal-setting-button-container'>
                            <Link to={`/EmployeeMaster?step=0&tab=${activeTab}`}>
                                <button className='admin-goal-setting-button'>Set New Goals</button>
                            </Link>
                            {hasGoals && (
                                <Link to={`/EmployeeMaster?step=2&tab=${activeTab}`}>
                                    <button className='admin-goal-setting-button'>Review Goals</button>
                                </Link>
                            )}
                        </div>
                    </div>
                );
            case 'your-progress':
                return (
                    <div className="portal-section">
                        <h2>Your Progress</h2>
                        <p>View your progress.</p>
                        <Link to={`/ProgressPage?tab=${activeTab}`}>
                            <button className='admin-goal-setting-button'>View Progress</button>
                        </Link>
                    </div>
                );
            case 'goal-setting-period':
                return (
                    <div className="portal-section">
                        <h2>Goal Setting Period Control</h2>
                        <p>Control when managers and employees can set or edit their goals.</p>
                        <div className='admin-goal-setting-status'>
                            <h3>Current Status: {isGoalSettingActive ? 'Open' : 'Closed'}</h3>
                            <p>{isGoalSettingActive 
                                ? 'Managers and employees can currently set and edit their goals.' 
                                : 'Goal setting is currently disabled for managers and employees.'}
                            </p>
                        </div>
                        <button 
                            className={`admin-goal-setting-period-button ${isGoalSettingActive ? 'active' : ''}`}
                            onClick={toggleGoalSettingPeriod}
                        >
                            {isGoalSettingActive ? 'Close Goal Setting Period' : 'Open Goal Setting Period'}
                        </button>
                        
                        <div className="admin-archive-section">
                            <h3>Archive Goals</h3>
                            <p>Archive all current goals for all employees in your organization. This action cannot be undone.</p>
                            <button 
                                className="admin-archive-button"
                                onClick={() => setShowArchiveConfirmation(true)}
                            >
                                <FaArchive /> Archive All Goals
                            </button>
                            
                            {showArchiveConfirmation && (
                                <div className="admin-confirmation-modal">
                                    <div className="admin-confirmation-content">
                                        <h3>Confirm Archive</h3>
                                        <p>Are you sure you want to archive all goals for all employees?</p>
                                        <p className="admin-warning">This action cannot be undone.</p>
                                        <div className="admin-confirmation-buttons">
                                            <button 
                                                className="admin-confirm-button"
                                                onClick={handleArchiveAllGoals}
                                            >
                                                Yes, Archive All Goals
                                            </button>
                                            <button 
                                                className="admin-cancel-button"
                                                onClick={() => setShowArchiveConfirmation(false)}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
            case 'review-old-goals':
                return (
                    <div className="portal-section">
                        <h2>Review Old Goals</h2>
                        <p>View goals that have been archived from previous goal setting periods.</p>
                        
                        {isArchivedGoalsLoading ? (
                            <div className="admin-loading">Loading archived goals...</div>
                        ) : (
                            <div className="admin-archived-goals-container">
                                {/* Company Goals Section */}
                                <div className="admin-archived-section">
                                    <h3>Company Goals</h3>
                                    {archivedCompanyGoals.length > 0 ? (
                                        <div className="admin-archived-goals-list">
                                            {archivedCompanyGoals.map((goal) => (
                                                <div key={goal.id} className="admin-archived-goal-card">
                                                    <div className="admin-archived-goal-content">
                                                        <h4>{goal.text}</h4>
                                                        <p className="admin-archived-date">
                                                            Archived on: {new Date(goal.archived_at).toLocaleDateString()}
                                                        </p>
                                                        <div className="admin-archived-actions">
                                                            <button
                                                                className="admin-unarchive-button"
                                                                onClick={() => handleUnarchiveCompanyGoal(goal.id)}
                                                            >
                                                                Restore Company Goal
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="admin-no-archived">No archived company goals found.</p>
                                    )}
                                </div>
                                
                                {/* Team Goals Section */}
                                <div className="admin-archived-section">
                                    <h3>Team Goals</h3>
                                    {archivedTeamGoals.length > 0 ? (
                                        <div className="admin-archived-goals-list">
                                            {archivedTeamGoals.map((goal) => (
                                                <div key={goal.id} className="admin-archived-goal-card">
                                                    <div className="admin-archived-goal-content">
                                                        <h4>{goal.summary || goal.goal_text}</h4>
                                                        <div className="admin-archived-goal-progress">
                                                            <div className="admin-archived-progress-bar">
                                                                <div 
                                                                    className="admin-archived-progress-fill" 
                                                                    style={{ width: `${goal.progress || 0}%` }}
                                                                ></div>
                                                            </div>
                                                            <span className="admin-archived-progress-text">{goal.progress || 0}%</span>
                                                        </div>
                                                        <p className="admin-archived-date">
                                                            Archived on: {new Date(goal.archived_at).toLocaleDateString()}
                                                        </p>
                                                        <div className="admin-archived-actions">
                                                            <button
                                                                className="admin-unarchive-button"
                                                                onClick={() => handleUnarchiveTeamGoal(goal.id)}
                                                            >
                                                                Restore Team Goal
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="admin-no-archived">No archived team goals found.</p>
                                    )}
                                </div>
                                
                                {/* Personal Goals Section */}
                                <div className="admin-archived-section">
                                    <h3>Personal Goals</h3>
                                    {archivedPersonalGoals.length > 0 ? (
                                        <div className="admin-archived-goals-list">
                                            {archivedPersonalGoals.map((goal) => (
                                                <div key={goal.id} className="admin-archived-goal-card">
                                                    <div className="admin-archived-goal-content">
                                                        <h4>{goal.summary || goal.goal_text}</h4>
                                                        <div className="admin-archived-goal-progress">
                                                            <div className="admin-archived-progress-bar">
                                                                <div 
                                                                    className="admin-archived-progress-fill" 
                                                                    style={{ width: `${goal.progress || 0}%` }}
                                                                ></div>
                                                            </div>
                                                            <span className="admin-archived-progress-text">{goal.progress || 0}%</span>
                                                        </div>
                                                        <p className="admin-archived-date">
                                                            Archived on: {new Date(goal.archived_at).toLocaleDateString()}
                                                        </p>
                                                        <div className="admin-archived-actions">
                                                            <button
                                                                className="admin-unarchive-button"
                                                                onClick={() => handleUnarchivePersonalGoal(goal.id)}
                                                            >
                                                                Restore Personal Goal
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="admin-no-archived">No archived personal goals found.</p>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
            <div className="admin-portal">
                <button 
                    className="admin-portal-menu-button" 
                    onClick={toggleSidebar}
                >
                    Menu
                </button>
                <div className="admin-portal-sidebar">
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'your-dashboard' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('your-dashboard')}
                    >
                        Your Dashboard
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'set-company-goals' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('set-company-goals')}
                    >
                        Set Company Goals
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'goal-setting-period' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('goal-setting-period')}
                    >
                        Goal Setting Period
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'employee-directory' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('employee-directory')}
                    >
                        Employee Directory
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'billing-usage' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('billing-usage')}
                    >
                        Billing & Usage
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'store-manager' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('store-manager')}
                    >
                        Store Manager
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'set-team-goals' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('set-team-goals')}
                    >
                        Set Team Goals
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'your-team' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('your-team')}
                    >
                        Your Team
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'set-your-goals' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('set-your-goals')}
                    >
                        Set Your Goals
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'your-progress' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('your-progress')}
                    >
                        Your Progress
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'review-old-goals' ? 'active' : ''} ${!hasArchivedGoals ? 'disabled' : ''}`} 
                        onClick={() => hasArchivedGoals && handleTabClick('review-old-goals')}
                        title={!hasArchivedGoals ? "No archived goals to display" : "Review your archived goals"}
                    >
                        Review Old Goals
                    </button>
                </div>
                <div ref={sidebarRef} className={`admin-portal-sidebar mobile ${isSidebarOpen ? 'open' : ''}`}>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'your-dashboard' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('your-dashboard')}
                    >
                        Your Dashboard
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'set-company-goals' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('set-company-goals')}
                    >
                        Set Company Goals
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'goal-setting-period' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('goal-setting-period')}
                    >
                        Goal Setting Period
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'employee-directory' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('employee-directory')}
                    >
                        Employee Directory
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'billing-usage' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('billing-usage')}
                    >
                        Billing & Usage
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'store-manager' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('store-manager')}
                    >
                        Store Manager
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'set-team-goals' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('set-team-goals')}
                    >
                        Set Team Goals
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'your-team' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('your-team')}
                    >
                        Your Team
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'set-your-goals' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('set-your-goals')}
                    >
                        Set Your Goals
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'your-progress' ? 'active' : ''}`} 
                        onClick={() => handleTabClick('your-progress')}
                    >
                        Your Progress
                    </button>
                    <button 
                        className={`admin-portal-sidebar-button ${activeTab === 'review-old-goals' ? 'active' : ''} ${!hasArchivedGoals ? 'disabled' : ''}`} 
                        onClick={() => hasArchivedGoals && handleTabClick('review-old-goals')}
                        title={!hasArchivedGoals ? "No archived goals to display" : "Review your archived goals"}
                    >
                        Review Old Goals
                    </button>
                </div>
                <div className="admin-portal-content">
                    {renderContent()}
                </div>
            </div>
    );
};

export default AdminPortal;