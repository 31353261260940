import axios from 'axios';
import Cookies from 'js-cookie';

// Create axios instance with default config
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '/api',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Session management
let isRefreshing = false;
let failedQueue = [];
let isRedirecting = false;

// Function to process failed requests
const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  
  failedQueue = [];
};

// Function to refresh token
const refreshToken = async () => {
  try {
    const response = await axios.post('/api/auth/refresh-token', {}, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Helper function to handle session expiration
const handleSessionExpiration = () => {
  if (!isRedirecting) {
    isRedirecting = true;
    
    // Clear cookies
    Cookies.remove('authToken');
    Cookies.remove('userId');
    Cookies.remove('userRole');
    Cookies.remove('company_id');
    Cookies.remove('first_name');
    Cookies.remove('last_name');
    
    // Show expiration message
    sessionStorage.setItem('sessionExpired', 'true');
    
    // Redirect to login
    window.location.href = '/login';
  }
  
  return Promise.reject(new Error('Session expired'));
};

// Response interceptor for handling auth errors
api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    
    // Detect token expiration errors
    if ((error.response?.status === 401 || error.response?.status === 403) && 
        error.response?.data?.error === 'Invalid token.') {
      
      // If the token refresh request itself fails, redirect to login
      if (originalRequest.url === '/auth/refresh-token' || isRedirecting) {
        return handleSessionExpiration();
      }
      
      // If already refreshing token, add request to queue
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            return api(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }
      
      isRefreshing = true;
      
      try {
        // Try to refresh the token
        await refreshToken();
        
        // Process queue with new token
        processQueue(null);
        isRefreshing = false;
        
        // Retry the original request
        return api(originalRequest);
      } catch (refreshError) {
        // If refresh fails, process queue with error
        processQueue(refreshError, null);
        isRefreshing = false;
        
        // Redirect to login
        isRedirecting = true;
        
        // Clear cookies
        Cookies.remove('authToken');
        Cookies.remove('userId');
        Cookies.remove('userRole');
        Cookies.remove('company_id');
        Cookies.remove('first_name');
        Cookies.remove('last_name');
        
        // Show expiration message
        sessionStorage.setItem('sessionExpired', 'true');
        
        // Redirect to login
        window.location.href = '/login';
        
        return Promise.reject(error);
      }
    }
    
    return Promise.reject(error);
  }
);

// Set up token refresh interval (refresh every 2.5 hours to stay ahead of 3 hour expiration)
// Only refresh if the user is actively using the app
const REFRESH_INTERVAL = 2.5 * 60 * 60 * 1000; // 2.5 hours in milliseconds
let tokenRefreshInterval;

// Start refreshing tokens when the app loads
const startTokenRefresh = () => {
  // Clear any existing interval
  if (tokenRefreshInterval) {
    clearInterval(tokenRefreshInterval);
  }
  
  // Set up interval to refresh token
  tokenRefreshInterval = setInterval(async () => {
    // Only refresh if user is logged in
    if (Cookies.get('authToken')) {
      try {
        await refreshToken();
        console.log('Token refreshed automatically');
      } catch (error) {
        console.error('Failed to refresh token:', error);
      }
    }
  }, REFRESH_INTERVAL);
  
  // Also refresh token on user activity
  document.addEventListener('click', debounce(() => {
    if (Cookies.get('authToken')) {
      refreshToken().catch(error => {
        console.error('Failed to refresh token on user activity:', error);
      });
    }
  }, 30000)); // Only try to refresh at most every 30 seconds
};

// Debounce helper function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

// Initialize token refresh on import
startTokenRefresh();

export default api; 