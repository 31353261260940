import React, { useState, useRef, useEffect, useMemo } from 'react';
import EmployeeStep1 from './EmployeeStep1';
import EmployeeStep2 from './EmployeeStep2';
import EmployeeStep3 from './EmployeeStep3';
import EmployeeStep4 from './EmployeeStep4';
import '../Styles/EmployeeMaster.css';
import { attachAutoExpand, detachAutoExpand } from '../../../Util/autoExpand';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../Contexts/authContext';
import { useGoalSetting } from '../../../Contexts/goalSettingContext';
import { useEmployeeContext } from '../../../Contexts/employeeContext';
import { scrollToTop } from '../../../Util/scrollHelper';
import fetchWithAuth from '../../../Util/fetchWithAuth';

function EmployeeMaster() {
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const initialStep = parseInt(queryParams.get('step'), 10);

    const [currentStep, setCurrentStep] = useState(initialStep || 0);
    const [companyMission, setCompanyMission] = useState('');
    const [companyGoals, setCompanyGoals] = useState([]);
    const [teamGoals, setTeamGoals] = useState([]);
    const [employeeGoals, setEmployeeGoals] = useState([]);
    
    const textareaRef = useRef(null);
    const { userId, userRole, companyId } = useAuth();
    const { isGoalSettingActive } = useGoalSetting();
    const { employeeContext, saveEmployeeContext } = useEmployeeContext();
    
    console.log('User context:', { userId, userRole, companyId });

    useEffect(() => {
        if (companyId) {
            console.log('Fetching company mission and goals for companyId:', companyId);
            fetchWithAuth(`/api/company-missions/mission/${companyId}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => setCompanyMission(data.mission))
                .catch(error => {
                    console.error('Error fetching company mission:', error);
                });

            fetchWithAuth(`/api/company-goals/get-goals/${companyId}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => setCompanyGoals(data))
                .catch(error => {
                    console.error('Error fetching company goals:', error);
                });
        }
    }, [companyId]);

    useEffect(() => {
        if (userId) {
            console.log('Fetching employee goals for userId:', userId);
            fetchWithAuth(`/api/goals/get-goals/${userId}`)
                .then(response => response.json())
                .then(apiGoals => {
                    console.log('Fetched goals from API:', apiGoals);
                    setEmployeeGoals(apiGoals);
                })
                .catch(error => {
                    console.error('Error retrieving employee goals:', error);
                    setEmployeeGoals([]);
                });
        }
    }, [userId]);

    useEffect(() => {
        if (userId) {
            fetchWithAuth(`/api/users/get-manager/${userId}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    const managerId = data.managerId;
                    if (managerId) {
                        fetchWithAuth(`/api/team-goals/get-goals/${managerId}`)
                            .then(response => {
                                if (!response.ok) {
                                    throw new Error('Network response was not ok');
                                }
                                return response.json();
                            })
                            .then(goals => {
                                setTeamGoals(goals);
                            })
                            .catch(error => {
                                console.error('Error fetching team goals:', error);
                            });
                    }
                })
                .catch(error => {
                    console.error('Error fetching manager ID:', error);
                });
        }
    }, [userId]);

    useEffect(() => {
        if (textareaRef.current) {
            attachAutoExpand(textareaRef.current);
        }
        return () => {
            if (textareaRef.current) {
                detachAutoExpand(textareaRef.current);
            }
        };
    }, [textareaRef]);

    useEffect(() => {
        const handlePopState = (event) => {
            const step = event.state?.step || 0;
            console.log('Popstate event, setting current step:', step);
            setCurrentStep(step);
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    const updateStep = (newStep) => {
        console.log('Updating step to:', newStep);
        setCurrentStep(newStep);
        window.history.pushState({ step: newStep }, '', `?step=${newStep}`);
        scrollToTop();
    };

    const nextStep = () => updateStep(currentStep + 1);
    const prevStep = () => updateStep(currentStep - 1);

    const saveForLater = async () => {
        try {
            const newGoals = employeeGoals.filter(goal => !goal.id);
            const existingGoals = employeeGoals.filter(goal => goal.id);

            if (newGoals.length > 0) {
                await fetchWithAuth('/api/goals/create-goals', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        userId, 
                        goals: newGoals,
                        is_draft: true 
                    }),
                });
            }

            if (existingGoals.length > 0) {
                await fetchWithAuth('/api/goals/update-goals', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        userId, 
                        goals: existingGoals,
                        is_draft: true 
                    }),
                });
            }
            
            // Save employee context
            await saveEmployeeContext(employeeContext);

            alert('Progress saved successfully!');
            scrollToTop();
        } catch (error) {
            console.error('Error saving progress:', error);
            alert('Failed to save progress. Please try again.');
        }
    };

    const submitEmployeeGoals = async (summarizedGoals) => {
        const newGoals = summarizedGoals.filter(goal => !goal.id);
        const existingGoals = summarizedGoals.filter(goal => goal.id);

        try {
            if (newGoals.length > 0) {
                await fetchWithAuth('/api/goals/create-goals', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        userId, 
                        goals: newGoals,
                        is_draft: false 
                    }),
                });
            }

            if (existingGoals.length > 0) {
                await fetchWithAuth('/api/goals/update-goals', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        userId, 
                        goals: existingGoals,
                        is_draft: false 
                    }),
                });
            }
            
            // Save final employee context
            await saveEmployeeContext(employeeContext);
            scrollToTop();
        } catch (error) {
            console.error('Error submitting goals:', error);
            throw error;
        }
    };

    const handleSectionChange = () => {
        scrollToTop();
    };

    const handleShowConfirmation = () => {
        scrollToTop();
    };

    // Update the condition to check the specific tab
    const queryTab = queryParams.get('tab');
    if (!isGoalSettingActive && queryTab === 'set-your-goals') {
        return (
            <div className='employee-goal-set-body'>
                <div className='goal-setting-inactive-message'>
                    <h2>Goal Setting Period is Closed</h2>
                    <p>The goal setting period is currently closed. Please check back later or contact your administrator.</p>
                </div>
            </div>
        );
    }

    return (
        <div className='employee-goal-set-body'>
            {currentStep === 0 && (
                <EmployeeStep1
                    companyMission={companyMission}
                    companyGoals={companyGoals}
                    teamGoals={teamGoals}
                    nextStep={nextStep}
                    userRole={userRole}
                />
            )}
            {currentStep === 1 && (
                <EmployeeStep2
                    prevStep={prevStep}
                    nextStep={nextStep}
                    saveForLater={saveForLater}
                    onSectionChange={handleSectionChange}
                    onShowConfirmation={handleShowConfirmation}
                />
            )}
            {currentStep === 2 && (
                <EmployeeStep3
                    employeeGoals={employeeGoals}
                    setEmployeeGoals={setEmployeeGoals}
                    prevStep={prevStep}
                    nextStep={nextStep}
                    saveForLater={saveForLater}
                    textareaRef={textareaRef}
                    userId={userId}
                />
            )}
            {currentStep === 3 && (
                <EmployeeStep4
                    employeeGoals={employeeGoals}
                    setEmployeeGoals={setEmployeeGoals}
                    prevStep={prevStep}
                    saveForLater={saveForLater}
                    submitData={submitEmployeeGoals}
                    userRole={userRole}
                />
            )}
        </div>
    );
}

export default EmployeeMaster;
