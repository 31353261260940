import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight, FaFloppyDisk, FaPlus, FaPenToSquare, FaTrash, FaCheck } from 'react-icons/fa6';

function AdminStep2({ companyGoals, setCompanyGoals, prevStep, nextStep, saveForLater, textareaRef, company_id }) {
    const [goals, setGoals] = useState(companyGoals);

    useEffect(() => {
        if (company_id) {
            console.log('Fetching company goals for companyId:', company_id);
            fetch(`/api/company-goals/get-goals/${company_id}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Fetched goals:', data);
                    const mergedGoals = data.map(fetchedGoal => {
                        const currentGoal = goals.find(g => g.id === fetchedGoal.id);
                        return currentGoal || fetchedGoal;
                    });
                    setGoals(mergedGoals);
                    setCompanyGoals(mergedGoals);
                })
                .catch(error => {
                    console.error('Error fetching company goals:', error);
                });
        }
    }, [company_id]);

    const addCompanyGoal = () => {
        if (goals.length >= 5) {
            alert('You can only have up to 5 goals.');
            return;
        }
        const newCompanyGoals = [...goals, { text: '', confirmed: false }];
        setGoals(newCompanyGoals);
        setCompanyGoals(newCompanyGoals);
    };

    const handleCompanyGoalChange = (index, value) => {
        const newCompanyGoals = goals.map((goal, i) => {
            if (i === index) {
                return { ...goal, text: value, confirmed: false };
            }
            return goal;
        });
        setGoals(newCompanyGoals);
        setCompanyGoals(newCompanyGoals);
    };

    const toggleCompanyGoalConfirmation = (index) => {
        const newCompanyGoals = goals.map((goal, i) => {
            if (i === index) {
                return { ...goal, confirmed: !goal.confirmed };
            }
            return goal;
        });
        setGoals(newCompanyGoals);
        setCompanyGoals(newCompanyGoals);
    };

    const deleteCompanyGoal = async (index) => {
        const goalToDelete = goals[index];
        if (goalToDelete.id) {
            try {
                const response = await fetch(`/api/company-goals/delete-goal/${goalToDelete.id}`, {
                    method: 'DELETE',
                });

                if (!response.ok) {
                    throw new Error('Failed to delete goal');
                }
            } catch (error) {
                console.error('Error deleting goal:', error);
                return;
            }
        }

        const newCompanyGoals = goals.filter((_, i) => i !== index);
        setGoals(newCompanyGoals);
        setCompanyGoals(newCompanyGoals);
    };

    return (
        <div className="page-container">
            <div className="page-content">
                <div className='admin-goal-set-container'>
                    <h2 className='admin-goal-set-title'>Set Your Company Goals</h2>
                    <p className='admin-goal-set-description'>
                        Please enter up to five company-wide goals below. These will be considered when creating personalized goals for your employees.
                    </p>

                    <div className='admin-goal-actions'>
                        {goals.length < 5 && (
                            <button onClick={addCompanyGoal} className='admin-goal-set-add-button'>
                                <FaPlus /> Add Goal
                            </button>
                        )}
                    </div>

                    <div className='admin-goals-container'>
                        {goals.length > 0 ? (
                            goals.map((goal, index) => (
                                <div key={index} className='admin-goal-item'>
                                    <div className='admin-goal-content'>
                                        <textarea
                                            className='admin-goal-set-input'
                                            placeholder='Enter a company goal here'
                                            value={goal.text}
                                            onChange={(e) => handleCompanyGoalChange(index, e.target.value)}
                                            ref={textareaRef}
                                            readOnly={goal.confirmed}
                                        />
                                        <div className="admin-goal-actions">
                                            <button 
                                                onClick={() => toggleCompanyGoalConfirmation(index)} 
                                                className={`action-button ${goal.confirmed ? 'edit' : 'confirm'}`}
                                            >
                                                {goal.confirmed ? <FaPenToSquare /> : <FaCheck />}
                                                {goal.confirmed ? 'Edit' : 'Confirm'}
                                            </button>
                                            <button 
                                                onClick={() => deleteCompanyGoal(index)} 
                                                className='action-button delete'
                                            >
                                                <FaTrash /> Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="admin-goal-set-description">No goals available. Please add some goals.</p>
                        )}
                    </div>

                    <div className='admin-goal-set-button-container'>
                        <button onClick={prevStep} className='admin-goal-set-link'>
                            <FaArrowLeft /> Back
                        </button>
                        <button onClick={saveForLater} className='admin-goal-set-link'>
                            <FaFloppyDisk /> Save for Later
                        </button>
                        <button onClick={nextStep} className='admin-goal-set-link'>
                            Next <FaArrowRight />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminStep2;