import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from '../../Contexts/authContext'; // Import the Auth Context
import NotificationIcon from '../Notifications/NotificationIcon'; // Import the NotificationIcon component
import { scrollToTop } from '../../Util/scrollHelper'; // Import the scroll helper
import "./Navbar.css";

const Navbar = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const sidebarRef = useRef(null); // Create a ref for the sidebar
	const { userRole, userId, isAuthenticated, logout } = useAuth(); // Get user data from auth context
	const navigate = useNavigate();

	const handleScrollToTop = () => {
		scrollToTop(); // Use our utility function
		setIsSidebarOpen(false);
	};

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	const handleSignOut = () => {
		// Use the logout function from auth context
		logout();
		
		// The logout function now handles the redirect, so we don't need to do it here
		// navigate('/login');
	};

	const handleGoToPortal = () => {
		if (userRole === 'Admin') {
			navigate('/adminportal?tab=your-dashboard');
		} else if (userRole === 'Manager') {
			navigate('/managerportal?tab=your-dashboard');
		} else {
			navigate('/employeeportal?tab=dashboard'); // Employee portal uses 'dashboard' instead of 'your-dashboard'
		}
	};

	const handleClickOutside = (event) => {
		if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
			setIsSidebarOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className="navbar-container">
			<div className="navbar">
				<div className="navbar-hamburger" onClick={toggleSidebar}>
					<div className="navbar-hamburger-line"></div>
					<div className="navbar-hamburger-line"></div>
					<div className="navbar-hamburger-line"></div>
				</div>
				<NavLink to="/" className="navbar-link-logo" onClick={handleScrollToTop}>Astellyst</NavLink>
				<div className="navbar-links">
					<NavLink to="/About" className="navbar-link" onClick={handleScrollToTop}>About</NavLink>
					<NavLink to="/Contact" className="navbar-link" onClick={handleScrollToTop}>Contact</NavLink>
					<NavLink to="/Resources" className="navbar-link" onClick={handleScrollToTop}>Resources</NavLink>
				</div>
				<div className="navbar-buttons">
					{isAuthenticated ? (
						<>
							<div className="navbar-notification-icon">
								<NotificationIcon />
							</div>
							<button className="navbar-link-button" onClick={handleGoToPortal}>Go to Portal</button>
							<button className="navbar-link-button" onClick={handleSignOut}>Sign Out</button>
						</>
					) : (
						<NavLink to="/login" className="navbar-link" onClick={handleScrollToTop}>Sign In</NavLink>
					)}
				</div>
			</div>
			{/* Sidebar */}
			<div ref={sidebarRef} className={`navbar-sidebar mobile ${isSidebarOpen ? 'open' : ''}`}>
				<button className="navbar-close-btn" onClick={toggleSidebar}>×</button>
				<NavLink to="/About" className="navbar-sidebar-link" onClick={() => { handleScrollToTop(); toggleSidebar(); }}>About</NavLink>
				<NavLink to="/Contact" className="navbar-sidebar-link" onClick={() => { handleScrollToTop(); toggleSidebar(); }}>Contact</NavLink>
				<NavLink to="/Resources" className="navbar-sidebar-link" onClick={() => { handleScrollToTop(); toggleSidebar(); }}>Resources</NavLink>
				{isAuthenticated ? (
					<>
						<button className="navbar-sidebar-link-button" onClick={handleGoToPortal}>Go to Portal</button>
						<button className="navbar-sidebar-link-button" onClick={handleSignOut}>Sign Out</button>
					</>
				) : (
					<NavLink to="/login" className="navbar-sidebar-link" onClick={() => { handleScrollToTop(); toggleSidebar(); }}>Sign In</NavLink>
				)}
			</div>
		</div>
	);
};

export default Navbar;
