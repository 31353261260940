import React from 'react';
import { useTeamContext } from '../../../Contexts/teamContext';
import { 
    FaUsers, 
    FaPeopleGroup,
    FaPeopleRoof,
    FaLaptop,
    FaBuilding,
    FaBuildingUser,
    FaCircleQuestion,
    FaArrowLeft,
    FaArrowRight,
    FaCheck,
    FaFloppyDisk,
} from 'react-icons/fa6';

const CHARACTER_LIMITS = {
    teamFocus: 200,
    challengeDetails: 300,
    strengths: 300,
    priorities: 300,
    metrics: 300
};

function ManagerStep2({ nextStep, prevStep, saveForLater, onSectionChange, onShowConfirmation }) {
    const { teamContext, setTeamContext, saveTeamContext } = useTeamContext();
    
    // Handle potentially missing data with default values
    const safeTeamContext = {
        teamSize: teamContext?.teamSize || '',
        teamFocus: teamContext?.teamFocus || '',
        workStyle: teamContext?.workStyle || '',
        challenges: Array.isArray(teamContext?.challenges) ? teamContext.challenges : [],
        challengeDetails: teamContext?.challengeDetails || '',
        strengths: Array.isArray(teamContext?.strengths) ? teamContext.strengths : 
                  (teamContext?.strengths ? [teamContext.strengths] : []),
        opportunities: teamContext?.opportunities || '',
        priorities: teamContext?.priorities || '',
        metrics: teamContext?.metrics || '',
        developmentNeeds: teamContext?.developmentNeeds || '',
        currentSection: teamContext?.currentSection || 0
    };
    
    // Use safeTeamContext instead of directly accessing teamContext in your component
    const [currentSection, setCurrentSection] = React.useState(safeTeamContext.currentSection || 0);
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    
    const TEAM_SIZES = [
        { value: 'small', label: 'Small (2-5 members)', icon: FaUsers },
        { value: 'medium', label: 'Medium (6-12 members)', icon: FaPeopleGroup },
        { value: 'large', label: 'Large (13+ members)', icon: FaPeopleRoof }
    ];

    const WORK_STYLES = [
        { value: 'remote', label: 'Fully Remote', icon: FaLaptop },
        { value: 'hybrid', label: 'Hybrid', icon: FaBuildingUser },
        { value: 'office', label: 'Office-based', icon: FaBuilding }
    ];

    const COMMON_CHALLENGES = [
        "Communication barriers",
        "Work-life balance",
        "Meeting deadlines",
        "Resource constraints",
        "Skills gaps",
        "Team collaboration",
        "Other"
    ];

    const handleChallengeClick = (challenge) => {
        setTeamContext(prev => ({
            ...prev,
            challenges: (prev?.challenges || []).includes(challenge)
                ? (prev?.challenges || []).filter(c => c !== challenge)
                : [...(prev?.challenges || []), challenge]
        }));
    };

    const handleSaveAndContinue = async () => {
        onShowConfirmation();
        setShowConfirmation(true);
    };

    const handleConfirmAndProceed = async () => {
        try {
            console.log('Starting handleConfirmAndProceed with teamContext:', teamContext);
            
            // Ensure arrays are properly formatted
            const formattedData = {
                ...teamContext,
                challenges: Array.isArray(teamContext.challenges) 
                    ? teamContext.challenges 
                    : [],
                strengths: Array.isArray(teamContext.strengths) 
                    ? teamContext.strengths 
                    : typeof teamContext.strengths === 'string' && teamContext.strengths.trim() !== ''
                        ? [teamContext.strengths]
                        : []
            };
            
            console.log('Formatted data before save:', formattedData);
            
            await saveTeamContext(formattedData);
            console.log('Save successful, proceeding to next step');
            nextStep();
        } catch (error) {
            console.error('Detailed error in handleConfirmAndProceed:', {
                message: error.message,
                stack: error.stack
            });
            alert('Failed to save team context. Please try again.');
        }
    };

    const getCharacterCount = (field, value) => {
        const remaining = CHARACTER_LIMITS[field] - (value?.length || 0);
        return `${remaining} characters remaining`;
    };

    if (showConfirmation) {
        return (
            <div className='manager-goal-set-container'>
                <h2 className='manager-goal-set-title'>Confirm Team Context</h2>
                <div className='manager-team-context-form-section'>
                    <div className='manager-confirmation-content'>
                        <h4>Please review your team context before proceeding</h4>
                        
                        <div className='manager-confirmation-section'>
                            <h5>Team Overview</h5>
                            <div className='manager-confirmation-item'>
                                <strong>Team Size:</strong> {teamContext.teamSize}
                            </div>
                            <div className='manager-confirmation-item'>
                                <strong>Team Focus:</strong> {teamContext.teamFocus}
                            </div>
                            <div className='manager-confirmation-item'>
                                <strong>Work Style:</strong> {teamContext.workStyle}
                            </div>
                        </div>

                        <div className='manager-confirmation-section'>
                            <h5>Team Challenges</h5>
                            <ul>
                                {teamContext.challenges.map((challenge, index) => (
                                    <li key={index}>{challenge}</li>
                                ))}
                            </ul>
                            {teamContext.challengeDetails && (
                                <div className='manager-confirmation-item'>
                                    <strong>Additional Details:</strong> {teamContext.challengeDetails}
                                </div>
                            )}
                        </div>

                        <div className='manager-confirmation-section'>
                            <h5>Team Strengths</h5>
                            <div className='manager-confirmation-item'>{teamContext.strengths}</div>
                        </div>

                        <div className='manager-confirmation-section'>
                            <h5>Team Direction</h5>
                            <div className='manager-confirmation-item'>
                                <strong>Priorities:</strong> {teamContext.priorities}
                            </div>
                            <div className='manager-confirmation-item'>
                                <strong>Success Metrics:</strong> {teamContext.metrics}
                            </div>
                        </div>
                    </div>

                    <div className='manager-goal-set-button-container'>
                        <button 
                            onClick={() => setShowConfirmation(false)} 
                            className='manager-goal-set-link'
                        >
                            <FaArrowLeft /> Edit Information
                        </button>
                        <button onClick={saveForLater} className='manager-goal-set-link'>
                            <FaFloppyDisk /> Save for Later
                        </button>
                        <button 
                            onClick={handleConfirmAndProceed} 
                            className='manager-goal-set-link'
                        >
                            Confirm & Continue <FaArrowRight />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='manager-goal-set-container'>
            <h2 className='manager-goal-set-title'>Team Context</h2>
            
            <div className="manager-step-progress">
                <div className="manager-step-progress-bar">
                    <div 
                        className="manager-step-progress-fill"
                        style={{ width: `${((currentSection + 1) / 3) * 100}%` }}
                    />
                </div>
                <div className="manager-step-progress-text">
                    Step {currentSection + 1} of 3: {
                        currentSection === 0 ? 'Team Overview' :
                        currentSection === 1 ? 'Team Assessment' :
                        'Team Direction'
                    }
                </div>
            </div>

            <div className='manager-team-context-form'>
                {/* Team Overview Section */}
                {currentSection === 0 && (
                    <div className='manager-team-context-form-section'>
                        <h4 className='manager-team-context-form-section-title'>
                            Team Overview
                        </h4>
                        
                        <label className='manager-team-context-label'>
                            Team Size
                            <FaCircleQuestion className="manager-tooltip-icon" title="Select the size that best matches your team" />
                        </label>
                        <div className='manager-team-size-selector'>
                            {TEAM_SIZES.map(({value, label, icon: Icon}) => (
                                <div 
                                    key={value}
                                    className={`manager-size-option ${teamContext.teamSize === value ? 'selected' : ''}`}
                                    onClick={() => setTeamContext(prev => ({...prev, teamSize: value}))}
                                >
                                    <Icon />
                                    <span>{label}</span>
                                </div>
                            ))}
                        </div>

                        <label className='manager-team-context-label'>
                            Team's Primary Focus
                            <FaCircleQuestion className="manager-tooltip-icon" title="What is your team's main responsibility?" />
                            <span className="manager-character-count">{getCharacterCount('teamFocus', teamContext.teamFocus)}</span>
                        </label>
                        <textarea
                            className='manager-team-context-input'
                            value={teamContext.teamFocus}
                            onChange={(e) => setTeamContext(prev => ({...prev, teamFocus: e.target.value}))}
                            placeholder="e.g., Frontend Development, Customer Support, Sales..."
                            maxLength={CHARACTER_LIMITS.teamFocus}
                        />

                        <label className='manager-team-context-label'>
                            Work Style
                            <FaCircleQuestion className="manager-tooltip-icon" title="How does your team primarily work together?" />
                        </label>
                        <div className='manager-work-style-selector'>
                            {WORK_STYLES.map(({value, label, icon: Icon}) => (
                                <div 
                                    key={value}
                                    className={`manager-style-option ${teamContext.workStyle === value ? 'selected' : ''}`}
                                    onClick={() => setTeamContext(prev => ({...prev, workStyle: value}))}
                                >
                                    <Icon />
                                    <span>{label}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* Team Assessment Section */}
                {currentSection === 1 && (
                    <div className='manager-team-context-form-section'>
                        <h4 className='manager-team-context-form-section-title'>
                            Team Assessment
                        </h4>
                        
                        <label className='manager-team-context-label'>
                            What challenges does your team face?
                            <FaCircleQuestion className="manager-tooltip-icon" title="Select all that apply" />
                        </label>
                        <div className='manager-challenges-grid'>
                            {COMMON_CHALLENGES.map(challenge => (
                                <div 
                                    key={challenge} 
                                    className='manager-challenge-option'
                                    onClick={() => handleChallengeClick(challenge)}
                                >
                                    <input
                                        type="checkbox"
                                        checked={teamContext.challenges.includes(challenge)}
                                        onChange={() => handleChallengeClick(challenge)}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                    <span>{challenge}</span>
                                </div>
                            ))}
                        </div>
                        
                        {teamContext.challenges.includes('Other') && (
                            <>
                                <label className='manager-team-context-label'>
                                    Please specify other challenges
                                    <span className="manager-character-count">{getCharacterCount('challengeDetails', teamContext.challengeDetails)}</span>
                                </label>
                                <textarea
                                    className='manager-team-context-input'
                                    value={teamContext.challengeDetails}
                                    onChange={(e) => setTeamContext(prev => ({...prev, challengeDetails: e.target.value}))}
                                    placeholder="Please specify other challenges..."
                                    maxLength={CHARACTER_LIMITS.challengeDetails}
                                />
                            </>
                        )}

                        <label className='manager-team-context-label'>
                            Team's Key Strengths
                            <span className="manager-character-count">{getCharacterCount('strengths', teamContext.strengths)}</span>
                        </label>
                        <textarea
                            className='manager-team-context-input'
                            value={teamContext.strengths}
                            onChange={(e) => setTeamContext(prev => ({...prev, strengths: e.target.value}))}
                            placeholder="What makes your team special?"
                            maxLength={CHARACTER_LIMITS.strengths}
                        />
                    </div>
                )}

                {/* Team Direction Section */}
                {currentSection === 2 && (
                    <div className='manager-team-context-form-section'>
                        <h4 className='manager-team-context-form-section-title'>
                            Team Direction
                        </h4>
                        
                        <label className='manager-team-context-label'>
                            Top Priorities
                            <span className="manager-character-count">{getCharacterCount('priorities', teamContext.priorities)}</span>
                        </label>
                        <textarea
                            className='manager-team-context-input'
                            value={teamContext.priorities}
                            onChange={(e) => setTeamContext(prev => ({...prev, priorities: e.target.value}))}
                            placeholder="What are your team's main objectives this year?"
                            maxLength={CHARACTER_LIMITS.priorities}
                        />

                        <label className='manager-team-context-label'>
                            Success Metrics
                            <span className="manager-character-count">{getCharacterCount('metrics', teamContext.metrics)}</span>
                        </label>
                        <textarea
                            className='manager-team-context-input'
                            value={teamContext.metrics}
                            onChange={(e) => setTeamContext(prev => ({...prev, metrics: e.target.value}))}
                            placeholder="How do you measure success?"
                            maxLength={CHARACTER_LIMITS.metrics}
                        />
                    </div>
                )}

                <div className='manager-goal-set-button-container'>
                    {/* Back button - only visible in first section */}
                    {currentSection === 0 && (
                        <button onClick={prevStep} className='manager-goal-set-link'>
                            <FaArrowLeft /> Back
                        </button>
                    )}

                    {/* Navigation between sections */}
                    {currentSection > 0 && (
                        <button onClick={() => {
                            setCurrentSection(prev => prev - 1);
                            onSectionChange();
                        }} className='manager-goal-set-link'>
                            <FaArrowLeft /> Previous
                        </button>
                    )}
                    <button onClick={saveForLater} className='manager-goal-set-link'>
                        <FaFloppyDisk /> Save for Later
                    </button>
                    {currentSection < 2 ? (
                        <button onClick={() => {
                            setCurrentSection(prev => prev + 1);
                            onSectionChange();
                        }} className='manager-goal-set-link'>
                            Next <FaArrowRight />
                        </button>
                    ) : (
                        <button onClick={handleSaveAndContinue} className='manager-goal-set-link'>
                            <FaCheck /> Complete
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ManagerStep2;
