import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../Contexts/authContext';
import BackButton from '../../../Components/BackButton/BackButton';
import { FaCalendarCheck, FaAward, FaTrophy, FaChartLine, FaRegCheckCircle, FaRegClock, FaRegLightbulb, FaRegStar, FaUsers, FaBuilding, FaUserTie, FaChartPie, FaAddressBook, FaBell, FaClipboardCheck, FaCalendarAlt, FaVideo } from 'react-icons/fa';
import '../Styles/Dashboard.css';
import fetchWithAuth from '../../../Util/fetchWithAuth';

function Dashboard() {
    const [goals, setGoals] = useState([]);
    const [achievements, setAchievements] = useState([]);
    const [checkInStats, setCheckInStats] = useState({
        totalCheckIns: 0,
        weeklyStreak: 0,
        lastCheckIn: null
    });
    const [loading, setLoading] = useState(true);
    const [teamStats, setTeamStats] = useState(null);
    const [companyStats, setCompanyStats] = useState(null);
    
    // New state for check-ins
    const [upcomingCheckIns, setUpcomingCheckIns] = useState([]);
    const [recentCheckIns, setRecentCheckIns] = useState([]);
    
    const navigate = useNavigate();
    const { userId, firstName, lastName, role } = useAuth();

    useEffect(() => {
        const fetchDashboardData = async () => {
            if (!userId) return;
            
            try {
                setLoading(true);
                
                // Fetch goals
                const goalsResponse = await fetchWithAuth(`/api/goals/get-goals/${userId}`);
                const goalsData = await goalsResponse.json();
                setGoals(goalsData);
                
                // Try to fetch achievements from API
                try {
                    const achievementsResponse = await fetchWithAuth(`/api/achievements/get-achievements/${userId}`);
                    if (achievementsResponse.ok) {
                        const achievementsData = await achievementsResponse.json();
                        // Map the achievements data to our component format
                        const formattedAchievements = achievementsData.map(achievement => {
                            // Map icon names to actual icon components
                            let icon;
                            switch (achievement.icon_name) {
                                case 'FaRegCheckCircle':
                                    icon = <FaRegCheckCircle />;
                                    break;
                                case 'FaChartLine':
                                    icon = <FaChartLine />;
                                    break;
                                case 'FaTrophy':
                                    icon = <FaTrophy />;
                                    break;
                                case 'FaAward':
                                    icon = <FaAward />;
                                    break;
                                case 'FaRegStar':
                                    icon = <FaRegStar />;
                                    break;
                                default:
                                    icon = <FaRegLightbulb />;
                            }
                            
                            return {
                                id: achievement.id,
                                title: achievement.title,
                                description: achievement.description,
                                icon: icon,
                                date: new Date(achievement.date_earned).toLocaleDateString(),
                                type: achievement.achievement_type.includes('progress') ? 'progress' : 
                                      achievement.achievement_type.includes('goal') ? 'completion' : 
                                      achievement.achievement_type.includes('verified') ? 'verification' : 'progress'
                            };
                        });
                        
                        setAchievements(formattedAchievements);
                    } else {
                        // If API fails, fall back to generating sample achievements
                        const sampleAchievements = generateSampleAchievements(goalsData);
                        setAchievements(sampleAchievements);
                    }
                } catch (error) {
                    console.error("Error fetching achievements:", error);
                    // Fall back to generating sample achievements
                    const sampleAchievements = generateSampleAchievements(goalsData);
                    setAchievements(sampleAchievements);
                }
                
                // Try to fetch check-in stats from API
                try {
                    const checkInResponse = await fetchWithAuth(`/api/achievements/get-checkin-stats/${userId}`);
                    if (checkInResponse.ok) {
                        const checkInData = await checkInResponse.json();
                        setCheckInStats(checkInData);
                    } else {
                        // If API fails, fall back to calculating check-in stats
                        const calculatedStats = await calculateCheckInStats(goalsData);
                        setCheckInStats(calculatedStats);
                    }
                } catch (error) {
                    console.error("Error fetching check-in stats:", error);
                    // Fall back to calculating check-in stats
                    const calculatedStats = await calculateCheckInStats(goalsData);
                    setCheckInStats(calculatedStats);
                }
                
                // Fetch check-ins
                try {
                    const checkInsResponse = await fetchWithAuth(`/api/check-ins/employee/${userId}`);
                    if (checkInsResponse.ok) {
                        const checkInsData = await checkInsResponse.json();
                        
                        // Filter for upcoming check-ins
                        const now = new Date();
                        const upcoming = checkInsData
                            .filter(checkIn => 
                                new Date(checkIn.scheduledDate) > now && 
                                checkIn.status === 'scheduled'
                            )
                            .sort((a, b) => new Date(a.scheduledDate) - new Date(b.scheduledDate));
                        
                        setUpcomingCheckIns(upcoming);
                        
                        // Filter for recent completed check-ins
                        const recent = checkInsData
                            .filter(checkIn => checkIn.status === 'completed')
                            .sort((a, b) => new Date(b.completedDate) - new Date(a.completedDate))
                            .slice(0, 3);
                        
                        setRecentCheckIns(recent);
                    }
                } catch (error) {
                    console.error("Error fetching check-ins:", error);
                }
                
                // If user is a manager, fetch team stats
                if (role === 'Manager' || role === 'Admin') {
                    try {
                        // This would be a real API endpoint in production
                        // For now, we'll use mock data
                        setTeamStats({
                            teamSize: 8,
                            teamGoals: 32,
                            teamCompletedGoals: 18,
                            teamVerifiedGoals: 15
                        });
                    } catch (error) {
                        console.error("Error fetching team stats:", error);
                    }
                }
                
                // If user is an admin, fetch company stats
                if (role === 'Admin') {
                    try {
                        // This would be a real API endpoint in production
                        // For now, we'll use mock data
                        setCompanyStats({
                            totalEmployees: 25,
                            totalGoals: 87,
                            completedGoals: 42,
                            verifiedGoals: 38
                        });
                    } catch (error) {
                        console.error("Error fetching company stats:", error);
                    }
                }
                
                setLoading(false);
            } catch (error) {
                console.error("Error fetching dashboard data:", error);
                setLoading(false);
            }
        };

        fetchDashboardData();
    }, [userId, role]);

    // Generate sample achievements based on goals data (fallback if API fails)
    const generateSampleAchievements = (goalsData) => {
        const achievements = [];
        
        // Check for goals with progress
        const hasProgressGoals = goalsData.filter(goal => goal.progress > 0);
        if (hasProgressGoals.length > 0) {
            achievements.push({
                id: 'progress-starter',
                title: 'Progress Starter',
                description: 'Started making progress on your goals',
                icon: <FaRegCheckCircle />,
                date: new Date().toLocaleDateString(),
                type: 'progress'
            });
        }
        
        // Check for goals with 50%+ progress
        const halfwayGoals = goalsData.filter(goal => goal.progress >= 50);
        if (halfwayGoals.length > 0) {
            achievements.push({
                id: 'halfway-hero',
                title: 'Halfway Hero',
                description: `Reached 50% on ${halfwayGoals.length} goal${halfwayGoals.length > 1 ? 's' : ''}`,
                icon: <FaChartLine />,
                date: new Date().toLocaleDateString(),
                type: 'progress'
            });
        }
        
        // Check for completed goals
        const completedGoals = goalsData.filter(goal => goal.progress === 100);
        if (completedGoals.length > 0) {
            achievements.push({
                id: 'goal-achiever',
                title: 'Goal Achiever',
                description: `Completed ${completedGoals.length} goal${completedGoals.length > 1 ? 's' : ''}`,
                icon: <FaTrophy />,
                date: new Date().toLocaleDateString(),
                type: 'completion'
            });
        }
        
        // Check for verified goals
        const verifiedGoals = goalsData.filter(goal => goal.verification_status === 'verified');
        if (verifiedGoals.length > 0) {
            achievements.push({
                id: 'verified-victor',
                title: 'Verified Victor',
                description: `Had ${verifiedGoals.length} goal${verifiedGoals.length > 1 ? 's' : ''} verified by your manager`,
                icon: <FaAward />,
                date: new Date().toLocaleDateString(),
                type: 'verification'
            });
        }
        
        return achievements;
    };
    
    // Calculate check-in statistics based on comments (fallback if API fails)
    const calculateCheckInStats = async (goalsData) => {
        try {
            // We'll need to fetch comments for each goal to calculate check-in stats
            const allComments = [];
            
            for (const goal of goalsData) {
                try {
                    const response = await fetchWithAuth(`/api/comments/get-comments/${goal.id}`);
                    const comments = await response.json();
                    allComments.push(...comments);
                } catch (error) {
                    console.error(`Error fetching comments for goal ${goal.id}:`, error);
                }
            }
            
            // Sort comments by date
            allComments.sort((a, b) => new Date(b.date) - new Date(a.date));
            
            // Calculate total check-ins
            const totalCheckIns = allComments.length;
            
            // Get the date of the last check-in
            const lastCheckIn = allComments.length > 0 ? new Date(allComments[0].date) : null;
            
            // Calculate weekly streak
            let weeklyStreak = 0;
            if (allComments.length > 0) {
                // Group comments by week
                const commentsByWeek = {};
                
                allComments.forEach(comment => {
                    const commentDate = new Date(comment.date);
                    const weekStart = new Date(commentDate);
                    weekStart.setDate(weekStart.getDate() - weekStart.getDay()); // Start of week (Sunday)
                    const weekKey = weekStart.toISOString().split('T')[0];
                    
                    if (!commentsByWeek[weekKey]) {
                        commentsByWeek[weekKey] = [];
                    }
                    commentsByWeek[weekKey].push(comment);
                });
                
                // Sort weeks in descending order
                const sortedWeeks = Object.keys(commentsByWeek).sort().reverse();
                
                // Calculate streak
                let currentStreak = 0;
                const today = new Date();
                const currentWeekStart = new Date(today);
                currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay());
                const currentWeekKey = currentWeekStart.toISOString().split('T')[0];
                
                for (let i = 0; i < sortedWeeks.length; i++) {
                    const weekKey = sortedWeeks[i];
                    
                    // If this is the current week, we count it in the streak
                    if (i === 0 && weekKey === currentWeekKey) {
                        currentStreak++;
                        continue;
                    }
                    
                    // For past weeks, check if they're consecutive
                    if (i > 0) {
                        const prevWeekDate = new Date(sortedWeeks[i-1]);
                        const thisWeekDate = new Date(weekKey);
                        const diffDays = Math.round((prevWeekDate - thisWeekDate) / (1000 * 60 * 60 * 24));
                        
                        // If the difference is approximately 7 days (1 week)
                        if (diffDays <= 9 && diffDays >= 5) {
                            currentStreak++;
                        } else {
                            break;
                        }
                    } else {
                        currentStreak++;
                    }
                }
                
                weeklyStreak = currentStreak;
            }
            
            return {
                totalCheckIns,
                weeklyStreak,
                lastCheckIn
            };
            
        } catch (error) {
            console.error("Error calculating check-in stats:", error);
            return {
                totalCheckIns: 0,
                weeklyStreak: 0,
                lastCheckIn: null
            };
        }
    };

    // Calculate overall progress percentage
    const calculateOverallProgress = () => {
        if (goals.length === 0) return 0;
        
        const totalProgress = goals.reduce((sum, goal) => sum + parseInt(goal.progress || 0), 0);
        return Math.round(totalProgress / goals.length);
    };
    
    // Get the appropriate badge based on check-in streak
    const getStreakBadge = () => {
        if (checkInStats.weeklyStreak >= 8) {
            return { name: 'Consistency Champion', icon: <FaTrophy className="badge-icon gold" /> };
        } else if (checkInStats.weeklyStreak >= 4) {
            return { name: 'Regular Reviewer', icon: <FaAward className="badge-icon silver" /> };
        } else if (checkInStats.weeklyStreak >= 2) {
            return { name: 'Check-in Starter', icon: <FaRegStar className="badge-icon bronze" /> };
        }
        return { name: 'No streak yet', icon: <FaRegClock className="badge-icon" /> };
    };
    
    const streakBadge = getStreakBadge();

    return (
        <div className="dashboard-container">
            <div className="dashboard-header-container">
                <div className="dashboard-back-button">
                    <BackButton onClick={() => navigate(-1)} />
                </div>
                <div className="dashboard-header-content">
                    <h1 className="dashboard-title">Dashboard</h1>
                    <p className="dashboard-welcome">Welcome back, {firstName}!</p>
                </div>
            </div>

            {loading ? (
                <div className="dashboard-loading">
                    <div className="dashboard-loading-spinner"></div>
                    <p>Loading your dashboard...</p>
                </div>
            ) : (
                <div className="dashboard-content">
                    <div className="dashboard-main">
                        <div className="dashboard-column dashboard-column-main">
                            {/* Progress Overview Card */}
                            <div className="dashboard-card dashboard-progress-card">
                                <div className="dashboard-card-header">
                                    <h2 className="dashboard-card-title">
                                        <FaChartPie className="dashboard-card-icon" /> 
                                        Progress Overview
                                    </h2>
                                </div>
                                <div className="dashboard-card-content">
                                    <div className="dashboard-progress-overview">
                                        <div className="dashboard-progress-circle-container">
                                            <div className="dashboard-progress-circle">
                                                <svg viewBox="0 0 36 36" className="dashboard-circular-chart">
                                                    <path className="dashboard-circle-bg"
                                                        d="M18 2.0845
                                                        a 15.9155 15.9155 0 0 1 0 31.831
                                                        a 15.9155 15.9155 0 0 1 0 -31.831"
                                                    />
                                                    <path className="dashboard-circle-progress"
                                                        strokeDasharray={`${calculateOverallProgress()}, 100`}
                                                        d="M18 2.0845
                                                        a 15.9155 15.9155 0 0 1 0 31.831
                                                        a 15.9155 15.9155 0 0 1 0 -31.831"
                                                    />
                                                    <text x="18" y="20.35" className="dashboard-percentage">{calculateOverallProgress()}%</text>
                                                </svg>
                                                <div className="dashboard-progress-label">Overall Progress</div>
                                            </div>
                                        </div>
                                        <div className="dashboard-progress-stats-container">
                                            <div className="dashboard-progress-stats">
                                                <div className="dashboard-stat-item">
                                                    <span className="dashboard-stat-value">{goals.length}</span>
                                                    <span className="dashboard-stat-label">Total Goals</span>
                                                </div>
                                                <div className="dashboard-stat-item">
                                                    <span className="dashboard-stat-value">{goals.filter(goal => goal.progress === 100).length}</span>
                                                    <span className="dashboard-stat-label">Completed</span>
                                                </div>
                                                <div className="dashboard-stat-item">
                                                    <span className="dashboard-stat-value">{goals.filter(goal => goal.verification_status === 'verified').length}</span>
                                                    <span className="dashboard-stat-label">Verified</span>
                                                </div>
                                            </div>
                                            <button className="dashboard-action-button" onClick={() => navigate('/ProgressPage')}>
                                                <FaChartLine /> Update Progress
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Upcoming Check-ins Card */}
                            <div className="dashboard-card dashboard-checkins-card">
                                <div className="dashboard-card-header">
                                    <h2 className="dashboard-card-title">
                                        <FaCalendarAlt className="dashboard-card-icon" /> 
                                        Upcoming Check-ins
                                    </h2>
                                </div>
                                <div className="dashboard-card-content">
                                    {upcomingCheckIns.length > 0 ? (
                                        <div className="dashboard-upcoming-checkins">
                                            {upcomingCheckIns.slice(0, 3).map(checkIn => (
                                                <div key={checkIn.id} className="dashboard-checkin-item">
                                                    <div className="dashboard-checkin-icon">
                                                        <FaVideo />
                                                    </div>
                                                    <div className="dashboard-checkin-content">
                                                        <div className="dashboard-checkin-date">
                                                            {new Date(checkIn.scheduledDate).toLocaleString()}
                                                        </div>
                                                        {checkIn.managerNotes && (
                                                            <div className="dashboard-checkin-notes">
                                                                <strong>Agenda:</strong> {checkIn.managerNotes}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                            <button 
                                                className="dashboard-action-button" 
                                                onClick={() => navigate('/ProgressPage')}
                                            >
                                                <FaCalendarCheck /> View All Check-ins
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="dashboard-no-checkins">
                                            <FaRegClock className="dashboard-no-checkins-icon" />
                                            <p>No upcoming check-ins scheduled.</p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Recent Activity Card */}
                            <div className="dashboard-card dashboard-activity-card">
                                <div className="dashboard-card-header">
                                    <h2 className="dashboard-card-title">
                                        <FaBell className="dashboard-card-icon" /> 
                                        Recent Activity
                                    </h2>
                                </div>
                                <div className="dashboard-card-content">
                                    {goals.length > 0 ? (
                                        <div className="dashboard-activity-list">
                                            {goals.slice(0, 3).map(goal => (
                                                <div key={goal.id} className="dashboard-activity-item">
                                                    <div className="dashboard-activity-progress">
                                                        <div className="dashboard-activity-progress-bar" style={{ width: `${goal.progress}%` }}></div>
                                                    </div>
                                                    <div className="dashboard-activity-content">
                                                        <h4 className="dashboard-activity-title">{goal.summary}</h4>
                                                        <div className="dashboard-activity-meta">
                                                            <span className="dashboard-activity-progress-text">{goal.progress}% complete</span>
                                                            {goal.verification_status === 'verified' && (
                                                                <span className="dashboard-activity-verified">
                                                                    <FaClipboardCheck /> Verified
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="dashboard-no-activity">
                                            <FaRegLightbulb className="dashboard-no-activity-icon" />
                                            <p>No recent activity to display.</p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Admin-specific Company Overview Card */}
                            {role === 'Admin' && companyStats && (
                                <div className="dashboard-card dashboard-company-card">
                                    <div className="dashboard-card-header">
                                        <h2 className="dashboard-card-title">
                                            <FaBuilding className="dashboard-card-icon" /> 
                                            Company Overview
                                        </h2>
                                    </div>
                                    <div className="dashboard-card-content">
                                        <div className="dashboard-stats-grid">
                                            <div className="dashboard-stat-card">
                                                <span className="dashboard-stat-value">{companyStats.totalEmployees}</span>
                                                <span className="dashboard-stat-label">Total Employees</span>
                                            </div>
                                            <div className="dashboard-stat-card">
                                                <span className="dashboard-stat-value">{companyStats.totalGoals}</span>
                                                <span className="dashboard-stat-label">Total Goals</span>
                                            </div>
                                            <div className="dashboard-stat-card">
                                                <span className="dashboard-stat-value">{companyStats.completedGoals}</span>
                                                <span className="dashboard-stat-label">Completed Goals</span>
                                            </div>
                                            <div className="dashboard-stat-card">
                                                <span className="dashboard-stat-value">{companyStats.verifiedGoals}</span>
                                                <span className="dashboard-stat-label">Verified Goals</span>
                                            </div>
                                        </div>
                                        <button className="dashboard-action-button" onClick={() => navigate('/EmployeeDirectory')}>
                                            <FaAddressBook /> View Directory
                                        </button>
                                    </div>
                                </div>
                            )}

                            {/* Manager-specific Team Overview Card */}
                            {(role === 'Manager' || role === 'Admin') && teamStats && (
                                <div className="dashboard-card dashboard-team-card">
                                    <div className="dashboard-card-header">
                                        <h2 className="dashboard-card-title">
                                            <FaUsers className="dashboard-card-icon" /> 
                                            Team Overview
                                        </h2>
                                    </div>
                                    <div className="dashboard-card-content">
                                        <div className="dashboard-stats-grid">
                                            <div className="dashboard-stat-card">
                                                <span className="dashboard-stat-value">{teamStats.teamSize}</span>
                                                <span className="dashboard-stat-label">Team Members</span>
                                            </div>
                                            <div className="dashboard-stat-card">
                                                <span className="dashboard-stat-value">{teamStats.teamGoals}</span>
                                                <span className="dashboard-stat-label">Team Goals</span>
                                            </div>
                                            <div className="dashboard-stat-card">
                                                <span className="dashboard-stat-value">{teamStats.teamCompletedGoals}</span>
                                                <span className="dashboard-stat-label">Completed Goals</span>
                                            </div>
                                            <div className="dashboard-stat-card">
                                                <span className="dashboard-stat-value">{teamStats.teamVerifiedGoals}</span>
                                                <span className="dashboard-stat-label">Verified Goals</span>
                                            </div>
                                        </div>
                                        <button className="dashboard-action-button" onClick={() => navigate('/TeamMembers')}>
                                            <FaUsers /> View Team
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="dashboard-column dashboard-column-side">
                            {/* Check-in Streak Card */}
                            <div className="dashboard-card dashboard-streak-card">
                                <div className="dashboard-card-header">
                                    <h2 className="dashboard-card-title">
                                        <FaCalendarCheck className="dashboard-card-icon" /> 
                                        Check-in Streak
                                    </h2>
                                </div>
                                <div className="dashboard-card-content">
                                    <div className="dashboard-streak-container">
                                        <div className="dashboard-streak-badge">
                                            {streakBadge.icon}
                                            <span className="dashboard-streak-badge-name">{streakBadge.name}</span>
                                        </div>
                                        <div className="dashboard-streak-stats">
                                            <div className="dashboard-stat-item">
                                                <span className="dashboard-stat-value">{checkInStats.weeklyStreak}</span>
                                                <span className="dashboard-stat-label">Week Streak</span>
                                            </div>
                                            <div className="dashboard-stat-item">
                                                <span className="dashboard-stat-value">{checkInStats.totalCheckIns}</span>
                                                <span className="dashboard-stat-label">Total Check-ins</span>
                                            </div>
                                        </div>
                                        <div className="dashboard-last-checkin">
                                            <span className="dashboard-last-checkin-label">Last Check-in:</span>
                                            <span className="dashboard-last-checkin-value">
                                                {checkInStats.lastCheckIn ? new Date(checkInStats.lastCheckIn).toLocaleDateString() : 'None'}
                                            </span>
                                        </div>
                                        
                                        {/* Recent Check-ins */}
                                        {recentCheckIns.length > 0 && (
                                            <div className="dashboard-recent-checkins">
                                                <h4>Recent Check-ins</h4>
                                                {recentCheckIns.map(checkIn => (
                                                    <div key={checkIn.id} className="dashboard-recent-checkin-item">
                                                        <div className="dashboard-recent-checkin-date">
                                                            {new Date(checkIn.completedDate).toLocaleDateString()}
                                                        </div>
                                                        {checkIn.managerNotes && (
                                                            <div className="dashboard-recent-checkin-notes">
                                                                {checkIn.managerNotes.length > 50 
                                                                    ? `${checkIn.managerNotes.substring(0, 50)}...` 
                                                                    : checkIn.managerNotes}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Achievements Card */}
                            <div className="dashboard-card dashboard-achievements-card">
                                <div className="dashboard-card-header">
                                    <h2 className="dashboard-card-title">
                                        <FaAward className="dashboard-card-icon" /> 
                                        Your Achievements
                                    </h2>
                                </div>
                                <div className="dashboard-card-content">
                                    {achievements.length > 0 ? (
                                        <div className="dashboard-achievements-list">
                                            {achievements.map(achievement => (
                                                <div key={achievement.id} className={`dashboard-achievement-item ${achievement.type}`}>
                                                    <div className="dashboard-achievement-icon">
                                                        {achievement.icon}
                                                    </div>
                                                    <div className="dashboard-achievement-content">
                                                        <h4 className="dashboard-achievement-title">{achievement.title}</h4>
                                                        <p className="dashboard-achievement-description">{achievement.description}</p>
                                                        <span className="dashboard-achievement-date">Earned on {achievement.date}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="dashboard-no-achievements">
                                            <FaRegLightbulb className="dashboard-no-achievements-icon" />
                                            <p>Start making progress on your goals to earn achievements!</p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Quick Links Card */}
                            <div className="dashboard-card dashboard-links-card">
                                <div className="dashboard-card-header">
                                    <h2 className="dashboard-card-title">
                                        <FaRegLightbulb className="dashboard-card-icon" /> 
                                        Quick Links
                                    </h2>
                                </div>
                                <div className="dashboard-card-content">
                                    <div className="dashboard-quick-links">
                                        <button className="dashboard-quick-link-button" onClick={() => navigate('/ProgressPage')}>
                                            <FaChartLine className="dashboard-quick-link-icon" />
                                            <span>Update Progress</span>
                                        </button>
                                        <button className="dashboard-quick-link-button" onClick={() => navigate('/TeamMembers')}>
                                            <FaUsers className="dashboard-quick-link-icon" />
                                            <span>Team Dashboard</span>
                                        </button>
                                        <button className="dashboard-quick-link-button" onClick={() => navigate('/EmployeeDirectory')}>
                                            <FaAddressBook className="dashboard-quick-link-icon" />
                                            <span>Employee Directory</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Dashboard;