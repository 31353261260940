import React from 'react';
import './Styles/Support.css';
import BackButton from '../../Components/BackButton/BackButton';
import { useNavigate } from 'react-router-dom';

const SupportPage = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <div className="page-container">
            <div className="back-button-container">
                <BackButton onClick={handleBackClick} />
            </div>
            <div className="page-content">
                <h1 className="page-header support-title">Support Center</h1>
                
                <div className="support-container">
                    <div className="support-section">
                        <h2 className="support-subtitle">Account and Billing Questions</h2>
                        <p className="support-text">
                            For any account or billing related inquiries, please email our dedicated billing team at
                            <a href="mailto:billing@astellyst.com" className="support-link"> billing@astellyst.com</a>
                        </p>
                    </div>
                    
                    <div className="support-section">
                        <h2 className="support-subtitle">Technical Support</h2>
                        <p className="support-text">
                            For all technical support questions, please contact our support team at
                            <a href="mailto:support@astellyst.com" className="support-link"> support@astellyst.com</a>
                        </p>
                    </div>
                    
                    <div className="support-section">
                        <h2 className="support-subtitle">Response Times</h2>
                        <p className="support-text">
                            We strive to respond to all inquiries within 24 business hours.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupportPage;
