import React, { useEffect, useState } from 'react';
import TeamAIButton from '../../../Components/AIButton/TeamAIButton';
import { FaArrowLeft, FaArrowRight, FaFloppyDisk, FaPlus, FaPenToSquare, FaTrash, FaCheck, FaWandMagicSparkles } from 'react-icons/fa6';
import fetchWithAuth from '../../../Util/fetchWithAuth';

function ManagerStep3({ teamGoals = [], setTeamGoals, prevStep, nextStep, saveForLater, textareaRef, userId }) {
    const [goals, setGoals] = useState(teamGoals);

    useEffect(() => {
        setGoals(teamGoals);
    }, [teamGoals]);

    const addGeneratedGoal = (generatedGoal) => {
        if (goals.length >= 5) {
            alert('You can only have up to 5 goals.');
            return;
        }
        const newTeamGoals = [...goals, { id: null, goal_text: generatedGoal, confirmed: false, userID: userId }];
        setGoals(newTeamGoals);
        setTeamGoals(newTeamGoals);
    };

    const addManualGoal = (goalText) => {
        if (goals.length >= 5) {
            alert('You can only have up to 5 goals.');
            return;
        }
        const newTeamGoals = [...goals, { id: null, goal_text: goalText, confirmed: false, userID: userId }];
        setGoals(newTeamGoals);
        setTeamGoals(newTeamGoals);
    };

    const handleTeamGoalChange = (index, value) => {
        const newTeamGoals = goals.map((goal, i) => (i === index ? { ...goal, goal_text: value } : goal));
        console.log('Updated goal_text:', newTeamGoals[index].goal_text);
        setGoals(newTeamGoals);
        setTeamGoals(newTeamGoals);
    };

    const toggleTeamGoalConfirmation = (index) => {
        const newTeamGoals = goals.map((goal, i) => (i === index ? { ...goal, confirmed: !goal.confirmed } : goal));
        setGoals(newTeamGoals);
        setTeamGoals(newTeamGoals);
    };

    const deleteTeamGoal = async (index) => {
        const goalToDelete = goals[index];
        if (goalToDelete.id) {
            try {
                const response = await fetchWithAuth(`/api/team-goals/delete-goal/${goalToDelete.id}`, {
                    method: 'DELETE',
                });

                if (!response.ok) {
                    throw new Error('Failed to delete goal');
                }

                console.log('Goal deleted successfully');
            } catch (error) {
                console.error('Error deleting goal:', error);
                return;
            }
        }

        const newTeamGoals = goals.filter((_, i) => i !== index);
        setGoals(newTeamGoals);
        setTeamGoals(newTeamGoals);
    };

    return (
        <div className='manager-goal-set-container'>
            <h2 className='manager-goal-set-title'>Set Your Team Goals</h2>
            
            <div className='manager-team-context-form-section'>
                <div className='manager-goal-actions'>
                    <TeamAIButton 
                        userId={userId} 
                        onNewGoal={addGeneratedGoal}
                    />
                    {goals.length < 5 && (
                        <button 
                            onClick={() => addManualGoal('')} 
                            className='manager-add-goal-button'
                        >
                            <FaPlus /> Add Manual Goal
                        </button>
                    )}
                </div>

                <div className='manager-goals-container'>
                    {goals.map((goal, index) => (
                        <div key={index} className='manager-goal-item'>
                            <div className='manager-goal-number'>{index + 1}</div>
                            <div className='manager-goal-content'>
                                <textarea
                                    className='manager-goal-input'
                                    placeholder='Enter a team goal here'
                                    value={goal.goal_text}
                                    onChange={(e) => handleTeamGoalChange(index, e.target.value)}
                                    ref={textareaRef}
                                    readOnly={goal.confirmed}
                                />
                                <div className="manager-goal-actions">
                                    <button 
                                        onClick={() => toggleTeamGoalConfirmation(index)} 
                                        className={`manager-action-button ${goal.confirmed ? 'edit' : 'confirm'}`}
                                    >
                                        {goal.confirmed ? <FaPenToSquare /> : <FaCheck />}
                                        {goal.confirmed ? 'Edit' : 'Confirm'}
                                    </button>
                                    <button 
                                        onClick={() => deleteTeamGoal(index)} 
                                        className='manager-action-button delete'
                                    >
                                        <FaTrash /> Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='manager-goal-set-button-container'>
                <button onClick={prevStep} className='manager-goal-set-link'>
                    <FaArrowLeft /> Back
                </button>
                <button onClick={saveForLater} className='manager-goal-set-link'>
                    <FaFloppyDisk /> Save for Later
                </button>
                <button onClick={nextStep} className='manager-goal-set-link'>
                    Next <FaArrowRight />
                </button>
            </div>
        </div>
    );
}

export default ManagerStep3;