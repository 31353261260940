import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { useEmployeeContext } from '../../../Contexts/employeeContext';
import { 
    FaArrowLeft, 
    FaFloppyDisk, 
    FaPaperPlane, 
    FaPenToSquare, 
    FaCheck 
} from 'react-icons/fa6';
import fetchWithAuth from '../../../Util/fetchWithAuth';
import { scrollToTop } from '../../../Util/scrollHelper';

function EmployeeStep4({ employeeGoals, prevStep, saveForLater, setEmployeeGoals, submitData, userRole }) {
    const [isEditingGoals, setIsEditingGoals] = useState(false);
    const [editedGoals, setEditedGoals] = useState(employeeGoals.map(goal => goal.goal_text || ''));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { employeeContext } = useEmployeeContext();
    const navigate = useNavigate();

    useEffect(() => {
        setEditedGoals(employeeGoals.map(goal => goal.goal_text || ''));
    }, [employeeGoals]);

    const toggleEditGoals = () => {
        if (isEditingGoals) {
            const nonEmptyGoals = editedGoals
                .filter(goalText => goalText.trim() !== '')
                .map((goalText, index) => ({
                    ...employeeGoals[index],
                    goal_text: goalText,
                    confirmed: true
                }));
            setEmployeeGoals(nonEmptyGoals);
        }
        setIsEditingGoals(!isEditingGoals);
    };

    const handleGoalChange = (index, value) => {
        const newGoals = [...editedGoals];
        newGoals[index] = value;
        setEditedGoals(newGoals);
    };

    const handleSubmit = async () => {
        const nonEmptyGoals = editedGoals
            .filter(goalText => goalText && goalText.trim() !== '')
            .map((goalText, index) => ({
                ...employeeGoals[index],
                goal_text: goalText,
                confirmed: true
            }));

        if (nonEmptyGoals.length === 0) {
            alert('You must submit at least one goal to continue');
            return;
        }

        const hasNullGoalText = nonEmptyGoals.some(goal => !goal.goal_text || goal.goal_text.trim() === '');
        if (hasNullGoalText) {
            alert('All goals must have text.');
            return;
        }

        setIsSubmitting(true);

        try {
            const summarizedGoals = await Promise.all(nonEmptyGoals.map(async (goal) => {
                const response = await fetchWithAuth('/api/summarization/summarize-goal', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ text: goal.goal_text })
                });
                const { summary } = await response.json();
                return {
                    ...goal,
                    summary: summary || goal.goal_text.substring(0, 100) + '...'
                };
            }));

            await submitData(summarizedGoals);
            alert('Goals submitted successfully!');

            let portalPath = '/employeeportal';
            if (userRole === 'Admin') {
                portalPath = '/adminportal';
            } else if (userRole === 'Manager') {
                portalPath = '/managerportal';
            }
            scrollToTop();
            navigate(portalPath);
        } catch (error) {
            console.error('Error summarizing goals:', error);
            alert('There was an error submitting your goals. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isSubmitting) {
        return (
            <div className='employee-goal-set-container'>
                <div className='employee-loading-overlay'>
                    <div className='employee-loading-content'>
                        <PulseLoader
                            color="#6908C9"
                            size={15}
                            margin={5}
                            speedMultiplier={0.8}
                        />
                        <h2 className='employee-loading-text'>Summarizing and submitting goals...</h2>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="employee-page-container">
            <div className="employee-page-content">
                <div className='employee-goal-set-container'>
                    <h2 className='employee-goal-set-title'>Review Your Personal Goals</h2>
                    <p className='employee-goal-set-description'>
                        Review and confirm your goals before final submission
                    </p>

                    <div className='employee-goal-set-review-container'>
                        <div className='employee-goals-review-container'>
                            {isEditingGoals ? (
                                <div className='employee-goals-edit-list'>
                                    {editedGoals.map((goalText, index) => (
                                        <div key={index} className='employee-goal-review-item editing'>
                                            <div className='employee-goal-number'>{index + 1}</div>
                                            <div className='employee-goal-content'>
                                                <textarea
                                                    className='employee-goal-set-input'
                                                    value={goalText}
                                                    onChange={(e) => handleGoalChange(index, e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    <button onClick={toggleEditGoals} className='employee-edit-done-button'>
                                        <FaCheck /> Done Editing
                                    </button>
                                </div>
                            ) : (
                                <div className='employee-goals-display-list'>
                                    {employeeGoals.map((goal, index) => (
                                        <div key={index} className='employee-goal-review-item'>
                                            <div className='employee-goal-number'>{index + 1}</div>
                                            <div className='employee-goal-review-content'>
                                                {goal.goal_text}
                                            </div>
                                        </div>
                                    ))}
                                    <button onClick={toggleEditGoals} className='employee-edit-goals-button'>
                                        <FaPenToSquare /> Edit Goals
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='employee-goal-set-button-container'>
                        <button onClick={prevStep} className='employee-goal-set-link'>
                            <FaArrowLeft /> Back
                        </button>
                        <button onClick={saveForLater} className='employee-goal-set-link'>
                            <FaFloppyDisk /> Save for Later
                        </button>
                        <button 
                            onClick={handleSubmit} 
                            className='employee-goal-set-submit-link'
                            disabled={employeeGoals.length === 0}
                        >
                            <FaPaperPlane /> Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmployeeStep4; 