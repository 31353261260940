import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './authContext';
import fetchWithAuth from '../Util/fetchWithAuth';

const TeamContext = createContext();

export function TeamContextProvider({ children }) {
    const [teamContext, setTeamContext] = useState({
        teamSize: '',
        teamFocus: '',
        workStyle: '',
        challenges: [],
        challengeDetails: '',
        strengths: [],
        opportunities: '',
        priorities: '',
        metrics: '',
        developmentNeeds: '',
        currentSection: 0
    });
    const { userId } = useAuth();

    useEffect(() => {
        let isMounted = true;
        
        const fetchTeamContext = async () => {
            if (!userId) return;
            
            try {
                console.log(`Fetching team context for user ID: ${userId}`);
                const response = await fetchWithAuth(`/api/team-context/${userId}`);
                
                // Even if we get an error response, we'll handle it and set default values
                let data;
                try {
                    data = await response.json();
                    console.log('Received team context data:', data);
                } catch (parseError) {
                    console.error('Error parsing team context response:', parseError);
                    data = null;
                }
                
                if (isMounted) {
                    // If data is null or undefined, use empty default values
                    setTeamContext({
                        teamSize: data?.team_size || '',
                        teamFocus: data?.team_focus || '',
                        workStyle: data?.work_style || '',
                        challenges: Array.isArray(data?.challenges) ? data.challenges : [],
                        challengeDetails: data?.challenge_details || '',
                        strengths: Array.isArray(data?.strengths) ? data.strengths : [],
                        opportunities: data?.opportunities || '',
                        priorities: data?.priorities || '',
                        metrics: data?.metrics || '',
                        developmentNeeds: data?.development_needs || '',
                        currentSection: data?.current_section || 0
                    });
                }
            } catch (error) {
                console.error('Error in team context fetch:', error);
                
                // Set default empty values on error
                if (isMounted) {
                    setTeamContext({
                        teamSize: '',
                        teamFocus: '',
                        workStyle: '',
                        challenges: [],
                        challengeDetails: '',
                        strengths: [],
                        opportunities: '',
                        priorities: '',
                        metrics: '',
                        developmentNeeds: '',
                        currentSection: 0
                    });
                }
            }
        };
        
        fetchTeamContext();
        
        return () => {
            isMounted = false;
        };
    }, [userId]);

    const saveTeamContext = async (contextData) => {
        try {
            console.log('Starting saveTeamContext with data:', contextData);
            
            // Ensure arrays are properly formatted
            const formattedData = {
                managerId: userId, // Explicitly include managerId
                teamSize: contextData.teamSize || '',
                teamFocus: contextData.teamFocus || '',
                workStyle: contextData.workStyle || '',
                challenges: Array.isArray(contextData.challenges) ? contextData.challenges : [],
                challengeDetails: contextData.challengeDetails || '',
                strengths: Array.isArray(contextData.strengths) ? contextData.strengths : 
                          (contextData.strengths ? [contextData.strengths] : []),
                opportunities: contextData.opportunities || '',
                priorities: contextData.priorities || '',
                metrics: contextData.metrics || '',
                developmentNeeds: contextData.developmentNeeds || ''
            };

            console.log('Formatted request data:', formattedData);

            const response = await fetchWithAuth('/api/team-context', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formattedData),
            });
            
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Server response:', {
                    status: response.status,
                    statusText: response.statusText,
                    body: errorText
                });
                throw new Error(`Failed to save team context: ${errorText}`);
            }
            
            const savedData = await response.json();
            console.log('Successfully saved team context:', savedData);
            setTeamContext(savedData);
            return savedData;
        } catch (error) {
            console.error('Error in saveTeamContext:', error);
            throw error;
        }
    };

    return (
        <TeamContext.Provider value={{ 
            teamContext, 
            setTeamContext,
            saveTeamContext 
        }}>
            {children}
        </TeamContext.Provider>
    );
}

export function useTeamContext() {
    const context = useContext(TeamContext);
    if (!context) {
        throw new Error('useTeamContext must be used within a TeamContextProvider');
    }
    return context;
}
