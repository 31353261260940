import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../../Components/BackButton/BackButton';
import { 
    FaArrowRight, 
    FaArrowLeft,
    FaLightbulb
} from 'react-icons/fa6';
import { scrollToTop } from '../../../Util/scrollHelper';

function ManagerStep1({ companyMission, companyGoals, nextStep, userRole }) {
    const navigate = useNavigate();
    const [currentSection, setCurrentSection] = useState(0);

    const handleBackClick = () => {
        let portalPath = '/employeeportal';
        if (userRole === 'Admin') {
            portalPath = '/adminportal?tab=set-team-goals';
        } else if (userRole === 'Manager') {
            portalPath = '/managerportal?tab=set-goals';
        }
        scrollToTop();
        navigate(`${portalPath}`);
    };

    const goToNextSection = () => {
        setCurrentSection(prev => prev + 1);
        scrollToTop();
    };

    const goToPrevSection = () => {
        setCurrentSection(prev => prev - 1);
        scrollToTop();
    };

    return (
        <div className="page-container">
            <div className="back-button-container">
                <BackButton onClick={handleBackClick} />
            </div>
            <div className="page-content">
                <h2 className='page-header manager-goal-set-title'>Review Company Mission and Goals</h2>
                <p className='manager-goal-set-subtitle'>
                    Review these carefully as they will help guide your team goal setting process
                </p>
                
                <div className="manager-step-progress">
                    <div className="manager-step-progress-bar">
                        <div 
                            className="manager-step-progress-fill"
                            style={{ width: `${((currentSection + 1) / 2) * 100}%` }}
                        />
                    </div>
                    <div className="manager-step-progress-text">
                        Step {currentSection + 1} of 2: {
                            currentSection === 0 ? 'Company Mission' : 'Company Goals'
                        }
                    </div>
                </div>
                
                <div className='manager-team-context-form-section'>
                    {/* Company Mission Section */}
                    {currentSection === 0 && (
                        <>
                            <h4 className='manager-team-context-form-section-title'>
                                Company Mission
                            </h4>
                            <div className='manager-mission-card'>
                                <p className='manager-goal-review-mission'>{companyMission}</p>
                            </div>
                        </>
                    )}

                    {/* Company Goals Section */}
                    {currentSection === 1 && (
                        <>
                            <h4 className='manager-team-context-form-section-title'>
                                Company Goals
                            </h4>
                            <div className='manager-goals-grid'>
                                {companyGoals.map((goal, index) => (
                                    <div key={index} className='manager-goal-card'>
                                        <span className='manager-goal-number'>{index + 1}</span>
                                        <p>{goal.text}</p>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>

                <div className='manager-goal-set-button-container'>
                    {currentSection > 0 && (
                        <button className='manager-goal-set-link' onClick={goToPrevSection}>
                            <FaArrowLeft /> Previous
                        </button>
                    )}
                    
                    {currentSection < 1 ? (
                        <button className='manager-goal-set-link' onClick={goToNextSection}>
                            Next <FaArrowRight />
                        </button>
                    ) : (
                        <button className='manager-goal-set-link' onClick={nextStep}>
                            Continue <FaArrowRight />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ManagerStep1;
