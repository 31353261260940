import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './authContext';
import fetchWithAuth from '../Util/fetchWithAuth';

const GoalSettingContext = createContext();

export const GoalSettingProvider = ({ children }) => {
    const [isGoalSettingActive, setIsGoalSettingActive] = useState(false);
    const { companyId } = useAuth();

    const fetchGoalSettingStatus = async () => {
        if (companyId) {
            try {
                const response = await fetchWithAuth(`/api/goal-setting-period/status/${companyId}`);
                const data = await response.json();
                setIsGoalSettingActive(data.is_active);
            } catch (error) {
                console.error('Error fetching goal setting period status:', error);
            }
        }
    };

    useEffect(() => {
        fetchGoalSettingStatus();
    }, [companyId]);

    const updateGoalSettingStatus = (newStatus) => {
        setIsGoalSettingActive(newStatus);
    };

    return (
        <GoalSettingContext.Provider value={{ 
            isGoalSettingActive, 
            updateGoalSettingStatus,
            refreshStatus: fetchGoalSettingStatus 
        }}>
            {children}
        </GoalSettingContext.Provider>
    );
};

export const useGoalSetting = () => useContext(GoalSettingContext);
