import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../Contexts/authContext';
import BackButton from '../../../Components/BackButton/BackButton';
import '../Styles/AchievementsPage.css';
import fetchWithAuth from '../../../Util/fetchWithAuth';
import { 
    FaCheckCircle, FaTrophy, FaCalendarCheck, FaChartLine, 
    FaRegCheckCircle, FaAward, FaUsers, FaRegStar, FaFireAlt
} from 'react-icons/fa';

const AchievementsPage = () => {
    const [achievements, setAchievements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState({
        total: 0,
        goal: 0,
        streak: 0,
        team: 0,
        company: 0
    });
    
    const navigate = useNavigate();
    const { userId } = useAuth();
    
    // Fetch achievements from API
    useEffect(() => {
        if (userId) {
            setLoading(true);
            
            // Mock achievement data for development/demonstration
            const mockAchievements = [
                {
                    id: 1,
                    achievement_type: 'progress_starter',
                    title: 'Progress Starter',
                    description: 'Started making progress on your goals',
                    date_earned: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString(),
                    icon_name: 'FaRegCheckCircle'
                },
                {
                    id: 2,
                    achievement_type: 'halfway_hero',
                    title: 'Halfway Hero',
                    description: 'Reached 50% on at least one goal',
                    date_earned: new Date(Date.now() - 20 * 24 * 60 * 60 * 1000).toISOString(),
                    icon_name: 'FaChartLine'
                },
                {
                    id: 3,
                    achievement_type: 'checkin_starter',
                    title: 'Check-in Starter',
                    description: 'Maintained a 2-week check-in streak',
                    date_earned: new Date(Date.now() - 10 * 24 * 60 * 60 * 1000).toISOString(),
                    icon_name: 'FaCalendarCheck'
                }
            ];
            
            fetchWithAuth(`/api/achievements/get-achievements/${userId}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`API returned status ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    // Make sure data is an array before processing
                    if (Array.isArray(data) && data.length > 0) {
                        setAchievements(data);
                        
                        // Calculate achievement stats
                        const counts = {
                            total: data.length,
                            goal: data.filter(a => 
                                a.achievement_type === 'goal_achiever' || 
                                a.achievement_type === 'goal_completion' ||
                                a.achievement_type === 'verified_victor' ||
                                a.achievement_type === 'halfway_hero' ||
                                a.achievement_type === 'progress_starter'
                            ).length,
                            streak: data.filter(a => 
                                a.achievement_type === 'checkin_starter' || 
                                a.achievement_type === 'regular_reviewer' ||
                                a.achievement_type === 'consistency_champion' ||
                                a.achievement_type === 'check_in_streak'
                            ).length,
                            team: data.filter(a => 
                                a.achievement_type === 'team_player' || 
                                a.achievement_type === 'team_contributor'
                            ).length,
                            company: data.filter(a => 
                                a.achievement_type === 'company_goal_contributor'
                            ).length
                        };
                        
                        setStats(counts);
                    } else {
                        // Use mock data for development/demonstration
                        console.log('Using mock achievement data for development');
                        setAchievements(mockAchievements);
                        
                        // Set mock stats
                        setStats({
                            total: mockAchievements.length,
                            goal: 2,  // progress_starter and halfway_hero
                            streak: 1, // checkin_starter
                            team: 0,
                            company: 0
                        });
                    }
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching achievements:', error);
                    // Use mock data when the API fails
                    console.log('Using mock achievement data due to API error');
                    setAchievements(mockAchievements);
                    
                    // Set mock stats
                    setStats({
                        total: mockAchievements.length,
                        goal: 2,  // progress_starter and halfway_hero
                        streak: 1, // checkin_starter
                        team: 0,
                        company: 0
                    });
                    setLoading(false);
                });
                
            // Simplified mock check-in stats
            const mockCheckInStats = {
                totalCheckIns: 12,
                weeklyStreak: 3,
                lastCheckIn: new Date().toISOString()
            };
            
            // Also fetch check-in stats for streak information
            fetchWithAuth(`/api/achievements/get-checkin-stats/${userId}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`API returned status ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Check-in stats:', data);
                })
                .catch(error => {
                    console.error('Error fetching check-in stats:', error);
                    console.log('Using mock check-in stats:', mockCheckInStats);
                });
        }
    }, [userId]);
    
    const handleBackLinkClick = () => {
        navigate(`/EmployeePortal?tab=achievements`);
    };
    
    // Map achievement types to icons
    const getAchievementIcon = (type) => {
        switch (type) {
            case 'goal_completion':
            case 'goal_achiever':
                return <FaTrophy className="achievement-icon-complete" />;
            case 'check_in_streak':
            case 'checkin_starter':
            case 'regular_reviewer':
            case 'consistency_champion':
                return <FaCalendarCheck className="achievement-icon-streak" />;
            case 'progress_milestone':
            case 'halfway_hero':
                return <FaChartLine className="achievement-icon-progress" />;
            case 'progress_starter':
                return <FaRegCheckCircle className="achievement-icon-progress" />;
            case 'verified_victor':
                return <FaAward className="achievement-icon-complete" />;
            case 'team_player':
            case 'team_contributor':
                return <FaUsers className="achievement-icon-team" />;
            case 'company_goal_contributor':
                return <FaFireAlt className="achievement-icon-company" />;
            default:
                return <FaRegStar className="achievement-icon-default" />;
        }
    };
    
    // List of all possible achievements (including locked ones)
    const allPossibleAchievements = [
        // Goal achievements
        {
            category: 'goals',
            title: 'Progress Starter',
            description: 'Started making progress on your goals',
            type: 'progress_starter',
            requirements: 'Update progress on any goal'
        },
        {
            category: 'goals',
            title: 'Halfway Hero',
            description: 'Reached 50% on at least one goal',
            type: 'halfway_hero',
            requirements: 'Get to 50% on any goal'
        },
        {
            category: 'goals',
            title: 'Goal Achiever',
            description: 'Completed at least one goal',
            type: 'goal_achiever',
            requirements: 'Complete a goal (100%)'
        },
        {
            category: 'goals',
            title: 'Verified Victor',
            description: 'Had a goal verified by your manager',
            type: 'verified_victor',
            requirements: 'Get a completed goal verified'
        },
        
        // Check-in achievements
        {
            category: 'check-ins',
            title: 'Check-in Starter',
            description: 'Maintained a 2-week check-in streak',
            type: 'checkin_starter',
            requirements: 'Update progress weekly for 2 weeks'
        },
        {
            category: 'check-ins',
            title: 'Regular Reviewer',
            description: 'Maintained a 4-week check-in streak',
            type: 'regular_reviewer',
            requirements: 'Update progress weekly for 4 weeks'
        },
        {
            category: 'check-ins',
            title: 'Consistency Champion',
            description: 'Maintained an 8-week check-in streak',
            type: 'consistency_champion',
            requirements: 'Update progress weekly for 8 weeks'
        },
        
        // Team achievements
        {
            category: 'team',
            title: 'Team Player',
            description: 'Contributed to a team goal',
            type: 'team_player',
            requirements: 'Update progress on a team goal'
        },
        {
            category: 'team',
            title: 'Team Contributor',
            description: 'Contributed to the completion of a team goal',
            type: 'team_contributor',
            requirements: 'Be part of a team that completes a goal'
        },
        
        // Company achievements
        {
            category: 'company',
            title: 'Company Goal Contributor',
            description: 'Contributed to a company-wide goal',
            type: 'company_goal_contributor',
            requirements: 'Link your personal goal to a company goal'
        }
    ];
    
    // Group all possible achievements by category
    const groupedAchievements = {
        goals: allPossibleAchievements.filter(a => a.category === 'goals'),
        'check-ins': allPossibleAchievements.filter(a => a.category === 'check-ins'),
        team: allPossibleAchievements.filter(a => a.category === 'team'),
        company: allPossibleAchievements.filter(a => a.category === 'company')
    };
    
    // Function to check if an achievement is earned
    const isAchievementEarned = (type) => {
        // Guard against achievements not being an array
        if (!Array.isArray(achievements)) return false;
        return achievements.some(achievement => achievement.achievement_type === type);
    };
    
    return (
        <div className="achievements-page-container">
            <div className="back-button-container">
                <BackButton onClick={handleBackLinkClick} />
            </div>
            
            <div className="achievements-page-content">
                <h1 className="achievements-page-header">Your Achievements</h1>
                
                {loading ? (
                    <div className="achievements-loading">Loading achievements...</div>
                ) : (
                    <>
                        <div className="achievements-stats-container">
                            <div className="achievement-stat-card">
                                <span className="achievement-stat-value">{stats.total}</span>
                                <span className="achievement-stat-label">Total Achievements</span>
                            </div>
                            <div className="achievement-stat-card">
                                <span className="achievement-stat-value">{stats.goal}</span>
                                <span className="achievement-stat-label">Goal Achievements</span>
                            </div>
                            <div className="achievement-stat-card">
                                <span className="achievement-stat-value">{stats.streak}</span>
                                <span className="achievement-stat-label">Streak Achievements</span>
                            </div>
                            <div className="achievement-stat-card">
                                <span className="achievement-stat-value">{stats.team + stats.company}</span>
                                <span className="achievement-stat-label">Team & Company</span>
                            </div>
                        </div>
                        
                        <div className="achievements-content">
                            {/* Goals Achievements */}
                            <div className="achievement-category">
                                <h2 className="achievement-category-title">
                                    <FaTrophy /> Goal Achievements
                                </h2>
                                <div className="achievements-grid">
                                    {groupedAchievements.goals.map((achievement) => {
                                        const earned = isAchievementEarned(achievement.type);
                                        const earnedAchievement = earned ? 
                                            achievements.find(a => a.achievement_type === achievement.type) : null;
                                        
                                        return (
                                            <div 
                                                key={achievement.type} 
                                                className={`achievement-card ${!earned ? 'locked-achievement' : ''}`}
                                            >
                                                <div className="achievement-icon-container">
                                                    {getAchievementIcon(achievement.type)}
                                                </div>
                                                <div className="achievement-card-content">
                                                    <h3 className="achievement-title">{achievement.title}</h3>
                                                    <p className="achievement-description">{achievement.description}</p>
                                                    {earned ? (
                                                        <p className="achievement-date">
                                                            Earned on: {earnedAchievement && earnedAchievement.date_earned ? 
                                                                new Date(earnedAchievement.date_earned).toLocaleDateString() : 'Unknown date'}
                                                        </p>
                                                    ) : (
                                                        <p className="achievement-requirements">
                                                            {achievement.requirements}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            
                            {/* Check-in Achievements */}
                            <div className="achievement-category">
                                <h2 className="achievement-category-title">
                                    <FaCalendarCheck /> Check-in Achievements
                                </h2>
                                <div className="achievements-grid">
                                    {groupedAchievements['check-ins'].map((achievement) => {
                                        const earned = isAchievementEarned(achievement.type);
                                        const earnedAchievement = earned ? 
                                            achievements.find(a => a.achievement_type === achievement.type) : null;
                                        
                                        return (
                                            <div 
                                                key={achievement.type} 
                                                className={`achievement-card ${!earned ? 'locked-achievement' : ''}`}
                                            >
                                                <div className="achievement-icon-container">
                                                    {getAchievementIcon(achievement.type)}
                                                </div>
                                                <div className="achievement-card-content">
                                                    <h3 className="achievement-title">{achievement.title}</h3>
                                                    <p className="achievement-description">{achievement.description}</p>
                                                    {earned ? (
                                                        <p className="achievement-date">
                                                            Earned on: {earnedAchievement && earnedAchievement.date_earned ? 
                                                                new Date(earnedAchievement.date_earned).toLocaleDateString() : 'Unknown date'}
                                                        </p>
                                                    ) : (
                                                        <p className="achievement-requirements">
                                                            {achievement.requirements}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            
                            {/* Team Achievements */}
                            <div className="achievement-category">
                                <h2 className="achievement-category-title">
                                    <FaUsers /> Team Achievements
                                </h2>
                                <div className="achievements-grid">
                                    {groupedAchievements.team.map((achievement) => {
                                        const earned = isAchievementEarned(achievement.type);
                                        const earnedAchievement = earned ? 
                                            achievements.find(a => a.achievement_type === achievement.type) : null;
                                        
                                        return (
                                            <div 
                                                key={achievement.type} 
                                                className={`achievement-card ${!earned ? 'locked-achievement' : ''}`}
                                            >
                                                <div className="achievement-icon-container">
                                                    {getAchievementIcon(achievement.type)}
                                                </div>
                                                <div className="achievement-card-content">
                                                    <h3 className="achievement-title">{achievement.title}</h3>
                                                    <p className="achievement-description">{achievement.description}</p>
                                                    {earned ? (
                                                        <p className="achievement-date">
                                                            Earned on: {earnedAchievement && earnedAchievement.date_earned ? 
                                                                new Date(earnedAchievement.date_earned).toLocaleDateString() : 'Unknown date'}
                                                        </p>
                                                    ) : (
                                                        <p className="achievement-requirements">
                                                            {achievement.requirements}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            
                            {/* Company Achievements */}
                            <div className="achievement-category">
                                <h2 className="achievement-category-title">
                                    <FaFireAlt /> Company Achievements
                                </h2>
                                <div className="achievements-grid">
                                    {groupedAchievements.company.map((achievement) => {
                                        const earned = isAchievementEarned(achievement.type);
                                        const earnedAchievement = earned ? 
                                            achievements.find(a => a.achievement_type === achievement.type) : null;
                                        
                                        return (
                                            <div 
                                                key={achievement.type} 
                                                className={`achievement-card ${!earned ? 'locked-achievement' : ''}`}
                                            >
                                                <div className="achievement-icon-container">
                                                    {getAchievementIcon(achievement.type)}
                                                </div>
                                                <div className="achievement-card-content">
                                                    <h3 className="achievement-title">{achievement.title}</h3>
                                                    <p className="achievement-description">{achievement.description}</p>
                                                    {earned ? (
                                                        <p className="achievement-date">
                                                            Earned on: {earnedAchievement && earnedAchievement.date_earned ? 
                                                                new Date(earnedAchievement.date_earned).toLocaleDateString() : 'Unknown date'}
                                                        </p>
                                                    ) : (
                                                        <p className="achievement-requirements">
                                                            {achievement.requirements}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default AchievementsPage; 