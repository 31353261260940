import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { 
    FaArrowLeft, 
    FaFloppyDisk, 
    FaPaperPlane, 
    FaPenToSquare,
    FaCheck,
} from 'react-icons/fa6';
import fetchWithAuth from '../../../Util/fetchWithAuth';
import { scrollToTop } from '../../../Util/scrollHelper';

function ManagerStep4({ teamGoals, prevStep, saveForLater, setTeamGoals, submitData, userRole }) {
    const [isEditingGoals, setIsEditingGoals] = useState(false);
    const [editedGoals, setEditedGoals] = useState(teamGoals.map(goal => goal.goal_text || ''));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setEditedGoals(teamGoals.map(goal => goal.goal_text || ''));
    }, [teamGoals]);

    const toggleEditGoals = () => {
        if (isEditingGoals) {
            const nonEmptyGoals = editedGoals
                .filter(goalText => goalText.trim() !== '')
                .map((goalText, index) => ({
                    ...teamGoals[index],
                    goal_text: goalText,
                    confirmed: true
                }));
            setTeamGoals(nonEmptyGoals);
        }
        setIsEditingGoals(!isEditingGoals);
    };

    const handleGoalChange = (index, value) => {
        const newGoals = [...editedGoals];
        newGoals[index] = value;
        setEditedGoals(newGoals);
    };

    const handleSubmit = async () => {
        const nonEmptyGoals = editedGoals
            .filter(goalText => goalText && goalText.trim() !== '')
            .map((goalText, index) => ({
                ...teamGoals[index],
                goal_text: goalText,
                confirmed: true
            }));

        const hasNullGoalText = nonEmptyGoals.some(goal => !goal.goal_text || goal.goal_text.trim() === '');
        if (hasNullGoalText) {
            alert('All goals must have text.');
            return;
        }

        setIsSubmitting(true);

        try {
            const summarizedGoals = await Promise.all(nonEmptyGoals.map(async (goal) => {
                const response = await fetchWithAuth('/api/summarization/summarize-goal', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ text: goal.goal_text })
                });
                const { summary } = await response.json();
                return {
                    ...goal,
                    summary: summary || goal.goal_text.substring(0, 100) + '...'
                };
            }));

            if (summarizedGoals.length === 0) {
                alert('You must submit a goal to continue');
                return;
            }

            submitData(summarizedGoals);
            alert('Data submitted successfully!');

            if (userRole === 'Admin') {
                scrollToTop();
                navigate('/adminportal');
            } else if (userRole === 'Manager') {
                scrollToTop();
                navigate('/managerportal');
            } else {
                scrollToTop();
                navigate('/employeeportal');
            }
        } catch (error) {
            console.error('Error summarizing goals:', error);
            alert('There was an error summarizing your goals. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isSubmitting) {
        return (
            <div className='manager-goal-set-container'>
                <div className='manager-loading-overlay'>
                    <div className='manager-loading-content'>
                        <PulseLoader
                            color="#6908C9"
                            size={15}
                            margin={5}
                            speedMultiplier={0.8}
                        />
                        <h2 className='manager-loading-text'>Summarizing and submitting goals...</h2>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='manager-goal-set-container'>
            <h2 className='manager-goal-set-title'>
                Review Your Team Goals
            </h2>

            <div className='manager-team-context-form-section'>
                <p className='manager-goal-set-description'>
                    Review and confirm your team goals before final submission
                </p>

                <div className='manager-goals-review-container'>
                    {isEditingGoals ? (
                        <div className='manager-goals-edit-list'>
                            {editedGoals.map((goalText, index) => (
                                <div key={index} className='manager-goal-review-item editing'>
                                    <div className='manager-goal-number'>{index + 1}</div>
                                    <textarea 
                                        className='manager-goal-review-input'
                                        value={goalText} 
                                        onChange={(e) => handleGoalChange(index, e.target.value)}
                                        placeholder="Enter team goal..."
                                    />
                                </div>
                            ))}
                            <button 
                                onClick={toggleEditGoals} 
                                className='manager-edit-done-button'
                            >
                                <FaCheck /> Done Editing
                            </button>
                        </div>
                    ) : (
                        <div className='manager-goals-display-list'>
                            {teamGoals.length > 0 ? (
                                teamGoals.map((goal, index) => (
                                    <div key={index} className='manager-goal-review-item'>
                                        <div className='manager-goal-number'>{index + 1}</div>
                                        <div className='manager-goal-review-content'>
                                            <p>{goal.goal_text}</p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className='manager-no-goals-message'>
                                    <p>No team goals provided. Please go back and add some goals.</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {!isEditingGoals && teamGoals.length > 0 && (
                    <button 
                        onClick={toggleEditGoals} 
                        className='manager-edit-goals-button'
                    >
                        <FaPenToSquare /> Edit Goals
                    </button>
                )}
            </div>

            <div className='manager-goal-set-button-container'>
                <button onClick={prevStep} className='manager-goal-set-link'>
                    <FaArrowLeft /> Back
                </button>
                <button onClick={saveForLater} className='manager-goal-set-link'>
                    <FaFloppyDisk /> Save for Later
                </button>
                <button 
                    onClick={handleSubmit} 
                    className='manager-goal-set-submit-link'
                    disabled={teamGoals.length === 0}
                >
                    <FaPaperPlane /> Submit
                </button>
            </div>
        </div>
    );
}

export default ManagerStep4;