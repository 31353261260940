import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    // Main authentication state
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    
    // User data state
    const [userId, setUserId] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    // Initialize auth state from cookies on component mount
    useEffect(() => {
        const token = Cookies.get('authToken');
        const cookieUserId = Cookies.get('userId');
        const cookieUserRole = Cookies.get('userRole');
        const cookieCompanyId = Cookies.get('company_id');
        const cookieFirstName = Cookies.get('first_name');
        const cookieLastName = Cookies.get('last_name');
        
        // If we have user ID and role cookies, consider user authenticated
        const hasAuthCookies = cookieUserId && cookieUserRole;
        
        setIsAuthenticated(!!hasAuthCookies);
        
        if (hasAuthCookies) {
            setUserId(cookieUserId);
            setUserRole(cookieUserRole);
            setCompanyId(cookieCompanyId);
            setFirstName(cookieFirstName || '');
            setLastName(cookieLastName || '');
        }
    }, []);

    // Login function to update all auth state and cookies
    const login = (userData) => {
        const { token, userId, userRole, companyId, firstName, lastName } = userData;
        
        // Update cookies (if values are provided)
        if (token) Cookies.set('authToken', token, { secure: true, sameSite: 'Lax' });
        if (userId) Cookies.set('userId', userId, { secure: true, sameSite: 'Lax' });
        if (userRole) Cookies.set('userRole', userRole, { secure: true, sameSite: 'Lax' });
        if (companyId) Cookies.set('company_id', companyId, { secure: true, sameSite: 'Lax' });
        if (firstName) Cookies.set('first_name', firstName, { secure: true, sameSite: 'Lax' });
        if (lastName) Cookies.set('last_name', lastName, { secure: true, sameSite: 'Lax' });
        
        // Update state
        setIsAuthenticated(true);
        setUserId(userId);
        setUserRole(userRole);
        setCompanyId(companyId);
        setFirstName(firstName || '');
        setLastName(lastName || '');
    };

    // Logout function to clear all auth state and cookies
    const logout = async (isInactivityTimeout) => {
        try {
            console.log('===== LOGOUT FUNCTION CALLED =====');
            console.log('isInactivityTimeout flag:', isInactivityTimeout);
            
            // Always set the flag first before any other operations
            if (isInactivityTimeout) {
                localStorage.setItem('sessionExpired', 'true');
                localStorage.setItem('inactivityTimeout', 'true');
                localStorage.setItem('logoutTimestamp', Date.now().toString());
                
                console.log('Inactivity flags set in localStorage:', {
                    sessionExpired: localStorage.getItem('sessionExpired'),
                    inactivityTimeout: localStorage.getItem('inactivityTimeout'),
                    logoutTimestamp: localStorage.getItem('logoutTimestamp')
                });
            }
            
            // Clear all auth cookies
            Cookies.remove('authToken');
            Cookies.remove('userId');
            Cookies.remove('userRole');
            Cookies.remove('company_id');
            Cookies.remove('first_name');
            Cookies.remove('last_name');
            
            // Reset state
            setIsAuthenticated(false);
            setUserId(null);
            setUserRole(null);
            setCompanyId(null);
            setFirstName('');
            setLastName('');

            // Only redirect if not already on the login page
            if (window.location.pathname !== '/login') {
                console.log('Redirecting to login page');
                
                // Add the timeout parameter to the URL only for inactivity timeout
                const redirectUrl = isInactivityTimeout ? '/login?timeout=true' : '/login';
                
                // Force a full page reload to ensure a clean state
                window.location.href = redirectUrl;
            }
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ 
            isAuthenticated,
            userId,
            userRole,
            companyId,
            firstName,
            lastName,
            login,
            logout,
            // Expose setters for compatibility with existing code
            setUserId,
            setUserRole,
            setCompanyId,
            setFirstName,
            setLastName
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
