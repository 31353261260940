import React, { useEffect, useState } from 'react';
import AIButton from '../../../Components/AIButton/AIButton';
import { useEmployeeContext } from '../../../Contexts/employeeContext';
import { 
    FaArrowLeft, 
    FaArrowRight, 
    FaFloppyDisk, 
    FaPlus, 
    FaPenToSquare, 
    FaTrash, 
    FaCheck,
    FaWandMagicSparkles 
} from 'react-icons/fa6';
import fetchWithAuth from '../../../Util/fetchWithAuth';

function EmployeeStep3({ employeeGoals = [], setEmployeeGoals, prevStep, nextStep, saveForLater, textareaRef, userId }) {
    const [goals, setGoals] = useState(employeeGoals);
    const { employeeContext } = useEmployeeContext();
    
    console.log("EmployeeStep3 rendering with context:", employeeContext);

    useEffect(() => {
        setGoals(employeeGoals);
    }, [employeeGoals]);

    const addGeneratedGoal = (generatedGoal) => {
        if (goals.length >= 5) {
            alert('You can only have up to 5 goals.');
            return;
        }
        const newEmployeeGoals = [...goals, { id: null, goal_text: generatedGoal, confirmed: false, userID: userId }];
        setGoals(newEmployeeGoals);
        setEmployeeGoals(newEmployeeGoals);
    };

    const addEmployeeGoal = () => {
        if (goals.length >= 5) {
            alert('You can only have up to 5 goals.');
            return;
        }
        const newEmployeeGoals = [...goals, { id: null, goal_text: '', confirmed: false, userID: userId }];
        setGoals(newEmployeeGoals);
        setEmployeeGoals(newEmployeeGoals);
    };

    const handleEmployeeGoalChange = (index, value) => {
        const newEmployeeGoals = goals.map((goal, i) => (i === index ? { ...goal, goal_text: value } : goal));
        setGoals(newEmployeeGoals);
        setEmployeeGoals(newEmployeeGoals);
    };

    const toggleEmployeeGoalConfirmation = (index) => {
        const newEmployeeGoals = goals.map((goal, i) => (i === index ? { ...goal, confirmed: !goal.confirmed } : goal));
        setGoals(newEmployeeGoals);
        setEmployeeGoals(newEmployeeGoals);
    };

    const deleteEmployeeGoal = async (index) => {
        const goalToDelete = goals[index];
        if (goalToDelete.id) {
            try {
                const response = await fetchWithAuth(`/api/goals/delete-goal/${goalToDelete.id}`, {
                    method: 'DELETE',
                });

                if (!response.ok) {
                    throw new Error('Failed to delete goal');
                }
            } catch (error) {
                console.error('Error deleting goal:', error);
                return;
            }
        }

        const newEmployeeGoals = goals.filter((_, i) => i !== index);
        setGoals(newEmployeeGoals);
        setEmployeeGoals(newEmployeeGoals);
    };

    return (
        <div className="employee-page-container">
            <div className="employee-page-content">
                <div className='employee-goal-set-container'>
                    <h2 className='employee-goal-set-title'>Set Your Personal Goals</h2>
                    <p className='employee-goal-set-description'>
                        Please enter up to five personal goals below. These will help personalize your development and make them more relevant to your career path.
                    </p>

                    {/* Context Summary */}
                    <div className='employee-context-summary'>
                        <h4>Your Profile Summary</h4>
                        <div className='employee-context-summary-content'>
                            <div className="employee-summary-row">
                                <p><strong>Work Style:</strong> {employeeContext.workStyle ? employeeContext.workStyle.charAt(0).toUpperCase() + employeeContext.workStyle.slice(1) : 'Not specified'}</p>
                                <p><strong>Skill Level:</strong> {employeeContext.skillLevel ? employeeContext.skillLevel.charAt(0).toUpperCase() + employeeContext.skillLevel.slice(1) : 'Not specified'}</p>
                            </div>
                            <div className="employee-summary-row">
                                <p><strong>Career Interests:</strong> {employeeContext.careerInterests ? employeeContext.careerInterests.charAt(0).toUpperCase() + employeeContext.careerInterests.slice(1) : 'Not specified'}</p>
                                <p><strong>Development Areas:</strong> {employeeContext.developmentAreas ? employeeContext.developmentAreas.charAt(0).toUpperCase() + employeeContext.developmentAreas.slice(1) : 'Not specified'}</p>
                            </div>
                            <div className="employee-summary-row">
                                {employeeContext.strengths && (
                                    <p><strong>Strengths:</strong> {employeeContext.strengths.charAt(0).toUpperCase() + employeeContext.strengths.slice(1)}</p>
                                )}
                                {employeeContext.challenges && employeeContext.challenges.length > 0 && (
                                    <p><strong>Challenges:</strong> {employeeContext.challenges.map(challenge => 
                                        challenge.charAt(0).toUpperCase() + challenge.slice(1)
                                    ).join(', ')}</p>
                                )}
                            </div>
                            {employeeContext.learningPreferences && (
                                <div className="employee-summary-row">
                                    <p><strong>Learning Preferences:</strong> {employeeContext.learningPreferences.charAt(0).toUpperCase() + employeeContext.learningPreferences.slice(1)}</p>
                                </div>
                            )}
                        </div>
                        <p className='employee-context-note'>
                            AI suggestions will be tailored based on your profile information.
                        </p>
                    </div>

                    <div className='employee-goal-actions'>
                        <AIButton 
                            userId={userId} 
                            onNewGoal={addGeneratedGoal}
                            contextData={employeeContext}
                            className='astellyst-ai-button'
                            icon={<FaWandMagicSparkles />}
                        />
                        {goals.length < 5 && (
                            <button 
                                onClick={addEmployeeGoal} 
                                className='employee-goal-set-add-button'
                            >
                                <FaPlus /> Add Manual Goal
                            </button>
                        )}
                    </div>

                    <div className='employee-goals-container'>
                        {goals.map((goal, index) => (
                            <div key={index} className='employee-goal-item'>
                                <div className='employee-goal-number'>{index + 1}</div>
                                <div className='employee-goal-content'>
                                    <textarea
                                        className='employee-goal-set-input'
                                        placeholder='Enter a personal goal here'
                                        value={goal.goal_text}
                                        onChange={(e) => handleEmployeeGoalChange(index, e.target.value)}
                                        ref={textareaRef}
                                        readOnly={goal.confirmed}
                                    />
                                    <div className="employee-goal-actions">
                                        <button 
                                            onClick={() => toggleEmployeeGoalConfirmation(index)} 
                                            className={`employee-action-button ${goal.confirmed ? 'edit' : 'confirm'}`}
                                        >
                                            {goal.confirmed ? <FaPenToSquare /> : <FaCheck />}
                                            {goal.confirmed ? 'Edit' : 'Confirm'}
                                        </button>
                                        <button 
                                            onClick={() => deleteEmployeeGoal(index)} 
                                            className='employee-action-button delete'
                                        >
                                            <FaTrash /> Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='employee-goal-set-button-container'>
                        <button onClick={prevStep} className='employee-goal-set-link'>
                            <FaArrowLeft /> Back
                        </button>
                        <button onClick={saveForLater} className='employee-goal-set-link'>
                            <FaFloppyDisk /> Save for Later
                        </button>
                        <button onClick={nextStep} className='employee-goal-set-link'>
                            Next <FaArrowRight />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmployeeStep3;
