import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scrollToTop } from '../../Util/scrollHelper';

// This component will automatically scroll to the top when the route changes
const ScrollToTop = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    // Scroll to top when the route changes
    scrollToTop();
  }, [pathname, search]); // Re-run this effect when the path or search params change

  return null; // This component doesn't render anything
};

export default ScrollToTop; 