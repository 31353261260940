import Cookies from 'js-cookie';

// Token refresh state management
let isRefreshing = false;
let refreshPromise = null;
let pendingRequests = [];

/**
 * Function to refresh the token
 * @returns {Promise} Promise that resolves when token is refreshed
 */
const refreshToken = async () => {
  try {
    const response = await fetch('/api/auth/refresh-token', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    if (!response.ok) {
      throw new Error('Token refresh failed');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

/**
 * Wrapper for fetch API that handles authentication and token expiration
 * @param {string} url - The URL to fetch
 * @param {Object} options - Fetch options
 * @returns {Promise} - Fetch promise
 */
const fetchWithAuth = async (url, options = {}) => {
  // Ensure we have credentials included
  const fetchOptions = {
    ...options,
    credentials: 'include',
  };

  try {
    const response = await fetch(url, fetchOptions);
    
    // Handle 401 (Unauthorized) or 403 (Forbidden) responses
    if ((response.status === 401 || response.status === 403)) {
      const data = await response.json().catch(() => ({}));
      
      if (data.error === 'Invalid token.') {
        // If this is a token refresh request that failed, redirect to login
        if (url.includes('/auth/refresh-token')) {
          handleSessionExpired();
          throw new Error('Session expired. Please log in again.');
        }
        
        // Try to refresh the token if not already refreshing
        if (!isRefreshing) {
          isRefreshing = true;
          refreshPromise = refreshToken()
            .then((result) => {
              // Process any pending requests
              pendingRequests.forEach(pendingRequest => pendingRequest.resolve());
              pendingRequests = [];
              return result;
            })
            .catch((error) => {
              // Fail all pending requests
              pendingRequests.forEach(pendingRequest => pendingRequest.reject(error));
              pendingRequests = [];
              
              // Handle session expiration
              handleSessionExpired();
              throw error;
            })
            .finally(() => {
              isRefreshing = false;
              refreshPromise = null;
            });
        }
        
        // If another request is already refreshing the token, wait for it
        if (refreshPromise) {
          try {
            // Wait for the token refresh
            await new Promise((resolve, reject) => {
              pendingRequests.push({ resolve, reject });
            });
            
            // Retry the request with new token
            return fetchWithAuth(url, options);
          } catch (refreshError) {
            throw new Error('Session expired. Please log in again.');
          }
        }
      }
    }
    
    return response;
  } catch (error) {
    // If it's our custom error, just throw it
    if (error.message === 'Session expired. Please log in again.') {
      throw error;
    }
    
    // For network errors or other fetch issues
    console.error('Fetch error:', error);
    throw error;
  }
};

/**
 * Helper function to handle session expiration
 */
function handleSessionExpired() {
  // Check if user is already on login page or being redirected
  if (window.location.pathname === '/login' || sessionStorage.getItem('redirecting') === 'true') {
    return;
  }
  
  // Set redirecting flag to prevent multiple redirects
  sessionStorage.setItem('redirecting', 'true');
  
  // Clear auth cookies - this should match what's in authContext.logout
  Cookies.remove('authToken');
  Cookies.remove('userId');
  Cookies.remove('userRole');
  Cookies.remove('company_id');
  Cookies.remove('first_name');
  Cookies.remove('last_name');
  
  // Set session expired flag for the timeout message component
  sessionStorage.setItem('sessionExpired', 'true');
  
  // Redirect to login
  window.location.href = '/login';
  
  // Clear redirecting flag after redirect
  setTimeout(() => {
    sessionStorage.removeItem('redirecting');
  }, 1000);
}

export default fetchWithAuth; 