import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../../Components/BackButton/BackButton';
import { 
    FaArrowRight, 
    FaArrowLeft,
} from 'react-icons/fa6';
import { scrollToTop } from '../../../Util/scrollHelper';

function EmployeeStep1({ companyMission, companyGoals, teamGoals, nextStep, userRole }) {
    const navigate = useNavigate();
    const [currentSection, setCurrentSection] = useState(0);

    const handleBackClick = () => {
        let portalPath = '/employeeportal?tab=set-goals';
        if (userRole === 'Admin') {
            portalPath = '/adminportal?tab=set-your-goals';
        } else if (userRole === 'Manager') {
            portalPath = '/managerportal?tab=set-your-goals';
        }
        scrollToTop();
        navigate(`${portalPath}`);
    };

    const goToNextSection = () => {
        setCurrentSection(prev => prev + 1);
        scrollToTop();
    };

    const goToPrevSection = () => {
        setCurrentSection(prev => prev - 1);
        scrollToTop();
    };

    return (
        <div className="page-container">
            <div className="back-button-container">
                <BackButton onClick={handleBackClick} />
            </div>
            <div className="page-content">
                <h2 className='page-header employee-goal-set-title'>Review Goals and Mission</h2>
                <p className='employee-goal-set-subtitle'>
                    Review these carefully to align your personal goals with the company's direction
                </p>
                
                <div className="employee-step-progress">
                    <div className="employee-step-progress-bar">
                        <div 
                            className="employee-step-progress-fill"
                            style={{ width: `${((currentSection + 1) / 3) * 100}%` }}
                        />
                    </div>
                    <div className="employee-step-progress-text">
                        Step {currentSection + 1} of 3: {
                            currentSection === 0 ? 'Company Mission' :
                            currentSection === 1 ? 'Company Goals' :
                            'Team Goals'
                        }
                    </div>
                </div>
                
                <div className='employee-team-context-form-section'>
                    {/* Company Mission Section */}
                    {currentSection === 0 && (
                        <>
                            <h4 className='employee-team-context-form-section-title'>
                                Company Mission
                            </h4>
                            <div className='employee-mission-card'>
                                <p className='employee-goal-review-mission'>{companyMission}</p>
                            </div>
                        </>
                    )}

                    {/* Company Goals Section */}
                    {currentSection === 1 && (
                        <>
                            <h4 className='employee-team-context-form-section-title'>
                                Company Goals
                            </h4>
                            <div className='employee-goals-grid'>
                                {companyGoals.map((goal, index) => (
                                    <div key={index} className='employee-goal-card'>
                                        <span className='employee-goal-number'>{index + 1}</span>
                                        <p>{goal.text}</p>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    {/* Team Goals Section */}
                    {currentSection === 2 && (
                        <>
                            <h4 className='employee-team-context-form-section-title'>
                                Team Goals
                            </h4>
                            <div className='employee-goals-grid'>
                                {teamGoals.map((goal, index) => (
                                    <div key={index} className='employee-goal-card'>
                                        <span className='employee-goal-number'>{index + 1}</span>
                                        <p>{goal.goal_text}</p>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>

                <div className='employee-goal-set-button-container'>
                    {currentSection > 0 && (
                        <button className='employee-goal-set-link' onClick={goToPrevSection}>
                            <FaArrowLeft /> Previous
                        </button>
                    )}
                    
                    {currentSection < 2 ? (
                        <button className='employee-goal-set-link' onClick={goToNextSection}>
                            Next <FaArrowRight />
                        </button>
                    ) : (
                        <button className='employee-goal-set-link' onClick={nextStep}>
                            Continue <FaArrowRight />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default EmployeeStep1;
