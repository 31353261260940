import React, { useState, useEffect } from 'react';
import { useAuth } from '../../Contexts/authContext';
import { useNavigate } from 'react-router-dom';
import { FaShoppingCart, FaArrowLeft, FaSpinner, FaTrophy, FaCoins } from 'react-icons/fa';
import fetchWithAuth from '../../Util/fetchWithAuth';
import './Store.css';

const getImageUrl = (imageUrl) => {
    if (!imageUrl) return null;
    // If it's a full URL, use it directly
    if (imageUrl.startsWith('http://') || imageUrl.startsWith('https://')) {
        return imageUrl;
    }
    // If it's a relative path, prepend with the base URL based on environment
    const apiUrl = process.env.NODE_ENV === 'production' 
        ? process.env.REACT_APP_API_URL 
        : process.env.REACT_APP_LOCAL_API_URL;
    return apiUrl ? `${apiUrl}${imageUrl}` : imageUrl;
};

const CompanyStore = () => {
    const [storeItems, setStoreItems] = useState([]);
    const [userPoints, setUserPoints] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [redemptionMessage, setRedemptionMessage] = useState(null);
    const [redemptionHistory, setRedemptionHistory] = useState([]);
    const [showHistory, setShowHistory] = useState(false);

    const { userId, companyId } = useAuth();
    const navigate = useNavigate();

    // Fetch store items and user points on component mount
    useEffect(() => {
        const fetchStoreData = async () => {
            setLoading(true);
            try {
                // Fetch store items
                const itemsResponse = await fetchWithAuth(`/api/store/items/${companyId}`);
                const itemsData = await itemsResponse.json();
                
                // Fetch user details to get points
                const userResponse = await fetchWithAuth(`/api/users/get-user-details/${userId}`);
                const userData = await userResponse.json();
                
                setStoreItems(itemsData);
                setUserPoints(userData.points || 0);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching store data:', err);
                setError('Failed to load store items. Please try again later.');
                setLoading(false);
            }
        };

        fetchStoreData();
    }, [userId, companyId]);

    // Fetch redemption history
    const fetchRedemptionHistory = async () => {
        try {
            const response = await fetchWithAuth(`/api/store/redemptions/${userId}`);
            const data = await response.json();
            setRedemptionHistory(data);
            setShowHistory(true);
        } catch (err) {
            console.error('Error fetching redemption history:', err);
            setError('Failed to load redemption history. Please try again later.');
        }
    };

    // Handle redemption of an item
    const handleRedeem = async () => {
        try {
            const response = await fetchWithAuth('/api/store/redeem', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId,
                    itemId: selectedItem.id
                }),
            });

            const data = await response.json();
            
            if (response.ok) {
                // Update local user points
                setUserPoints(data.remainingPoints);
                
                // Update item quantity
                setStoreItems(prevItems => prevItems.map(item => 
                    item.id === selectedItem.id 
                        ? { ...item, quantity_available: item.quantity_available - 1 }
                        : item
                ));
                
                setRedemptionMessage({
                    type: 'success',
                    text: 'Item redeemed successfully! Your request has been sent to administrators.'
                });
            } else {
                setRedemptionMessage({
                    type: 'error',
                    text: data.error || 'Failed to redeem item. Please try again.'
                });
            }
        } catch (err) {
            console.error('Error redeeming item:', err);
            setRedemptionMessage({
                type: 'error',
                text: 'An error occurred. Please try again later.'
            });
        } finally {
            setShowConfirmation(false);
        }
    };

    // Handle clicking on an item
    const handleItemClick = (item) => {
        setSelectedItem(item);
        setShowConfirmation(true);
        setRedemptionMessage(null);
    };

    // Close the confirmation modal
    const closeConfirmation = () => {
        setShowConfirmation(false);
        setSelectedItem(null);
    };

    // Format points with comma for thousands
    const formatPoints = (points) => {
        return points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    if (loading) {
        return (
            <div className="store-loading">
                <FaSpinner className="loading-spinner" />
                <p>Loading store items...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="store-error">
                <p>{error}</p>
                <button onClick={() => navigate(-1)}>Go Back</button>
            </div>
        );
    }

    return (
        <div className="company-store-container">
            <div className="store-header">
                <div className="store-navigation">
                    <button className="back-button" onClick={() => navigate(-1)}>
                        <FaArrowLeft /> Back
                    </button>
                    <h1>Company Reward Store</h1>
                </div>
                <div className="user-points">
                    <FaCoins className="coins-icon" />
                    <span>Your Points: <strong>{formatPoints(userPoints)}</strong></span>
                </div>
                <div className="store-actions">
                    <button 
                        className="history-button" 
                        onClick={showHistory ? () => setShowHistory(false) : fetchRedemptionHistory}
                    >
                        {showHistory ? 'Hide History' : 'View Redemption History'}
                    </button>
                </div>
            </div>

            {redemptionMessage && (
                <div className={`redemption-message ${redemptionMessage.type}`}>
                    {redemptionMessage.text}
                </div>
            )}

            {showHistory ? (
                <div className="redemption-history">
                    <h2>Your Redemption History</h2>
                    {redemptionHistory.length === 0 ? (
                        <p className="no-history">You haven't redeemed any items yet.</p>
                    ) : (
                        <div className="history-items">
                            {redemptionHistory.map(item => (
                                <div key={item.id} className="history-item">
                                    <div className="history-item-image-container">
                                        {item.image_url ? (
                                            <img 
                                                src={getImageUrl(item.image_url)} 
                                                alt={item.name} 
                                                className="history-item-image" 
                                            />
                                        ) : (
                                            <div className="no-image">
                                                <FaTrophy />
                                            </div>
                                        )}
                                    </div>
                                    <div className="history-item-details">
                                        <h3>{item.name}</h3>
                                        <p>{new Date(item.created_at).toLocaleDateString()}</p>
                                        <div className="history-item-points">
                                            <FaCoins className="history-coins-icon" />
                                            <span>{formatPoints(item.points_cost)} points</span>
                                        </div>
                                        <div className={`history-status ${item.status}`}>
                                            Status: {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    <button 
                        className="back-to-store-button" 
                        onClick={() => setShowHistory(false)}
                    >
                        Back to Store
                    </button>
                </div>
            ) : (
                <>
                    <div className="store-description">
                        <p>Redeem your earned points for exciting rewards! Complete goals and check-ins to earn more points.</p>
                    </div>

                    {storeItems.length === 0 ? (
                        <div className="no-items">
                            <p>No items are currently available in the store.</p>
                            <p>Check back later for new rewards!</p>
                        </div>
                    ) : (
                        <div className="store-items-grid">
                            {storeItems.map(item => (
                                <div 
                                    key={item.id} 
                                    className={`store-item ${item.quantity_available <= 0 || userPoints < item.points_cost ? 'disabled' : ''}`}
                                    onClick={() => {
                                        if (item.quantity_available > 0 && userPoints >= item.points_cost) {
                                            handleItemClick(item);
                                        }
                                    }}
                                >
                                    <div className="store-item-image-container">
                                        {item.image_url ? (
                                            <img 
                                                src={getImageUrl(item.image_url)} 
                                                alt={item.name} 
                                                className="store-item-image" 
                                            />
                                        ) : (
                                            <div className="no-image">
                                                <FaTrophy />
                                            </div>
                                        )}
                                    </div>
                                    <div className="store-item-details">
                                        <h3>{item.name}</h3>
                                        <p>{item.description}</p>
                                        <div className="store-item-points">
                                            <FaCoins className="store-coins-icon" />
                                            <span>{formatPoints(item.points_cost)} points</span>
                                        </div>
                                        <div className="store-item-quantity">
                                            {item.quantity_available <= 0 ? (
                                                <span className="out-of-stock">Out of Stock</span>
                                            ) : (
                                                <span>Available: {item.quantity_available}</span>
                                            )}
                                        </div>
                                        {userPoints < item.points_cost && (
                                            <div className="not-enough-points">
                                                You need {formatPoints(item.points_cost - userPoints)} more points
                                            </div>
                                        )}
                                    </div>
                                    <button 
                                        className="redeem-button"
                                        disabled={item.quantity_available <= 0 || userPoints < item.points_cost}
                                    >
                                        <FaShoppingCart /> Redeem
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}

            {showConfirmation && selectedItem && (
                <div className="confirmation-modal">
                    <div className="confirmation-content">
                        <h2>Confirm Redemption</h2>
                        <p>Are you sure you want to redeem <strong>{selectedItem.name}</strong> for <strong>{formatPoints(selectedItem.points_cost)}</strong> points?</p>
                        
                        <div className="confirmation-details">
                            <div className="confirmation-item-image">
                                {selectedItem.image_url ? (
                                    <img 
                                        src={getImageUrl(selectedItem.image_url)} 
                                        alt={selectedItem.name} 
                                    />
                                ) : (
                                    <div className="no-image">
                                        <FaTrophy />
                                    </div>
                                )}
                            </div>
                            <div className="confirmation-points">
                                <div>
                                    <span>Your Points:</span>
                                    <span>{formatPoints(userPoints)}</span>
                                </div>
                                <div>
                                    <span>Item Cost:</span>
                                    <span>{formatPoints(selectedItem.points_cost)}</span>
                                </div>
                                <div className="remaining-points">
                                    <span>Remaining Points:</span>
                                    <span>{formatPoints(userPoints - selectedItem.points_cost)}</span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="confirmation-buttons">
                            <button 
                                className="confirm-button"
                                onClick={handleRedeem}
                            >
                                Confirm Redemption
                            </button>
                            <button 
                                className="cancel-button"
                                onClick={closeConfirmation}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CompanyStore; 