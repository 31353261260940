import React, { useState, useEffect, useRef } from 'react';
import './Styles/home.css';
import SignUpForm from '../../Components/MailChimp/NewsletterSignUp';
import { useNavigate, NavLink } from 'react-router-dom';
import { useAuth } from '../../Contexts/authContext'; // Import the Auth Context
import { scrollToTop } from '../../Util/scrollHelper'; // Import the scroll helper

const NavBar = () => {
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const sidebarRef = useRef(null); // Create a ref for the sidebar
    const { userRole, userId, isAuthenticated, logout } = useAuth(); // Get data from auth context

    const handleNavigation = (path) => {
        navigate(path);
        scrollToTop(); // Use our utility function instead
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleSignOut = () => {
        // Use the logout function from auth context
        logout();
        
        // The logout function now handles the redirect, so we don't need to do it here
        // navigate('/login');
    };

    const handleGoToPortal = () => {
        if (userRole === 'Admin') {
            navigate('/AdminPortal');
        } else if (userRole === 'Manager') {
            navigate('/ManagerPortal');
        } else {
            navigate('/EmployeePortal');
        }
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsSidebarOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="landingPageNavBar-container">
            <div className="home-navbar">
                <div className="home-navbar-hamburger" onClick={toggleSidebar}>
                    <div className="home-navbar-hamburger-line"></div>
                    <div className="home-navbar-hamburger-line"></div>
                    <div className="home-navbar-hamburger-line"></div>
                </div>
                <NavLink className="home-navLink-logo" to="/" onClick={() => handleNavigation('/')}>Astellyst</NavLink>
                <div className="landingPageNavBarLeft">
                    <NavLink className="home-navLink" to="/About" onClick={() => handleNavigation('/About')}>About</NavLink>
                    <NavLink className="home-navLink" to="/Contact" onClick={() => handleNavigation('/Contact')}>Contact</NavLink>
                    <NavLink className="home-navLink" to="/Resources" onClick={() => handleNavigation('/Resources')}>Resources</NavLink>
                </div>
                <div className="landingPageNavBarRight">
                    {isAuthenticated ? (
                        <>
                            <button className="home-navLink-button" onClick={handleGoToPortal}>Go to Portal</button>
                            <button className="home-navLink-button" onClick={handleSignOut}>Sign Out</button>
                        </>
                    ) : (
                        <NavLink className="home-navLink" to="/login" onClick={() => handleNavigation('/login')}>Sign In</NavLink>
                    )}
                </div>
            </div>
            {/* Sidebar */}
            <div ref={sidebarRef} className={`navbar-sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <button className="home-navbar-close-btn" onClick={toggleSidebar}>×</button>
                <NavLink className="home-navbar-sidebar-link" to="/About" onClick={() => handleNavigation('/About')}>About</NavLink>
                <NavLink className="home-navbar-sidebar-link" to="/Contact" onClick={() => handleNavigation('/Contact')}>Contact</NavLink>
                <NavLink className="home-navbar-sidebar-link" to="/Resources" onClick={() => handleNavigation('/Resources')}>Resources</NavLink>
                {isAuthenticated ? (
                    <>
                        <button className="home-navbar-sidebar-link-button" onClick={handleGoToPortal}>Go to Portal</button>
                        <button className="home-navbar-sidebar-link-button" onClick={handleSignOut}>Sign Out</button>
                    </>
                ) : (
                    <NavLink className="home-navbar-sidebar-link" to="/login" onClick={() => handleNavigation('/login')}>Sign In</NavLink>
                )}
            </div>
        </div>
    );
};

const Home = () => {
    return (
        <div className='home-body'>
            <NavBar />
            <div className="landingPageHero">
                <h1 className="landingPageHeroTitle">Dream Big Together</h1>
                <h3 className="landingPageHeroSubtitle">Performance Management that Inspires</h3>
                <p>Get on the early access list now</p>
                <SignUpForm className="signUpForm" /> {/* Ensure the className is passed */}
            </div>
        </div>
    );
};

export default Home;