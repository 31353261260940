import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from './authContext';
import fetchWithAuth from '../Util/fetchWithAuth';

const EmployeeContext = createContext();

export function EmployeeContextProvider({ children }) {
    const { userId } = useAuth();
    const [employeeContext, setEmployeeContext] = useState({
        workStyle: '', // remote, hybrid, office
        skillLevel: '', // beginner, intermediate, advanced
        careerInterests: '',
        learningPreferences: '',
        challenges: [],
        challengeDetails: '',
        strengths: '',
        priorities: '',
        developmentAreas: ''
    });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (userId) {
            setIsLoading(true);
            fetchWithAuth(`/api/employee-context/${userId}`)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 404) {
                        // No context found, use default empty state
                        return null;
                    }
                    throw new Error('Failed to fetch employee context');
                }
                return response.json();
            })
            .then(data => {
                if (data) {
                    // Make sure we're parsing the data correctly
                    console.log('Received employee context data:', data);
                    
                    // Ensure challenges is an array
                    if (typeof data.challenges === 'string') {
                        try {
                            data.challenges = JSON.parse(data.challenges);
                        } catch (e) {
                            data.challenges = [];
                        }
                    }
                    
                    setEmployeeContext({
                        workStyle: data.work_style || '',
                        skillLevel: data.skill_level || '',
                        careerInterests: data.career_interests || '',
                        learningPreferences: data.learning_preferences || '',
                        challenges: Array.isArray(data.challenges) ? data.challenges : [],
                        challengeDetails: data.challenge_details || '',
                        strengths: data.strengths || '',
                        priorities: data.priorities || '',
                        developmentAreas: data.development_areas || ''
                    });
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.error('Error fetching employee context:', err);
                setError(err.message);
                setIsLoading(false);
            });
        }
    }, [userId]);

    const saveEmployeeContext = async (contextData) => {
        if (!userId) {
            throw new Error('User ID is required to save employee context');
        }

        try {
            const response = await fetchWithAuth(`/api/employee-context/${userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(contextData),
            });

            if (!response.ok) {
                throw new Error('Failed to save employee context');
            }

            const savedData = await response.json();
            setEmployeeContext(savedData);
            return savedData;
        } catch (error) {
            console.error('Error saving employee context:', error);
            throw error;
        }
    };

    const updateEmployeeContext = (updates) => {
        console.log("Updating employee context with:", updates);
        console.log("Previous context:", employeeContext);
        setEmployeeContext(prev => {
            const newContext = {...prev, ...updates};
            console.log("New context will be:", newContext);
            return newContext;
        });
    };

    const resetEmployeeContext = () => {
        setEmployeeContext({
            workStyle: '',
            skillLevel: '',
            careerInterests: '',
            learningPreferences: '',
            challenges: [],
            challengeDetails: '',
            strengths: '',
            priorities: '',
            developmentAreas: ''
        });
    };

    const value = {
        employeeContext,
        setEmployeeContext: updateEmployeeContext,
        saveEmployeeContext,
        resetEmployeeContext,
        isLoading,
        error
    };

    return (
        <EmployeeContext.Provider value={value}>
            {children}
        </EmployeeContext.Provider>
    );
}

export function useEmployeeContext() {
    const context = useContext(EmployeeContext);
    if (context === undefined) {
        throw new Error('useEmployeeContext must be used within an EmployeeContextProvider');
    }
    return context;
} 