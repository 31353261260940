import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../Contexts/authContext';
import { useNotifications } from '../../../Contexts/notificationContext';
import '../Styles/ManagerProgress.css';
import { 
    FaPaperPlane, 
    FaCheck, 
    FaTimes, 
    FaCalendarCheck, 
    FaComment, 
    FaCommentDots, 
    FaRegPaperPlane, 
    FaClock, 
    FaCalendarAlt, 
    FaCalendarTimes, 
    FaCheckCircle,
    FaNotesMedical,
    FaTrash
} from 'react-icons/fa';
import BackButton from '../../../Components/BackButton/BackButton';
import fetchWithAuth from '../../../Util/fetchWithAuth';

const ManagerProgress = () => {
    const { userId } = useParams();
    const { userId: commenterId, firstName, lastName, userRole } = useAuth();
    const navigate = useNavigate();
    
    const [goals, setGoals] = useState([]);
    const [employeeName, setEmployeeName] = useState("");
    const [currentComment, setCurrentComment] = useState({});
    const [goalComments, setGoalComments] = useState({});
    const [commentsVisible, setCommentsVisible] = useState({});
    const [pendingComments, setPendingComments] = useState({});
    const [goalTextVisible, setGoalTextVisible] = useState([]);
    const [isVerifying, setIsVerifying] = useState(false);
    
    // New state variables for check-ins
    const [checkIns, setCheckIns] = useState([]);
    const [showCheckInModal, setShowCheckInModal] = useState(false);
    const [checkInDate, setCheckInDate] = useState('');
    const [checkInTime, setCheckInTime] = useState('');
    const [checkInNotes, setCheckInNotes] = useState('');
    const [selectedGoalIds, setSelectedGoalIds] = useState([]);
    const [isSubmittingCheckIn, setIsSubmittingCheckIn] = useState(false);
    const [showCheckInHistory, setShowCheckInHistory] = useState(false);

    // Custom notes modal states
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [currentCheckInId, setCurrentCheckInId] = useState(null);
    const [completionNotes, setCompletionNotes] = useState('');

    // Add a state for submit loading
    const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);

    // Add this line to get the notification refresh functions
    const { fetchNotifications, fetchUnreadCount } = useNotifications();

    useEffect(() => {
        const fetchEmployeeDetails = async () => {
            try {
                const response = await fetchWithAuth(`/api/users/get-employee-details/${userId}`);
                const data = await response.json();
                
                if (data.goals) {
                    setGoals(data.goals);
                    if (data.user) {
                        setEmployeeName(`${data.user.first_name} ${data.user.last_name}`);
                    }
                }
            } catch (error) {
                console.error("Error fetching goals:", error);
            }
        };

        if (userId) {
            fetchEmployeeDetails();
            fetchCheckIns(); // Fetch check-ins when component loads
        }
    }, [userId]);

    const handleCommentChange = (goalId, comment) => {
        setCurrentComment(prev => ({
            ...prev,
            [goalId]: comment
        }));
    };

    const handleAddFeedback = (goalId) => {
        const comment = currentComment[goalId];
        if (!comment?.trim()) return;

        setPendingComments(prev => ({
            ...prev,
            [goalId]: comment
        }));
        setCurrentComment(prev => ({...prev, [goalId]: ''}));
    };

    const handleSubmitAllFeedback = async () => {
        try {
            const commentsToSubmit = Object.entries(pendingComments)
                .filter(([_, comment]) => comment?.trim())
                .map(([goalId, comment]) => ({
                    goalId,
                    commenterId,
                    commentText: comment,
                }));

            if (commentsToSubmit.length === 0) {
                alert('No feedback to submit');
                return;
            }

            setIsSubmittingFeedback(true);

            await fetchWithAuth('/api/manager-comments/submit-comments', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ comments: commentsToSubmit }),
            });

            // Refresh all comments
            await Promise.all(goals.map(goal => fetchGoalComments(goal.id)));
            // Clear pending comments
            setPendingComments({});
            alert('Feedback submitted successfully!');
        } catch (error) {
            console.error('Error submitting feedback:', error);
            alert('Error submitting feedback. Please try again.');
        } finally {
            setIsSubmittingFeedback(false);
        }
    };

    const fetchGoalComments = async (goalId) => {
        try {
            const response = await fetchWithAuth(`/api/comments/get-comments/${goalId}`);
            const data = await response.json();
            setGoalComments(prev => ({
                ...prev,
                [goalId]: data,
            }));
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };

    const handleBackLinkClick = () => {
        navigate('/teammembers?tab=your-team');
    };

    const handleClearFeedback = (goalId) => {
        setPendingComments(prev => {
            const newPendingComments = { ...prev };
            delete newPendingComments[goalId];
            return newPendingComments;
        });
    };

    const handleGoalTextToggle = (index) => {
        setGoalTextVisible((prev) => {
            const newVisibility = [...prev];
            newVisibility[index] = !newVisibility[index];
            return newVisibility;
        });
    };
    
    // Updated function to handle goal verification
    const handleVerifyGoal = async (goalId, status, feedback = '') => {
        setIsVerifying(true);
        
        try {
            const response = await fetchWithAuth(`/api/goal-verification/verify/${goalId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    status, 
                    feedback 
                }),
            });
            
            if (!response.ok) {
                throw new Error('Failed to verify goal');
            }
            
            // Update the goal status locally
            setGoals(prev => prev.map(goal => 
                goal.id === goalId 
                    ? { ...goal, verification_status: status } 
                    : goal
            ));
            
            alert(`Goal ${status === 'verified' ? 'verified' : 'rejected'} successfully`);
        } catch (error) {
            console.error('Error verifying goal:', error);
            alert('Failed to verify goal. Please try again.');
        } finally {
            setIsVerifying(false);
        }
    };

    // Function to fetch check-ins for this employee
    const fetchCheckIns = async () => {
        try {
            const response = await fetchWithAuth(`/api/check-ins/employee/${userId}`);
            if (response.ok) {
                const data = await response.json();
                setCheckIns(data);
            } else {
                console.error("Failed to fetch check-ins");
            }
        } catch (error) {
            console.error("Error fetching check-ins:", error);
        }
    };
    
    // Function to handle scheduling a new check-in
    const handleScheduleCheckIn = async () => {
        if (!checkInDate || !checkInTime) {
            alert("Please select a date and time for the check-in");
            return;
        }
        
        setIsSubmittingCheckIn(true);
        
        try {
            // Make sure we create a valid date object
            const dateStr = `${checkInDate}T${checkInTime}`;
            const scheduledDateTime = new Date(dateStr);
            
            // Check if the date is valid
            if (isNaN(scheduledDateTime.getTime())) {
                throw new Error('Invalid date or time selected');
            }
            
            const checkInData = {
                managerId: commenterId,
                employeeId: userId,
                scheduledDate: scheduledDateTime.toISOString(),
                managerNotes: checkInNotes,
                relatedGoalIds: selectedGoalIds,
                status: "scheduled"
            };
            
            console.log("Scheduling check-in with data:", checkInData);
            
            const response = await fetchWithAuth('/api/check-ins/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(checkInData),
            });
            
            if (!response.ok) {
                throw new Error('Failed to schedule check-in');
            }
            
            await fetchCheckIns(); // Refresh check-ins list
            
            // Refresh notifications to show new notification for employee
            if (fetchNotifications) fetchNotifications();
            if (fetchUnreadCount) fetchUnreadCount();
            
            setShowCheckInModal(false);
            
            // Reset form
            setCheckInDate('');
            setCheckInTime('');
            setCheckInNotes('');
            setSelectedGoalIds([]);
            
            alert('Check-in scheduled successfully!');
        } catch (error) {
            console.error('Error scheduling check-in:', error);
            alert('Failed to schedule check-in. Please try again.');
        } finally {
            setIsSubmittingCheckIn(false);
        }
    };
    
    // Function to mark a check-in as completed
    const handleCompleteCheckIn = async (checkInId, notes) => {
        try {
            const response = await fetchWithAuth(`/api/check-ins/${checkInId}/complete`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    completionNotes: notes || 'Check-in completed',
                    completedBy: commenterId
                }),
            });
            
            if (!response.ok) {
                throw new Error('Failed to complete check-in');
            }
            
            // Close notes modal if open
            setShowNotesModal(false);
            
            // Refresh check-ins
            await fetchCheckIns();
            
            // Refresh notifications to show new notification for employee
            if (fetchNotifications) fetchNotifications();
            if (fetchUnreadCount) fetchUnreadCount();
            
            alert('Check-in marked as completed!');
        } catch (error) {
            console.error('Error completing check-in:', error);
            alert('Failed to complete check-in. Please try again.');
        }
    };
    
    // Function to cancel a scheduled check-in
    const handleCancelCheckIn = async (checkInId) => {
        if (!window.confirm('Are you sure you want to cancel this check-in?')) {
            return;
        }
        
        try {
            const response = await fetchWithAuth(`/api/check-ins/${checkInId}/cancel`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    cancelledBy: commenterId,
                    cancellationReason: 'Cancelled by manager'
                }),
            });
            
            if (!response.ok) {
                throw new Error('Failed to cancel check-in');
            }
            
            // Refresh check-ins
            await fetchCheckIns();
            
            // Refresh notifications to show new notification for employee
            if (fetchNotifications) fetchNotifications();
            if (fetchUnreadCount) fetchUnreadCount();
            
            alert('Check-in cancelled successfully!');
        } catch (error) {
            console.error('Error cancelling check-in:', error);
            alert('Failed to cancel check-in. Please try again.');
        }
    };
    
    // Function to handle selecting goals for a check-in
    const handleGoalSelection = (goalId) => {
        setSelectedGoalIds(prev => {
            // If already selected, remove it; otherwise, add it
            return prev.includes(goalId)
                ? prev.filter(id => id !== goalId)
                : [...prev, goalId];
        });
    };
    
    // Function to delete a check-in
    const handleDeleteCheckIn = async (checkInId) => {
        if (!window.confirm('Are you sure you want to delete this check-in? This action cannot be undone.')) {
            return;
        }
        
        try {
            const response = await fetchWithAuth(`/api/check-ins/${checkInId}`, {
                method: 'DELETE',
            });
            
            if (!response.ok) {
                throw new Error('Failed to delete check-in');
            }
            
            // Refresh check-ins
            await fetchCheckIns();
            
            alert('Check-in deleted successfully!');
        } catch (error) {
            console.error('Error deleting check-in:', error);
            alert('Failed to delete check-in. Please try again.');
        }
    };
    
    // Function to open the notes modal
    const openNotesModal = (checkInId) => {
        setCurrentCheckInId(checkInId);
        setCompletionNotes('');
        setShowNotesModal(true);
    };
    
    // Function to handle submitting completion notes
    const handleNotesSubmit = () => {
        if (!completionNotes.trim()) {
            alert('Please enter notes about the check-in');
            return;
        }
        
        handleCompleteCheckIn(currentCheckInId, completionNotes);
    };

    return (
        <div className="page-container">
            <div className="back-button-container">
                <BackButton onClick={handleBackLinkClick} />
            </div>
            <div className="page-content">
                <header className="manager-progress-header">
                    <h2 className="page-header manager-progress-header-text">Goals Progress</h2>
                    <p className="manager-progress-header-subtext">
                        Reviewing goals for {employeeName}
                    </p>
                    
                    {/* New Check-in Button */}
                    <div className="manager-progress-actions">
                        <button 
                            className="manager-progress-checkin-button"
                            onClick={() => setShowCheckInModal(true)}
                        >
                            <FaCalendarCheck /> Schedule Check-in
                        </button>
                        <button 
                            className="manager-progress-history-button"
                            onClick={() => setShowCheckInHistory(!showCheckInHistory)}
                        >
                            {showCheckInHistory ? "Hide Check-in History" : "Show Check-in History"}
                        </button>
                    </div>
                </header>
                
                {/* Check-in History Section */}
                {showCheckInHistory && (
                    <div className="manager-progress-checkin-history">
                        <h3><FaCalendarAlt /> Check-in History</h3>
                        {checkIns.length > 0 ? (
                            <div className="checkin-list">
                                {checkIns.map(checkIn => (
                                    <div key={checkIn.id} className={`checkin-item checkin-${checkIn.status}`}>
                                        <div className="checkin-header">
                                            <div className="checkin-date">
                                                <FaCalendarCheck className="checkin-icon" /> 
                                                {(checkIn.scheduledDate || checkIn.scheduled_date) && 
                                                 !isNaN(new Date(checkIn.scheduledDate || checkIn.scheduled_date).getTime()) ? 
                                                    `${new Date(checkIn.scheduledDate || checkIn.scheduled_date).toLocaleDateString()} at ${new Date(checkIn.scheduledDate || checkIn.scheduled_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}` :
                                                    "Date not set"
                                                }
                                            </div>
                                            <div className="checkin-status">
                                                {checkIn.status === 'scheduled' && (
                                                    <span className="status-badge"><FaClock /> Scheduled</span>
                                                )}
                                                {checkIn.status === 'completed' && (
                                                    <span className="status-badge"><FaCheckCircle /> Completed</span>
                                                )}
                                                {checkIn.status === 'cancelled' && (
                                                    <span className="status-badge"><FaCalendarTimes /> Cancelled</span>
                                                )}
                                            </div>
                                        </div>
                                        
                                        {(checkIn.completedDate || checkIn.completed_date) && (
                                            <div className="checkin-completed-date">
                                                <strong>Completed on:</strong> {new Date(checkIn.completedDate || checkIn.completed_date).toLocaleDateString()} at {new Date(checkIn.completedDate || checkIn.completed_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                                            </div>
                                        )}
                                        
                                        {(checkIn.managerNotes || checkIn.manager_notes) && (
                                            <div className="checkin-notes">
                                                <strong><FaNotesMedical /> Notes:</strong> {checkIn.managerNotes || checkIn.manager_notes}
                                            </div>
                                        )}

                                        {(checkIn.employeeNotes || checkIn.employee_notes) && (
                                            <div className="checkin-employee-notes">
                                                <strong><FaComment /> Employee Notes:</strong> {checkIn.employeeNotes || checkIn.employee_notes}
                                            </div>
                                        )}
                                        
                                        {checkIn.status === 'scheduled' && (
                                            <div className="checkin-actions">
                                                <button 
                                                    className="checkin-complete-button"
                                                    onClick={() => openNotesModal(checkIn.id)}
                                                >
                                                    <FaCheckCircle /> Mark as Completed
                                                </button>
                                                <button 
                                                    className="checkin-cancel-button"
                                                    onClick={() => handleCancelCheckIn(checkIn.id)}
                                                >
                                                    <FaCalendarTimes /> Cancel
                                                </button>
                                            </div>
                                        )}
                                        
                                        {checkIn.status === 'cancelled' && (
                                            <div className="checkin-actions">
                                                <button 
                                                    className="checkin-delete-button"
                                                    onClick={() => handleDeleteCheckIn(checkIn.id)}
                                                >
                                                    <FaTrash /> Delete
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="no-checkins-message">
                                <FaClock className="no-checkins-icon" />
                                <p>No check-ins scheduled yet. Schedule your first check-in with {employeeName}.</p>
                            </div>
                        )}
                    </div>
                )}

                <div className="manager-progress-goals">
                    {goals.length > 0 ? (
                        goals.map((goal, index) => (
                            <div key={goal.id} className="goal-card">
                                <h3 className="manager-progress-goal-name">
                                    {goal.summary}
                                </h3>

                                <button 
                                    className="action-button"
                                    onClick={() => handleGoalTextToggle(index)}
                                >
                                    {goalTextVisible[index] ? "Show Less" : "Show More"}
                                </button>

                                {goalTextVisible[index] && (
                                    <div className="goal-details">
                                        <p className="manager-progress-goal-text">
                                            {goal.goal_text}
                                        </p>
                                        
                                        <div className="progress-bar-container">
                                            <div className="progress-label">
                                                Current Progress: {goal.progress}%
                                            </div>
                                            <div className="progress-bar">
                                                <div 
                                                    className="progress-fill"
                                                    style={{width: `${goal.progress}%`}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                
                                {/* Verification section */}
                                {goal.verification_status === 'pending' && (
                                    <div className="manager-progress-verification-actions">
                                        <h4>Verification Required</h4>
                                        <p>This goal is awaiting your verification.</p>
                                        
                                        {goal.verification_notes && (
                                            <div className="manager-progress-verification-notes">
                                                <h5>Employee's Completion Notes:</h5>
                                                <p>{goal.verification_notes}</p>
                                            </div>
                                        )}
                                        
                                        <div className="manager-progress-verification-buttons">
                                            <button 
                                                className="manager-progress-verify-button manager-progress-approve"
                                                onClick={() => handleVerifyGoal(goal.id, 'verified')}
                                                disabled={isVerifying}
                                            >
                                                <FaCheck /> Verify Goal
                                            </button>
                                            
                                            <button 
                                                className="manager-progress-verify-button manager-progress-reject"
                                                onClick={() => {
                                                    const feedback = prompt('Please provide feedback on why this goal was rejected:');
                                                    if (feedback) {
                                                        handleVerifyGoal(goal.id, 'rejected', feedback);
                                                    }
                                                }}
                                                disabled={isVerifying}
                                            >
                                                <FaTimes /> Reject Goal
                                            </button>
                                        </div>
                                    </div>
                                )}

                                <div className="comments-section">
                                    <div className="feedback-composer">
                                        <div className="feedback-composer-header">
                                            <FaCommentDots /> Provide Feedback
                                        </div>
                                        <textarea
                                            className="manager-progress-goal-comment"
                                            placeholder="Share your thoughts, questions, or suggestions about this goal..."
                                            value={currentComment[goal.id] || ''}
                                            onChange={(e) => handleCommentChange(goal.id, e.target.value)}
                                        />
                                        
                                        <div className="feedback-composer-actions">
                                            <button 
                                                className="action-button"
                                                onClick={() => {
                                                    if (!commentsVisible[goal.id]) {
                                                        fetchGoalComments(goal.id);
                                                    }
                                                    setCommentsVisible(prev => ({
                                                        ...prev,
                                                        [goal.id]: !prev[goal.id]
                                                    }));
                                                }}
                                            >
                                                {commentsVisible[goal.id] ? "Hide Discussion" : "View Discussion"}
                                            </button>
                                            
                                            <button 
                                                className="action-button primary"
                                                onClick={() => handleAddFeedback(goal.id)}
                                                disabled={!currentComment[goal.id]?.trim()}
                                            >
                                                <FaRegPaperPlane /> Add Feedback
                                            </button>
                                        </div>
                                    </div>

                                    {pendingComments[goal.id] && (
                                        <div className="pending-comment">
                                            <div className="pending-comment-header">
                                                <p><FaComment /> Pending feedback</p>
                                                <button 
                                                    className="clear-feedback-button"
                                                    onClick={() => handleClearFeedback(goal.id)}
                                                    title="Clear pending feedback"
                                                >
                                                    ×
                                                </button>
                                            </div>
                                            <p>{pendingComments[goal.id]}</p>
                                        </div>
                                    )}

                                    {commentsVisible[goal.id] && (
                                        <div className="comment-history">
                                            {goalComments[goal.id]?.length > 0 ? (
                                                goalComments[goal.id].map((comment, idx) => (
                                                    <div 
                                                        key={idx} 
                                                        className={`comment-item ${comment.type === 'manager' ? 'manager' : 'employee'}`}
                                                    >
                                                        <div className="comment-metadata">
                                                            <span className="comment-author">
                                                                {comment.first_name} {comment.last_name}
                                                            </span>
                                                            <span className="comment-time">
                                                                {new Date(comment.date).toLocaleDateString()} {new Date(comment.date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                                                            </span>
                                                        </div>
                                                        <div className="comment-content">{comment.comment}</div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="no-comments-message">
                                                    No discussion yet. Be the first to provide feedback!
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="no-goals-message">
                            No goals have been set for this employee yet.
                        </p>
                    )}
                </div>

                {/* Submit All Feedback Button - updated UI */}
                <div className="manager-progress-submit-container">
                    <button 
                        className="manager-progress-submit-link"
                        onClick={handleSubmitAllFeedback}
                        disabled={isSubmittingFeedback || Object.keys(pendingComments).length === 0}
                    >
                        {isSubmittingFeedback ? (
                            <>
                                <span className="submit-spinner"></span> Submitting...
                            </>
                        ) : (
                            <>
                                <FaPaperPlane /> Submit All Feedback
                            </>
                        )}
                    </button>
                </div>
            </div>
            
            {/* Check-in Scheduling Modal */}
            {showCheckInModal && (
                <div className="manager-progress-modal">
                    <div className="manager-progress-modal-content">
                        <h3> Schedule Check-in with {employeeName}</h3>
                        
                        <div className="manager-progress-form-group">
                            <label>Date:</label>
                            <input 
                                type="date" 
                                value={checkInDate}
                                onChange={(e) => setCheckInDate(e.target.value)}
                                min={new Date().toISOString().split('T')[0]}
                            />
                        </div>
                        
                        <div className="manager-progress-form-group">
                            <label>Time:</label>
                            <div className="time-select-container">
                                <select 
                                    className="time-select"
                                    value={checkInTime}
                                    onChange={(e) => setCheckInTime(e.target.value)}
                                >
                                    <option value="">Select a time</option>
                                    {Array.from({length: 24}).map((_, hour) => 
                                        [0, 15, 30, 45].map(minute => {
                                            const formattedHour = hour.toString().padStart(2, '0');
                                            const formattedMinute = minute.toString().padStart(2, '0');
                                            const timeValue = `${formattedHour}:${formattedMinute}`;
                                            const displayHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
                                            const amPm = hour < 12 ? 'AM' : 'PM';
                                            const displayTime = `${displayHour}:${formattedMinute} ${amPm}`;
                                            
                                            return (
                                                <option key={timeValue} value={timeValue}>
                                                    {displayTime}
                                                </option>
                                            );
                                        })
                                    )}
                                </select>
                            </div>
                        </div>
                        
                        <div className="manager-progress-form-group">
                            <label>Goals to Discuss:</label>
                            {goals.length > 0 ? (
                                <div className="manager-progress-goal-selection">
                                    {goals.map(goal => (
                                        <div 
                                            key={goal.id} 
                                            className="manager-progress-goal-checkbox"
                                            onClick={() => handleGoalSelection(goal.id)}
                                        >
                                            <input
                                                type="checkbox"
                                                id={`goal-${goal.id}`}
                                                checked={selectedGoalIds.includes(goal.id)}
                                                onChange={() => handleGoalSelection(goal.id)}
                                                onClick={(e) => e.stopPropagation()} 
                                            />
                                            <label htmlFor={`goal-${goal.id}`}>
                                                <div className="goal-checkbox-title">{goal.summary}</div>
                                                <div className="goal-checkbox-progress">
                                                    <div 
                                                        className="goal-checkbox-progress-bar"
                                                    >
                                                        <div 
                                                            className="goal-checkbox-progress-fill"
                                                            style={{ width: `${goal.progress}%` }}
                                                        ></div>
                                                    </div>
                                                    <span>{goal.progress}%</span>
                                                </div>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="no-goals-for-checkin">
                                    <p>No goals available. The employee hasn't set any goals yet.</p>
                                </div>
                            )}
                        </div>
                        
                        <div className="manager-progress-form-group">
                            <label>Notes/Agenda:</label>
                            <textarea
                                value={checkInNotes}
                                onChange={(e) => setCheckInNotes(e.target.value)}
                                placeholder="Enter topics to discuss during the check-in..."
                                rows={4}
                            ></textarea>
                            <small>These notes will be visible to the employee as an agenda for the meeting.</small>
                        </div>
                        
                        <div className="manager-progress-modal-actions">
                            <button 
                                className="manager-progress-modal-cancel"
                                onClick={() => setShowCheckInModal(false)}
                            >
                                <FaTimes /> Cancel
                            </button>
                            <button 
                                className="manager-progress-modal-submit"
                                onClick={handleScheduleCheckIn}
                                disabled={isSubmittingCheckIn || !checkInDate || !checkInTime}
                            >
                                {isSubmittingCheckIn ? (
                                    <>
                                        <span className="modal-spinner"></span> Scheduling...
                                    </>
                                ) : (
                                    <>
                                        <FaCalendarCheck /> Schedule Check-in
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Custom Notes Modal */}
            {showNotesModal && (
                <div className="custom-notes-modal">
                    <div className="custom-notes-modal-content">
                        <div className="custom-notes-modal-header">
                            <h3>Complete Check-in</h3>
                            <p>Enter your notes from this check-in with {employeeName}</p>
                        </div>
                        <textarea
                            value={completionNotes}
                            onChange={(e) => setCompletionNotes(e.target.value)}
                            placeholder="Enter summary and key points discussed during the meeting..."
                        ></textarea>
                        <div className="custom-notes-modal-actions">
                            <button 
                                className="custom-notes-modal-cancel"
                                onClick={() => setShowNotesModal(false)}
                            >
                                Cancel
                            </button>
                            <button 
                                className="custom-notes-modal-submit"
                                onClick={handleNotesSubmit}
                            >
                                <FaCheckCircle /> Complete Check-in
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManagerProgress;
